import React, {useEffect, useState} from 'react';
import moment from "moment";
import localForage from "localforage";
import Web3 from "web3";
import appConfig from "../appConfig";
import Mixpanel from "mixpanel-browser";
import InfoToolTip from "./InfoToolTip";
import Logger from "js-logger";
import {useSelector, useDispatch} from 'react-redux'
import {useAccount} from 'wagmi'
import { disconnect } from '@wagmi/core'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import {BsCircleFill, BsWallet2} from "react-icons/bs";
import {SlArrowDown, SlArrowUp} from "react-icons/sl";
import {AiOutlineDisconnect} from "react-icons/ai";
import {VscDebugDisconnect} from "react-icons/vsc";
import {FiRefreshCw} from "react-icons/fi";
import {requestDisconnectWallet, requestSetVTRBalance, requestSetWalletAddress} from "../store/actions/wallet";
import {requestSetAutoConnect, requestSetOnRightNetwork} from "../store/actions/app";
import {DataReporter} from "../DataReporter";
import "../styles/components/walletcontrols.scss";

const WalletControls = (props) => {
    // States
    const [gettingVTRBalance, setGettingVTRBalance] = useState(false);
    const [walletIsConnected, setWalletIsConnected] = useState(false);
    const [showWalletContextMenu, setShowWalletContextMenu] = useState(false);
    const [updatingVTR, setUpdatingVTR] = useState(false);

    // Redux
    const walletReducer = useSelector(state => state.wallet);
    const blockchainReducer = useSelector(state => state.blockchain);
    const appReducer = useSelector(state => state.app);

    const dispatch = useDispatch()

    const dispatchDisconnectWallet = () => {
        dispatch(requestDisconnectWallet())
    }
    const dispatchSetWalletAddress = (address) => {
        dispatch(requestSetWalletAddress(address))
    }
    const dispatchSetVTRBalance = (vtr) => {
        dispatch(requestSetVTRBalance(vtr / appConfig.VTRdecimals))
    }
    const dispatchSetOnRightNetwork = (b) => {
        dispatch(requestSetOnRightNetwork(b));
    }
    const dispatchSetAutoConnect = (b) => {
        dispatch(requestSetAutoConnect(b));
    }


    // Effects
    const {open} = useWeb3Modal();
    const {address} = useAccount();

    useEffect(() => {
        if (!gettingVTRBalance && canUpdateVTRBalance()) {
            setGettingVTRBalance(true);
            updateVTRBalance();
        }
        if (walletIsConnected && address && !walletReducer.connectedAddress) {
            dispatchSetWalletAddress(address);
            setWalletIsConnected(true); // value was 'false'
        }

        if (appReducer.autoConnect) {
            onConnectWalletClick();
            dispatchSetAutoConnect(false);
        }
    });


    // Functions
    const canUpdateVTRBalance = () => {
        if (walletReducer.lastVTRUpdate !== null) {
            let last = moment(walletReducer.lastVTRUpdate);
            let difference = moment().diff(last, 'seconds');

            if (walletReducer.lastVTRUpdate) return difference >= 15;
            return true;
        } else return true;
    }

    const updateVTRBalance = () => {
        if (walletReducer.connectedAddress && !updatingVTR) {
            setUpdatingVTR(true);

            if (blockchainReducer.vtrTokenContract) {

                try {
                    let vtrTokenContract = blockchainReducer.vtrTokenContract;
                    vtrTokenContract.methods.balanceOf(walletReducer.connectedAddress)
                        .call({from: walletReducer.connectedAddress, gas: appConfig.currentConfig.blockchainGasLimit})
                        .then((res) => {
                            dispatchSetVTRBalance(res);
                            setUpdatingVTR(false);
                        })
                        .catch((err) => {
                            setUpdatingVTR(false);
                            DataReporter.trackSentry(err, { extra: {additionalData: "WalletInfo updating VTR balance."} });
                        })
                }
                catch (err) {
                    Logger.info("Error updating balance");
                }
            }
        }
    }

    const onConnectWalletClick = async () => {
        await open();
        setWalletIsConnected(true);

        DataReporter.trackMixpanel(this.props, "Wallet connected", {
            category: "Interaction"
        });

        // TODO: Currently this runs immediately when the connect button is clickd
        let web3 = new Web3(window.ethereum);
        web3.eth.net.getId().then((networkId) => {
            if (!appConfig.onDev) {
                //dispatchSetOnRightNetwork(networkId === customWagmiChains.getInfuraAmoy().id);
            }
        }).catch((error) => {
            DataReporter.trackMixpanel(this.props,
                "Wallet: Failed to get network ID", { category: "Error" });

            DataReporter.trackSentry(error, {
                extra: {additionalData: "UIConfigLoader: Failed to get network ID."}
            });
        })
    }

    const onDisconnectWallet = async () => {
        await disconnect();

        localForage.dropInstance({
            name: "ventrace"
        }).then(async function () {
            setWalletIsConnected(false);
            dispatchDisconnectWallet();

            if (appReducer.cookiesAccepted && appConfig.currentConfig.trackAnalytics) {
                Mixpanel.track("Disconnected wallet", {
                    category: "Interaction"
                });
            }

            setTimeout(() => { window.open("/", "_self"); }, 1000);
        }).catch(function (err) {
            DataReporter.trackSentry(err, {
                extra: {additionalData: "Wallet disconnect dropping localForage instance."}
            });
        });
    }

    const toggleShowContextMenu = (set) => {
        setShowWalletContextMenu(set)
        if (props.toggleShowContextMenuCallback) {
            props.toggleShowContextMenuCallback(set);
        }

        if (appConfig.currentConfig.trackAnalytics) {
            Mixpanel.track(set ? "Open WalletControls" : "Close WalletControls", {
                category: "Navigation"
            });
        }
    }

    const onRefreshClick = () => {
        if (appConfig.currentConfig.trackAnalytics) {
            Mixpanel.track("Manual VTR refresh", { category: "Navigation" });
        }
        updateVTRBalance();
    }

    // Renderers
    const renderWalletQuickInfos = () => {
        if (walletReducer.connectedAddress !== null && showWalletContextMenu) {
            let vtr = walletReducer.vtrBalance !== null ? walletReducer.vtrBalance : 0;
            let mobileSuffix = appReducer.mobileMode ? "-mobile" : "";

            return (
                <div id={"wallet-context-menu" + mobileSuffix}>
                    <p className={"label"}>Your connected address</p>
                    <p id={"wallet-address"}>
                        {walletReducer.connectedAddress}
                    </p>

                    <div>
                        <p className={"label"} id={"label-vtr-balance"}>Your VTR Balance</p>
                        <div className={"inline"} id={"balance-inline"}>
                            <p id={"vtr-balance"}>
                                <BsWallet2 id={"wallet-icon"}/>
                                {vtr} <span className={"vtr-hl"}>VTR</span>
                            </p>
                            <FiRefreshCw
                                id={updatingVTR ? "refresh-icon-loading" : "refresh-icon"}
                                onClick={() => onRefreshClick()}
                            />
                        </div>
                    </div>


                    <p id={"disconnect"} onClick={() => onDisconnectWallet()}>
                        <AiOutlineDisconnect id={"icon"}/> Disconnect wallet
                    </p>

                </div>
            );
        }
    }

    const renderWalletInfo = () => {
        let mobileSuffix = appReducer.mobileMode ? "-mobile" : "";

        if (walletReducer.connectedAddress !== null) {
            let arrowIcon;
            if (showWalletContextMenu) {
                arrowIcon = <SlArrowUp
                    id={"expand-wallet-icon"}
                    onClick={() => toggleShowContextMenu(false)}
                />;
            } else {
                arrowIcon = <SlArrowDown
                    id={"expand-wallet-icon"}
                    onClick={() => toggleShowContextMenu(true)}
                />;
            }

            let sliceLen = 3;
            let walletAddress = walletReducer.connectedAddress.slice(0, sliceLen+2)
                + "-" + walletReducer.connectedAddress.slice(-sliceLen);

            return (
                <div className={"wallet-info-container" + mobileSuffix}>
                    <div className={"inline"}>
                        <p id={"address"}>
                            <BsCircleFill id={"connected-dot"}/>
                            Connected wallet
                        </p>

                        <div id={"data-container"}>
                            <p id={"data"}>
                                {walletAddress}
                            </p>
                            {arrowIcon}
                        </div>
                    </div>
                    {renderWalletQuickInfos()}
                </div>
            );
        } else if (walletReducer.noWalletMode) {
            return (
                <div className={"no-wallet-connect-container" + mobileSuffix}>
                    <p className={"connect-btn inline"} onClick={async () => onConnectWalletClick()}>
                        <VscDebugDisconnect className={"icon"}/>
                        Connect wallet
                    </p>
                    <InfoToolTip
                        text={"Click to open quick tutorial"}
                        onClick={() => {
                            window.open("/gettingstarted", "_blank");
                        }
                    }
                    />
                </div>
            );
        }
    }

    return (
        <div>{renderWalletInfo()}</div>
    );
}

export default WalletControls;