import React from "react";
import moment from "moment";
import {connect} from "react-redux";
import appConfig from "../../appConfig";
import {create} from "ipfs-http-client";
import {DateConverter} from "../../DateConverter";
import ImagePresenter from "./ImagePresenter";
import {DataReporter} from "../../DataReporter";
import DueBranchMerger from "../DueBranchMerger";
import UserButton from "../inputs/UserButton";
import VTRCalculator from "../VTRCalculator";
import TRXReceipt from "../TRXReceipt";
import DisputeDecision from "../DisputeDecision";
import {requestSetBlockchainLoading, requestSetBlockchainLoadingStop} from "../../store/actions/blockchain";
import {EIP712types} from "../../EIP712types";
import FeedbackMessage from "../FeedbackMessage";
import CreatorInfo from "./CreatorInfo";
import {CiBarcode} from "react-icons/ci";
import {BiCertification} from "react-icons/bi";
import {BsCalendarWeek} from "react-icons/bs";
import {AiOutlineShoppingCart, AiFillQuestionCircle} from "react-icons/ai";
import "../../styles/components/details_rootrecord.scss";

class RootRecord extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showImg: false,
            decidingOnBranch: false,
            branchAccepted: null,
            submissionSuccessful: null,
            estimatedGasUse: null,
            errorMessage: null,
            showAnimInfoText: false
        };
    }

    getBranchAcceptedJSON() {
        return {
            "recordType": "branchAccepted",
            "recordCreationDate": Date.now().toString(),
            "originator": this.props.walletReducer.connectedAddress
        }
    }

    getBranchDismissedJSON() {
        return {
            "recordType": "branchDismissed",
            "recordCreationDate": Date.now().toString(),
            "originator": this.props.walletReducer.connectedAddress
        }
    }

    getProductWord(capitalize) {
        let productWord = "product";
        if (this.props.appReducer.activePersona === appConfig.personas.watches) {
            productWord = "watch";
        }
        if (capitalize) {
            return productWord.charAt(0).toUpperCase() + productWord.slice(1);
        } else {
            return productWord;
        }
    }

    getDaysLeftForAutoMerge() {
        let branchDate = null;

        for (let i = 0; i < this.props.data.length; i += 1) {
            let rt = this.props.data[i].data.recordType;

            if (rt === "branchedRoot" || rt === "branchCreated") {
                branchDate = moment(parseInt(this.props.data[i].data.recordCreationDate)).add(1, "year");
                break;
            }
        }
        return branchDate.diff(moment(), "days") + 1;
    }

    getData() {
        let originatorAddress;
        let creationDate;
        let purchaseDate;
        let serialNr;
        let description;
        let imageCIDs;

        if (this.props.data) {
            originatorAddress = this.props.data[0].data.originator;
            creationDate = this.props.data[0].data.recordCreationDate;
            purchaseDate = this.props.data[0].data.purchaseDate;
            serialNr = this.props.data[0].data.serialNr;
            description = this.props.data[0].data.description;
            imageCIDs = this.props.data[0].data.imageCIDs;
        } else if (this.props.pendingRecordData) {
            originatorAddress = this.props.pendingRecordData.rootRecordData.originator;
            creationDate = this.props.pendingRecordData.rootRecordData.recordCreationDate;
            purchaseDate = this.props.pendingRecordData.rootRecordData.purchaseDate;
            serialNr = this.props.pendingRecordData.rootRecordData.serialNr;
            description = this.props.pendingRecordData.rootRecordData.description;
            imageCIDs = this.props.pendingRecordData.rootRecordData.imageCIDs;
        }

        return {
            originator: originatorAddress,
            recordCreationDate: creationDate,
            purchaseDate: purchaseDate,
            serialNr: serialNr,
            description: description,
            imageCIDs: imageCIDs
        }
    }

    // Events
    onSubmissionSuccessful(receipt, branchAccepted) {
        this.setState({
            submissionSuccessful: true,
            trxReceipt: receipt,
            branchAccepted: branchAccepted
        }, () => {
            if (this.props.reloadCallback) this.props.reloadCallback();
        })
    }

    onViewOriginalClick(rp) {
        DataReporter.trackMixpanel(this.props, "RR: Opening original record", {
            category: "Interaction",
        });

        window.open(`/record?rp=${rp}`, "_blank");
    }

    onAcceptBranch() {
        if (!this.state.decidingOnBranch) {
            this.setState({
                decidingOnBranch: true
            }, async () => {
                this.props.dispatchBlockchainLoading();

                let auth = "Basic " + Buffer.from(appConfig.IPFS_ProjectID + ":" + appConfig.IPFS_Key).toString("base64");
                let client = create({url: appConfig.IPFS_Endpoint, headers: {authorization: auth}});
                let storedJSON = await client.add(JSON.stringify(this.getBranchAcceptedJSON()));

                let recordManagerContract = this.props.blockchainReducer.recordManagerContract;

                try {
                    let signature = await window.ethereum.request({
                        method: 'eth_signTypedData_v4',
                        params: [this.props.walletReducer.connectedAddress,
                            JSON.stringify(EIP712types.newExtension(
                                parseInt(this.props.rootRecord[0]), "acceptBranch"))
                        ],
                    });

                    let rsv = EIP712types.getRSVfromSignature(signature);

                    recordManagerContract.methods.acceptBranch(this.props.rootRecord[9],
                        storedJSON.path, rsv.r, rsv.s, rsv.v)
                        .send({from: this.props.walletReducer.connectedAddress, gas: this.state.estimatedGasUse})
                        .then((receipt) => {
                            DataReporter.trackMixpanel(this.props, "Success: Accepted branch", {
                                category: "Interaction",
                            });

                            this.props.dispatchBlockchainLoadingStop();
                            this.onSubmissionSuccessful(receipt, true);
                        })
                        .catch((err) => {
                            this.props.dispatchBlockchainLoadingStop();

                            this.setState({
                                errorMessage: "Error accepting branch (1). Please try again.",
                                decidingOnBranch: false
                            }, () => {
                                DataReporter.trackSentry(err, {
                                    extra: {additionalData: "RootRecord: Accepting branch."}
                                });

                            });
                        })
                } catch (err) {
                    this.props.dispatchBlockchainLoadingStop();

                    let msg = "Signing failed. Please check if your wallet is configured correctly.";
                    if (err.message.toLowerCase().includes("chainid")) {
                        msg = `Signing failed. Please check if your wallet is connected to the ${appConfig.currentConfig.blockchainName} network.`;
                    }

                    this.setState({
                        errorMessage: msg,
                        decidingOnBranch: false
                    }, () => {
                        DataReporter.trackSentry(err, {
                            extra: {additionalData: "RootRecord: Signing accepting branch."}
                        });

                        DataReporter.trackMixpanel(this.props, "Error: Signing branch accept",
                            { category: "Interaction", });
                    });
                }
            });
        }
    }

    onDismissBranch() {
        if (!this.state.decidingOnBranch) {
            this.setState({
                decidingOnBranch: true
            }, async () => {
                this.props.dispatchBlockchainLoading();

                // Upload data as JSON to IPFS
                let auth = "Basic " + Buffer.from(appConfig.IPFS_ProjectID + ":" + appConfig.IPFS_Key).toString("base64");
                let client = create({url: appConfig.IPFS_Endpoint, headers: {authorization: auth}});
                let storedJSON = await client.add(JSON.stringify(this.getBranchDismissedJSON()));

                let recordManagerContract = this.props.blockchainReducer.recordManagerContract;

                try {
                    let signature = await window.ethereum.request({
                        method: 'eth_signTypedData_v4',
                        params: [this.props.walletReducer.connectedAddress,
                            JSON.stringify(EIP712types.newExtension(
                                parseInt(this.props.rootRecord[0]), "dismissBranch"))
                        ],
                    });

                    let rsv = EIP712types.getRSVfromSignature(signature);

                    recordManagerContract.methods.dismissBranch(this.props.rootRecord[9],
                        storedJSON.path, rsv.r, rsv.s, rsv.v)
                        .send({from: this.props.walletReducer.connectedAddress, gas: this.state.estimatedGasUse})
                        .then((dismissReceipt) => {
                            DataReporter.trackMixpanel(this.props, "Success: Dismissed branch", {
                                category: "Interaction",
                            });

                            this.props.dispatchBlockchainLoadingStop();
                            this.onSubmissionSuccessful(dismissReceipt, false);
                        })
                        .catch((err) => {
                            this.props.dispatchBlockchainLoadingStop();

                            this.setState({
                                errorMessage: "Error dismissing branch (1). Please try again.",
                                decidingOnBranch: false
                            }, () => {
                                DataReporter.trackSentry(err, {
                                    extra: {additionalData: "RootRecord: Dismissing branch."}
                                });
                                DataReporter.trackMixpanel(this.props, "Error: RR dismissing branch",
                                    { category: "Interaction" });

                            });
                        })
                } catch (err) {
                    this.props.dispatchBlockchainLoadingStop();

                    let msg = "Signing failed. Please check if your wallet is configured correctly.";
                    if (err.message.toLowerCase().includes("chainid")) {
                        msg = `Signing failed. Please check if your wallet is connected to the ${appConfig.currentConfig.blockchainName} network.`;
                    }

                    this.setState({ errorMessage: msg, decidingOnBranch: false }, () => {
                        DataReporter.trackSentry(err, {
                            extra: {additionalData: "RootRecord: Signing dismissing branch."}
                        });
                        DataReporter.trackMixpanel(this.props, "Error: Signing dismiss branch",
                            { category: "Interaction" });
                    });
                }
            });
        }
    }

    onEstimatedGasUse(estimate) {
        this.setState({
            estimatedGasUse: estimate
        });
    }

    clickCreatorAddress(val) {
        DataReporter.trackMixpanel(this.props, "RR: Open creator address", {
            category: "Interaction",
        });

        let baseUrl = appConfig.currentConfig.explorerURLs.address;
        window.open(baseUrl + val, "_blank");
    }

    onShowAnimInfoClick() {
        if (!this.state.showAnimInfoText) {
            this.setState({
                showAnimInfoText: true
            }, () => {
                DataReporter.trackMixpanel(this.props, "Showing VR anim info",
                    { category: "Sale" }
                )
            });
        }
    }

    // Renderers
    renderErrorMessage() {
        if (this.state.errorMessage !== null) {
            return <FeedbackMessage
                success={false}
                message={this.state.errorMessage}
            />;
        }
    }

    renderTRXReceipt() {
        if (this.state.submissionSuccessful !== null) {
            let last = this.state.trxReceipt;

            let descr = "Dismissed branch";
            if (this.state.branchAccepted) descr = "Accepted branch";

            return <TRXReceipt
                descr={descr}
                trxHash={last.transactionHash}
                trxGasUsed={last.gasUsed}
                estimatedGas={this.state.estimatedGasUse}
            />;
        }
    }

    renderBranchActionButtons() {
        if (!this.state.branchAccepted && this.props.rootRecord[8].length === 0) {
            let daysLeft = this.getDaysLeftForAutoMerge();
            let spinner;
            if (this.state.decidingOnBranch) {
                spinner = <div id={"spinner-container"}>
                    <img id="spinner" src={process.env.PUBLIC_URL + '/spinner.gif'} alt={"spinner"}/>
                </div>;
            }

            return (
                <div>
                    <div id={"button-container"} className={"inline"}>
                        <UserButton
                            id={"accept-button"}
                            value={"Accept branch"}
                            onClick={() => this.onAcceptBranch()}
                            disabled={this.state.decidingOnBranch}
                        />
                        <UserButton
                            id={"dismiss-button"}
                            value={"Dismiss branch"}
                            onClick={() => this.onDismissBranch()}
                            disabled={this.state.decidingOnBranch}
                        />
                        {spinner}
                    </div>
                    <div id={"time-to-decide-container"}>
                        <p>
                            You have <b>{daysLeft}</b> days left to decide.<br/>
                            The branch will be automatically accepted after the days elapse.
                        </p>
                    </div>
                    {this.renderErrorMessage()}
                </div>
            );
        } else if (!this.state.branchAccepted) {
            return (
                <div>
                    {this.renderTRXReceipt()}
                    <div id={"branch-dismissed"}>
                        <p>You have dismissed the branch.</p>
                    </div>
                </div>
            );
        } else if (this.state.branchAccepted) {
            return (
                <div>
                    {this.renderTRXReceipt()}
                    <div id={"branch-dismissed"}>
                        <p>You have accepted the branch.</p>
                    </div>
                </div>
            );
        }

    }

    renderBranchInfo() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        if (this.props.rootRecord) {
            if (this.props.rootRecord[8].length > 0) {
                let infoContent = <div className={"descr-container"}>
                    <p className={"descr"}>
                        The creator of this record hasn't officially received the ownership of the
                        original record. This means that all information on this page is unconfirmed.
                    </p>
                    <p className={"descr"}>
                        The creator of the original record might still accept (and thereby confirm) or dismiss
                        this record as invalid.
                    </p>
                </div>;

                if (this.props.rootRecord[11] === "true") {
                    infoContent = <p className={"descr"}>The creator of the original record has dismissed this branch.</p>;
                } else if (this.props.rootRecord[12] === "true") {
                    infoContent = <p className={"descr"}>
                        This branch has been accepted.<br/>
                        This means that this branch is being continued on the original branch.
                    </p>;
                } else if (this.getDaysLeftForAutoMerge() === 0) {
                    infoContent = <DueBranchMerger
                        forMobile={this.props.appReducer.mobileMode}
                        rootRecord={this.props.rootRecord}
                        reloadCallback={() => this.props.reloadCallback()}
                    />;
                }

                return (
                    <div id={"branch-info-container" + mobileSuffix}>
                        <h2>This is a branched Record</h2>
                        {infoContent}
                        <p className={"link"} onClick={() => this.onViewOriginalClick(this.props.rootRecord[8])}>
                            View the original Record
                        </p>
                    </div>
                )
            } else if (this.props.rootRecord[9].length > 0
                && this.props.walletReducer.connectedAddress
                && this.props.currentOwner === this.props.walletReducer.connectedAddress) {
                return (
                    <div id={"branch-info-container"}>
                        <h1>Someone created a branch of this Record</h1>

                        <div className={"descr-container"}>
                            <p className={"descr"}>
                                This means that someone else claims to own the product of this Record, but couldn't
                                reach you, the owner, to initiate a Record ownership transfer.
                            </p>

                            <p className={"descr"}>
                                You can decide whether the branch should be accepted or dismissed. If you accept the branch,
                                all record extensions of the branch will be merged to this record. Also <b>your Record
                                ownership
                                will be transferred</b> to the creator of the branch.
                                <br/><br/>
                                If you decide to dismiss the branch, you will stay the owner of this Record. Furthermore,
                                Ventrace will remove the branch from this Record.
                                <br/>
                            </p>
                        </div>

                        <p className={"link"} onClick={() => this.onViewOriginalClick(this.props.rootRecord[9])}>
                            View the branched Record
                        </p>

                        <VTRCalculator
                            id={"vtr-calc"}
                            posName={"Accepting branch"}
                            rateName={"getRateOwnershipTransferNextOwner"}
                            onGetGasUseEstimate={(estimate) => this.onEstimatedGasUse(estimate)}
                        />
                        {this.renderBranchActionButtons()}
                    </div>
                )
            }

            return null;
        }


    }

    renderRecordAnimation() {
        if (appConfig.playVideos) {
            return (
                <video
                    id={"record-rotation-video"}
                    poster={process.env.PUBLIC_URL + '/assets/video/Record_R02_Rotation.png'}
                    autoPlay preload muted loop playsInline>
                    <source src={process.env.PUBLIC_URL + '/assets/video/Record_R02_Rotation.mp4'} type="video/mp4"/>
                </video>
            );
        } else {
            return (
                <img id={"record-rotation-video"}
                     src={process.env.PUBLIC_URL + '/assets/video/Record_R02_Rotation.png'}
                />
            );
        }
    }

    renderAnimationInfoText() {
        if (this.state.showAnimInfoText) {
            return (
                <div id={"anim-info-text"}>
                    <p id={"descr"}>
                        The spinning object above is the actual Ventrace Record, which is containing
                        all details presented on this page.
                    </p>
                    <p id={"order-text"}>
                        You can also get verified updates about your high-value {this.getProductWord()} automatically.
                        Save money on possible repairs and preserve your {this.getProductWord()}.&nbsp;
                        <a href={"/order?ref=dp"} target={"_blank"}>Order your own</a> Ventrace Record now!
                    </p>
                </div>
            )
        } else {
            return (
                <AiFillQuestionCircle
                    id={"anim-question-mark"}
                    onClick={() => this.onShowAnimInfoClick()}
                />
            )
        }
    }

    renderDisputeDecision() {
        if (this.props.data) {
            return (
                <DisputeDecision
                    itemType={"RR"}
                    itemID={this.props.rootRecordID}
                    decisions={this.props.disputeDecisions}
                />
            );
        }
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        let data = this.getData();

        if (!this.state.submissionSuccessful) {
            let originatorAddress = data.originator;
            originatorAddress = originatorAddress.slice(0, appConfig.address_SliceLen)
                + "-" + originatorAddress.slice(-appConfig.address_SliceLen);


            let changeDateText = "Created on";
            let activationText = "Record activation";
            let captureType = "Purchase";
            let imagePresenter;

            if (this.props.appReducer.mobileMode) {
                changeDateText = "";
                activationText = "Activation";
            }

            if (this.props.pendingRecordData) {
                let pRR = this.props.pendingRecordData;
                if (pRR.rootRecordData.captureType === "Mnf" && pRR.isClaimable) {
                    captureType = "Production";
                } else if (pRR.rootRecordData.captureType === "Stock" && pRR.isClaimable) {
                    captureType = "Inventory addition";
                }
            } else {
                imagePresenter = (
                    <div id={"img-presenter"}>
                        <ImagePresenter
                            imageCIDs={data.imageCIDs}
                            forMobile={this.props.appReducer.mobileMode}
                        />
                    </div>
                );
            }

            return (
                <div id={"root-record-container" + mobileSuffix} ref={this.props.parentRef}>
                    <div id={"animation-container"}>
                        {this.renderRecordAnimation()}
                        {this.renderAnimationInfoText()}
                    </div>

                    <div className={"record-content"}>
                        {this.renderBranchInfo()}

                        <div className={"l1 inline"}>
                            <p className={"record-type"} id={"root"}>
                                <BiCertification className={"type-icon"}/>
                                {activationText}
                            </p>
                            <div className={"change-date-container"}>
                                <p id={"label"}>{changeDateText}&nbsp;</p>
                                <p id={"data"}>
                                    <BsCalendarWeek className={"cal-icon"}/>
                                    {DateConverter.dateObjToString(parseInt(data.recordCreationDate))}
                                </p>
                            </div>
                        </div>

                        <div className={"l2"}>
                            <CreatorInfo
                                title={"Verified owner of Record"}
                                creatorAddress={data.originator}
                                fromProvider={this.props.pendingRecordData ? this.props.pendingRecordData.isClaimable : false}
                                providerID={this.props.pendingRecordData ? 
                                    this.props.pendingRecordData.rootRecordData.providerID : null}
                                defaultRender={(
                                    <div className={"originator"}>
                                        <p className={"label"}>Creator</p>
                                        <a className={"data"}
                                           onClick={() =>
                                               this.clickCreatorAddress(data.originator)}>
                                            {originatorAddress}
                                        </a>
                                    </div>
                                )}
                            />

                        </div>

                        <div className={"l3"}>
                            <div id={"purchase-date"}>
                                <p className={"label"}>{captureType} date</p>
                                <p className={"data"}>
                                    <AiOutlineShoppingCart id={"purchase-date-icon"}/>
                                    {DateConverter.dateObjToString(parseInt(data.purchaseDate))}
                                </p>
                            </div>
                            <div id={"serial-nr"}>
                                <p className={"label"}>Serial number</p>
                                <p className={"data"}>
                                    <CiBarcode id={"serial-nr-icon"}/>
                                    {data.serialNr}
                                </p>
                            </div>
                        </div>

                        <div className={"l4"}>
                            <div id={"descr-container"}>
                                <p id={"descr-label"}>Description</p>
                                <div className={"inline"} id={"descr-val"}>
                                    <p id={"descr"}>
                                        <span className={"red-quotes"}>“</span>
                                        {data.description}
                                        <span className={"red-quotes"}>”</span>
                                    </p>
                                </div>
                            </div>

                            {imagePresenter}
                            {this.renderDisputeDecision()}

                        </div>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        walletReducer: state.wallet,
        blockchainReducer: state.blockchain,
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchBlockchainLoading: () => {
            dispatch(requestSetBlockchainLoading())
        },
        dispatchBlockchainLoadingStop: () => {
            dispatch(requestSetBlockchainLoadingStop())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RootRecord);