import React from "react";
import {connect} from "react-redux";
import appConfig from "../appConfig";
import {create} from "ipfs-http-client";
import axios from "axios";
import Logger from "js-logger";
import TextArea from "../components/inputs/TextArea";
import {DateConverter} from "../DateConverter";
import {requestSetBlockchainLoading, requestSetBlockchainLoadingStop} from "../store/actions/blockchain";
import HintContainer from "./HintContainer";
import UserButton from "./inputs/UserButton";
import FeedbackMessage from "./FeedbackMessage";
import VTRCalculator from "./VTRCalculator";
import TRXReceipt from "./TRXReceipt";
import {EIP712types} from "../EIP712types";
import {DataReporter} from "../DataReporter";
import SidebarExplainer from "./SidebarExplainer";
import "../styles/components/disputereporter.scss";


class DisputeReporter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitting: false,
            submissionSuccessful: null,
            minInputLength: 10,
            problemDescr: null,
            problemDescrValid: true,
            problemDescrValidMsg: null,
            selectedExtensionsIDs: [],
            errorMessage: null,
            rateToPay: null,
            enoughBalance: false,
            estimatedGasUse: null,
            disputeOngoing: null,
            ongoingDisputeData: null,
            closed: false,
        }
    }

    getDisputeReportJSON() {
        return {
            "creationDate": Date.now().toString(),
            "originator": this.props.walletReducer.connectedAddress,
            "rootRecordID": this.props.recordReceipt[0][0],
            "rootRecordRouteParam": this.props.recordRouteParam,
            "selectedExtensions": this.state.selectedExtensionsIDs,
            "description": this.state.problemDescr
        }
    }

    getExtListItem(index, type, date) {
        let isSelected = this.state.selectedExtensionsIDs.includes(this.props.recordReceipt[index - 1][0]);
        let classSelected = isSelected ? " item-selected" : "";

        return (
            <div className={"ext-list-item-container" + classSelected}
                 onClick={() => this.onClickExtension(index - 1)}>
                <p className={"index"}>{index}</p>
                <p className={"type"}>{type}</p>
                <p className={"creation-date"}>
                    {DateConverter.dateObjToString(parseInt(date))}
                </p>
            </div>
        );
    }

    isValidToSubmit() {
        let canSave = true;
        this.setState({
            problemDescrValid: true,
            problemDescrValidMsg: null,
        }, () => {
            DataReporter.trackMixpanel(this.props, "Validating dispute report", {
                category: "Interaction",
            });
        });

        if (!this.state.problemDescr || this.state.problemDescr.length < this.state.minInputLength) {
            this.setState({
                problemDescrValid: false,
                problemDescrValidMsg: "Your description is too short."
            }, () => {
                DataReporter.trackMixpanel(this.props, "Validation Error: Description too short", {
                    category: "Interaction",
                });
            });
            canSave = false;
        }

        let regex = /[<>{;}]/;

        if (regex.test(this.state.problemDescr)) {
            this.setState({
                problemDescrValid: false,
                problemDescrValidMsg: "Your description can't contain special characters."
            }, () => {
                DataReporter.trackMixpanel(this.props, "Validation Error: Description has special characters", {
                    category: "Interaction",
                });
            });

            canSave = false;
        }

        return canSave;
    }

    submitDisputeReport() {
        this.setState({submitting: true},
            async () => {
                if (this.isValidToSubmit()) {
                    this.props.dispatchBlockchainLoading();

                    let vtrTokenContract = this.props.blockchainReducer.vtrTokenContract;
                    vtrTokenContract.methods.approve(appConfig.currentConfig.contracts.address_VTR_Manager,
                        this.state.rateToPay*appConfig.approvalMultiply)
                        .send({
                            from: this.props.walletReducer.connectedAddress,
                            gas: appConfig.currentConfig.blockchainGasLimit
                        })
                        .then(async (receipt) => {
                            // Upload data as JSON to IPFS
                            let auth = "Basic " + Buffer.from(appConfig.IPFS_ProjectID + ":" + appConfig.IPFS_Key).toString("base64");
                            let client = create({url: appConfig.IPFS_Endpoint, headers: {authorization: auth}});

                            let storedJSON = await client.add(JSON.stringify(this.getDisputeReportJSON()));

                            try {
                                let signature = await window.ethereum.request({
                                    method: 'eth_signTypedData_v4',
                                    params: [this.props.walletReducer.connectedAddress,
                                        JSON.stringify(EIP712types.newDisputeReport(storedJSON.path))
                                    ],
                                });

                                let rsv = EIP712types.getRSVfromSignature(signature);

                                let disputeContract = this.props.blockchainReducer.disputeContract;
                                disputeContract.methods.addDisputeReport(this.props.recordReceipt[0][0], storedJSON.path, rsv.r, rsv.s, rsv.v)
                                    .send({
                                        from: this.props.walletReducer.connectedAddress,
                                        gas: appConfig.currentConfig.blockchainGasLimit
                                    })
                                    .then((receipt) => {
                                        this.props.dispatchBlockchainLoadingStop();

                                        this.setState({
                                            trxReceipt: receipt
                                        }, () => {
                                            this.onSubmissionSuccessful(receipt);
                                        })

                                        DataReporter.trackMixpanel(this.props, "Success: Dispute report created", {
                                            category: "Interaction",
                                        });

                                    })
                                    .catch((err) => {
                                        this.setState({
                                            errorMessage: "Error during dispute reporting (1). Please try again."
                                        }, () => {
                                            this.props.dispatchBlockchainLoadingStop();

                                            DataReporter.trackMixpanel(this.props, "Dispute reporter: Error during submission");
                                        });
                                    })

                            } catch (err) {
                                let msg = "Signing failed. Please check if your wallet is configured correctly.";
                                if (err.message.toLowerCase().includes("chainid")) {
                                    msg = `Signing failed. Please check if your wallet is connected to the ${appConfig.currentConfig.blockchainName} network.`;
                                }

                                this.setState({
                                    errorMessage: msg,
                                    submitting: false
                                }, () => {
                                    DataReporter.trackMixpanel(this.props, "Dispute reporter: Signing failed");
                                });
                            }
                        })
                        .catch((err) => {
                            this.setState({
                                errorMessage: "Could not approve VTR cost. Please try again."
                            }, () => {
                                this.props.dispatchBlockchainLoadingStop();

                                DataReporter.trackMixpanel(this.props, "Error: Appoving dispute report", {
                                    category: "Interaction",
                                });
                            });
                        })

                } else {
                    this.setState({
                        submitting: false
                    }, () => {
                        DataReporter.trackMixpanel(this.props, "Dispute reporter: Not valid to submit");
                    });
                }
            });
    }

    checkForOpenDispute() {
        if (!this.state.submitting) {
            this.setState({submitting: true}, () => {
                let disputeContract = this.props.blockchainReducer.disputeContract;

                disputeContract.methods.getDisputeByRRid(this.props.recordReceipt[0][0])
                    .call({
                        from: this.props.walletReducer.connectedAddress,
                        gas: appConfig.currentConfig.blockchainGasLimit
                    })
                    .then(async (receipt) => {
                        this.props.dispatchBlockchainLoadingStop();

                        if (receipt.length > 0) {
                            let responseJson = await axios.get(appConfig.IPFS_ProjectURL + receipt);
                            let disputeCreatedByWallet = responseJson.data.originator.toLowerCase()
                                === this.props.walletReducer.connectedAddress.toLowerCase()

                            // Check if dispute has been closed
                            disputeContract.methods.getDisputeDecisionsByRRid(this.props.recordReceipt[0][0])
                                .call({
                                    from: this.props.walletReducer.connectedAddress,
                                    gas: appConfig.currentConfig.blockchainGasLimit
                                })
                                .then(async (decisionsReceipt) => {

                                    let disputePromises = [];
                                    for (let i = 0; i < decisionsReceipt.length; i += 1) {
                                        disputePromises.push(axios.get(appConfig.IPFS_ProjectURL + decisionsReceipt[i]));
                                    }

                                    Promise.all(disputePromises)
                                        .then(promisesRes => {
                                            let closed = false;
                                            for (let i = 0; i < promisesRes.length; i += 1) {
                                                if (promisesRes[i].data.decision === "close") {
                                                    closed = true;
                                                    break;
                                                }
                                            }

                                            this.setState({
                                                disputeOngoing: disputeCreatedByWallet,
                                                closed: closed,
                                                ongoingDisputeData: responseJson.data,
                                                submitting: false
                                            }, () => {
                                                DataReporter.trackMixpanel(this.props, "Dispute reporter: Previous dispute existing");
                                            });

                                        })
                                        .catch((err) => {
                                            Logger.info(err);
                                        })
                                })
                                .catch((err) => {
                                    this.setState({
                                        errorMessage: "Error when checking for ongoing dispute decisions."
                                    }, () => {
                                        this.props.dispatchBlockchainLoadingStop();
                                    });
                                })
                        } else {
                            this.setState({
                                disputeOngoing: false,
                                submitting: false
                            });
                        }
                    })
                    .catch((err) => {
                        if (err.message.includes("Not found")) {
                            this.setState({
                                disputeOngoing: false,
                                submitting: false
                            });
                        } else {
                            this.setState({errorMessage: "Error when checking for ongoing disputes."},
                                () => {
                                    this.props.dispatchBlockchainLoadingStop();
                                });
                        }
                    })
            })
        }
    }

    getSideBarText() {
        return [
            {
                "name": "Submit the dispute report",
                "descr": "Use this dispute form to describe the problem with this record."
            },
            {
                "name": "Wait for moderation",
                "descr": "Your dispute will be queued for review by humans. This can take 2-3 days."
            },
            {
                "name": "Dispute decisions",
                "descr": "The dispute may be answered by a comment, an invalidation (removal) of an extension, a closure" +
                    " of the whole record or by blocking the address which created the disputed content."
            },
            {
                "name": "Stay up to date",
                "descr": "You will not receive an email when your dispute gets reviewed. Therefore make sure that you"
                    + " check your record regularly."
            },
        ];
    }

    // Events
    componentDidMount() {
        DataReporter.trackMixpanel(this.props, "Component view: Dispute reporter", {
            pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop"
        });
    }

    componentWillUnmount() {
        DataReporter.trackMixpanel(this.props, "Component exit: Dispute reporter");
    }

    descrOnChange(e) {
        if (e.inputValue.length >= this.state.minInputLength) {
            this.setState({problemDescr: e.inputValue});
        }
    }

    descrOnPaste(e) {
        this.descrOnChange({inputValue: e.clipboardData.getData("text")});
    }

    onClickExtension(i) {
        let e = this.state.selectedExtensionsIDs;
        let addIndex = this.props.recordReceipt[i][0];

        if (e.includes(addIndex)) {
            e.splice(e.indexOf(addIndex, 1));
        } else {
            e.push(this.props.recordReceipt[i][0]);
        }

        this.setState({
            selectedExtensionsIDs: e
        }, () => {
            DataReporter.trackMixpanel(this.props, "Dispute reporter: Clicked on extension");
        })
    }

    onEstimatedGasUse(estimate) {
        this.setState({
            estimatedGasUse: estimate
        });
    }

    onVTRGetRate(rate) {
        if (this.props.walletReducer.vtrBalance) {
            this.setState({
                enoughBalance: this.props.walletReducer.vtrBalance >= Math.abs(rate),
                rateToPay: Math.abs(rate)
            });
        }
    }

    onSubmissionSuccessful(receipt) {
        this.setState({
            submissionSuccessful: true,
            submitting: false,
            errorMessage: null
        }, () => {
            DataReporter.trackMixpanel(this.props, "Dispute reporter: Submission successful");
        })
    }

    // Renderers
    renderTRXReceipt() {
        if (this.state.submissionSuccessful !== null) {
            DataReporter.trackMixpanel(this.props, "TRX receipt: Dispute report", {
                category: "Interaction"
            });

            return (
                <div id={"trx-receipt-container"}>
                    <TRXReceipt
                        forMobile={this.props.appReducer.mobileMode}
                        descr={"Reported dispute"}
                        trxHash={this.state.trxReceipt.transactionHash}
                        trxGasUsed={this.state.trxReceipt.gasUsed}
                        estimatedGas={this.state.estimatedGasUse}
                    />
                </div>
            );
        }
    }

    renderErrorMessage() {
        if (this.state.errorMessage !== null) {
            return <FeedbackMessage
                success={false}
                message={this.state.errorMessage}
            />;
        }
    }

    renderExtensions() {
        let extListItems = [];
        for (let i = 1; i < this.props.completeRecord.length; i += 1) {
            let recItem = this.props.completeRecord[i];

            if (i === 0) {
                extListItems.push(this.getExtListItem(i + 1, "Initial record", recItem.data.purchaseDate));
            } else {
                if (recItem.data.recordType === "ownershipTransferOpening") {
                    extListItems.push(this.getExtListItem(i + 1, "Transfer opening",
                        recItem.data.recordCreationDate));

                } else if (recItem.data.recordType === "ownershipTransferConfirmation") {
                    extListItems.push(this.getExtListItem(i + 1, "Transfer confirmation", recItem.data.recordCreationDate));

                } else if (recItem.data.recordType === "setPrivateMode") {
                    extListItems.push(this.getExtListItem(i + 1, "Set private", recItem.data.recordCreationDate));

                } else if (recItem.data.recordType === "setPublicMode") {
                    extListItems.push(this.getExtListItem(i + 1, "Set public", recItem.data.recordCreationDate));

                } else if (recItem.data.recordType === "setPublicModeDue") {
                    extListItems.push(this.getExtListItem(i + 1, "Set public due", recItem.data.recordCreationDate));

                } else if (recItem.data.recordType === "branchCreated") {
                    extListItems.push(this.getExtListItem(i + 1, "Branch created", recItem.data.recordCreationDate));

                } else if (recItem.data.recordType === "branchAccepted") {
                    extListItems.push(this.getExtListItem(i + 1, "Branch accepted", recItem.data.recordCreationDate));

                } else if (recItem.data.recordType === "branchDueAccepted") {
                    extListItems.push(this.getExtListItem(i + 1, "Branch due", recItem.data.recordCreationDate));

                } else if (recItem.data.recordType === "branchDismissed") {
                    extListItems.push(this.getExtListItem(i + 1, "Branch dismissed", recItem.data.recordCreationDate));

                } else if (recItem.data.recordType === "generalExtension") {
                    extListItems.push(this.getExtListItem(i + 1, recItem.data.changeType, recItem.data.recordCreationDate));

                }
            }
        }
        return (
            <div id={"record-li-container"}>
                <p id={"h"}>Relevant update (if applicable)</p>
                <p id={"descr"}>
                    The following extensions are summarized from the current <span className={"vr-hl"}>Ventrace Record</span>.
                </p>
                {extListItems}
            </div>
        );
    }

    renderDisputeOngoing() {
        if (this.state.disputeOngoing === true && !this.state.closed) {
            let date = DateConverter.dateObjToString(parseInt(this.state.ongoingDisputeData.creationDate));
            return (
                <div id={"dispute-ongoing-container"}>
                    <p id={"info"}>
                        You've already created a dispute about this Record (created on {date}).<br/>
                        Check the Record's history for comments by Ventrace moderators.<br/><br/>
                        Otherwise, if you have another concern, you can use the&nbsp;
                        <a href={"/support"}>Help & Support</a> page.
                    </p>
                </div>
            );
        }
    }

    renderSubmitContainer() {
        if (this.state.submissionSuccessful === null) {
            let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

            let disabled = this.state.submitting;

            let spinner;
            if (this.state.submitting) {
                spinner = <div id={"spinner-container" + mobileSuffix}>
                    <img id="spinner" src={process.env.PUBLIC_URL + '/spinner.gif'} alt={"spinner"}/>
                </div>;
            }

            return (
                <div id={"submit-dispute-container"}>
                    <VTRCalculator
                        posName={"Creating a dispute report"}
                        rateName={"getRateCreateDisputeReport"}
                        onGetRate={(rate) => this.onVTRGetRate(rate)}
                        onGetGasUseEstimate={(estimate) => this.onEstimatedGasUse(estimate)}
                    />
                    {this.renderErrorMessage()}
                    <div className={"inline" + mobileSuffix}>
                        <UserButton
                            forMobile={this.props.appReducer.mobileMode}
                            id={"submit-dispute-button"}
                            value={disabled ? "Creating dispute report" : "Create dispute report"}
                            onClick={() => this.submitDisputeReport()}
                            disabled={disabled}
                        />
                        {spinner}
                    </div>
                    <HintContainer
                        forMobile={this.props.appReducer.mobileMode}
                        id={"hint-container"}
                        hints={[
                            "You will be prompted to sign two transactions.",
                            "The first signature is for approving the cost in VTR.",
                            "The second is is the actual dispute report.",
                        ]}
                    />
                </div>
            );
        }
    }

    renderSubmitForm() {
        return (
            <div>
                <h1>Submit a Ventrace Record dispute</h1>
                <p id={"descr"}>
                    If you think that this Record contains false information, you can submit a formal
                    dispute to Ventrace. Your request will be moderated by human beings.
                </p>

                <TextArea
                    id={"problem-descr-input"}
                    label={"Problem description*"}
                    maxLen={140}
                    placeholder={"What's wrong with the record?"}
                    onChange={(e) => this.descrOnChange(e)}
                    onPaste={(e) => this.descrOnPaste(e)}
                    validationError={!this.state.problemDescrValid}
                    validationMsg={this.state.problemDescrValidMsg}
                />

                {this.renderExtensions()}
                {this.renderSubmitContainer()}
            </div>
        );
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        let content = null
        let rightSide = null;

        if (!this.state.submissionSuccessful) {
            if (this.state.disputeOngoing === null) {
                this.checkForOpenDispute();
                content = (
                    <div id={"checking-container"} className={"inline"}>
                        <img id="spinner" src={process.env.PUBLIC_URL + '/spinner.gif'} alt={"spinner"}/>
                        <p id={"info"}>Checking if a dispute has already been reported.</p>
                    </div>
                );

            } else if (this.state.disputeOngoing === false || this.state.closed) {
                content = this.renderSubmitForm();
                rightSide = (
                    <div id={"right"}>
                        <SidebarExplainer
                            forMobile={this.props.appReducer.mobileMode}
                            id={"sidebar-explainer"}
                            h1={"How a reporting a dispute works"}
                            steps={this.getSideBarText()}
                        />
                    </div>
                );
            }
        }

        return (
            <div className={"dispute-recorder-container" + mobileSuffix}>
                <div id={"left"}>
                    {content}
                </div>
                {rightSide}
                {this.renderDisputeOngoing()}
                {this.renderTRXReceipt()}

                <div id={"clearer"}/>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        walletReducer: state.wallet,
        blockchainReducer: state.blockchain,
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchBlockchainLoading: () => {
            dispatch(requestSetBlockchainLoading())
        },
        dispatchBlockchainLoadingStop: () => {
            dispatch(requestSetBlockchainLoadingStop())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisputeReporter);