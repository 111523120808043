import React from "react";
import {connect} from "react-redux";
import axios from "axios";
import Logger from "js-logger";
import ContentLoader from "react-content-loader";
import Footer from "../components/Footer";
import appConfig from "../appConfig";
import moment from "moment/moment";
import Navigation from "../components/Navigation";
import UserButton from "../components/inputs/UserButton";
import QuickSummaryBox from "../components/recordDetails/QuickSummaryBox";
import RecordCryptoValueBox from "../components/recordDetails/RecordCryptoValueBox";
import ExtensionCreator from "../components/ExtensionCreator";
import OwnershipChanger from "../components/OwnershipChanger";
import RecordPrivacySwitcher from "../components/RecordPrivacySwitcher";
import DisputeReporter from "../components/DisputeReporter";
import LossNoticeReporter from "../components/LossNoticeReporter";
import RootRecord from "../components/recordDetails/RootRecord";
import ExtensionRecord from "../components/recordDetails/ExtensionRecord";
import TransferOpening from "../components/recordDetails/TransferOpening";
import TransferConfirmation from "../components/recordDetails/TransferConfirmation";
import PrivateModeExtension from "../components/recordDetails/PrivateModeExtension";
import PublicModeExtension from "../components/recordDetails/PublicModeExtension";
import PublicModeDueExtension from "../components/recordDetails/PublicModeDueExtension";
import BranchCreation from "../components/recordDetails/BranchCreation";
import BranchAccepted from "../components/recordDetails/BranchAccepted";
import BranchDismissed from "../components/recordDetails/BranchDismissed";
import BranchDueAccepted from "../components/recordDetails/BranchDueAccepted";
import RecordBranchCreator from "../components/RecordBranchCreator";
import DuePublicSetter from "../components/DuePublicSetter";
import {requestSetBlockchainLoading, requestSetBlockchainLoadingStop} from "../store/actions/blockchain";
import {
    AiOutlineEyeInvisible, AiOutlineCheck, AiFillThunderbolt,
    AiOutlineFieldTime, AiFillInfoCircle
} from "react-icons/ai";
import {GiHouseKeys} from "react-icons/gi";
import {VscExtensions} from "react-icons/vsc";
import {BsFillBackspaceFill} from "react-icons/bs";
import {MdPublic} from "react-icons/md";
import {RiFileCopyLine} from "react-icons/ri";
import {HiOutlineGlobeAlt} from "react-icons/hi";
import UIConfigLoader from "../components/UIConfigLoader";
import CheckboxInput from "../components/inputs/CheckboxInput";
import FeedbackMessage from "../components/FeedbackMessage";
import DropdownInput from "../components/inputs/DropdownInput";
import PendingExtensions from "../components/recordDetails/PendingExtensions";
import {requestSetClaimingExtension, requestSetClaimingRecord, requestSetCurrentPage} from "../store/actions/app";
import {DataReporter} from "../DataReporter";
import Web3 from "web3";
import * as recordManagerBuild from "../ABIs/recordManager";
import * as disputeContractBuild from "../ABIs/disputes";
import "../styles/pages/recorddetails.scss";
import customWagmiChains from "../customWagmiChains";

class RecordDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gettingRecord: false,
            recordReceipt: null,
            completeRecordData: null,
            pendingRecordData: null,
            rootRecord: null,
            rootRecordID: null,
            routeParam: null,
            lastOwnerAddress: null,
            addExtensionMode: false,
            changeOwnershipMode: false,
            createBranchMode: false,
            setPrivacyMode: false,
            reportLossMode: false,
            showTimeBetweenRecords: false,
            copiedToClipboard: false,
            extensionDisputes: [],
            gettingDisputes: false,
            singleRecordValues: [],
            weeklyBonusValue: null,
            gettingSingleRecordValues: false,
            extDisputedPercentage: null,
            reportDisputeMode: false,
            disputeDecisions: [],
            errorMessage: null,
            updateFilter: null,
            reportedLoss: null,
            lossNoticeChecked: false,
            pendingExtensions: [],
            gettingPendingExt: false,
            damageUpdateRefSet: false,
            modUpdateRefSet: false
        }

        this.rootRecordRef = React.createRef();
        this.modUpdateRef = React.createRef();
        this.damageUpdateRef = React.createRef();
    }

    getUpdateDisputes(extensionIDs) {
        if (this.state.extensionDisputes.length <= 0 && !this.state.gettingDisputes) {
            this.setState({gettingDisputes: true}, async () => {

                let recordManagerContract = this.props.blockchainReducer.recordManagerContract;
                let disputes = [];

                for (let i = 0; i < extensionIDs.length; i += 1) {
                    let waitTime = Math.random() * (4000 - 1500) + 1500;

                    setTimeout(() => {
                        if (!appConfig.onDev) {
                            let publicRPC = appConfig.useMainnet ? customWagmiChains.getPolygonMainnet().rpcUrls.public.http
                                : customWagmiChains.getAmoy().rpcUrls.public.http;

                            let web3 = new Web3(publicRPC);
                            recordManagerContract = new web3.eth.Contract(recordManagerBuild.abi,
                                appConfig.currentConfig.contracts.address_RecordManager)
                        }

                        recordManagerContract.methods.getExtensionDisputes(parseInt(extensionIDs[i]))
                            .call({
                                from: this.props.walletReducer.connectedAddress,
                                gas: appConfig.currentConfig.blockchainGasLimit
                            })
                            .then((disputeCIDs) => {
                                let promises = [];
                                for (let i = 0; i < disputeCIDs.length; i += 1)
                                    promises.push(axios.get(appConfig.IPFS_ProjectURL + disputeCIDs[i]));

                                Promise.all(promises)
                                    .then((allRes) => {
                                        if (allRes.length > 0) {
                                            disputes.push({
                                                extID: extensionIDs[i],
                                                res: allRes
                                            });
                                            if (this.state.extensionDisputes.length === 0) {
                                                this.setState({
                                                    extensionDisputes: disputes,
                                                    extDisputedPercentage: (disputes.length / extensionIDs.length) * 100
                                                });
                                            }
                                        }
                                    })
                                    .catch((err) => {
                                        this.props.dispatchBlockchainLoadingStop();
                                    })
                            })
                            .catch((err) => {
                                this.setState({
                                    errorMessage: "Unable to connect to the blockchain of Ventrace. " +
                                        "Please check if you are on the correct network or use the quick fix in the support page (2)."
                                }, () => {
                                    Logger.info(err.message);

                                    DataReporter.trackSentry(err, {
                                        extra: {additionalData: "getUpdateDisputes"}
                                    });
                                })
                            })

                    }, waitTime);
                }
            });
        }

    }

    getSingleRecordValues() {
        if (!this.state.gettingSingleRecordValues && this.state.singleRecordValues.length === 0) {
            this.setState({gettingSingleRecordValues: true},
                async () => {
                    this.props.dispatchBlockchainLoading();
                    let recordManagerContract = this.props.blockchainReducer.recordManagerContract;

                    recordManagerContract.methods.getRecordValues(this.state.rootRecordID)
                        .call({
                            from: this.props.walletReducer.connectedAddress,
                            gas: appConfig.currentConfig.blockchainGasLimit
                        })
                        .then((values) => {
                            this.setState({singleRecordValues: values});

                            let recordValuatorContract = this.props.blockchainReducer.recordValuatorContract;
                            recordValuatorContract.methods.getWeeklyValue()
                                .call({
                                    from: this.props.walletReducer.connectedAddress,
                                    gas: appConfig.currentConfig.blockchainGasLimit
                                })
                                .then((rate) => {
                                    this.setState({weeklyBonusValue: rate}, () => {
                                        this.props.dispatchBlockchainLoadingStop();
                                    });
                                })
                                .catch((err) => {
                                    this.props.dispatchBlockchainLoadingStop();

                                    DataReporter.trackSentry(err, {
                                        extra: {additionalData: "Getting weekly bonus rate."}
                                    });
                                })
                        })
                        .catch((err) => {
                            this.props.dispatchBlockchainLoadingStop();
                            Logger.info("Record value: ", err.message);

                            DataReporter.trackSentry(err, {
                                extra: {additionalData: "Getting record values."}
                            });
                        })
                })
        }
    }

    checkIfReportedLoss() {
        if (this.state.reportedLoss === null) {
            try {
                axios
                    .get(appConfig.currentConfig.backendApp.url + "/lossnotice/single",
                        {
                            headers: {
                                Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                                'Content-Type': 'application/json',
                            },
                            params: {
                                RRid: parseInt(this.state.rootRecord[0])
                            },
                        })
                    .then(res => {
                        if (res.status === 200 && res.data.isActive) {
                            this.setState({
                                reportedLoss: res.data,
                                lossNoticeChecked: true
                            });
                        } else {
                            this.setState({lossNoticeChecked: true});
                        }
                    })
                    .catch(err => {
                        DataReporter.trackMixpanel(this.props,
                            "Error checking existing loss notice", {category: "Error"});
                        DataReporter.trackSentry(err);
                    })

            } catch (err) {
                this.setState({
                    lossNoticeChecked: true
                });
                DataReporter.trackMixpanel(this.props, "Error connecting to backend.", {category: "Error"});
                DataReporter.trackSentry(err);
            }
        }
    }

    async getFromBlockchain(routeParam) {
        if (this.state.completeRecordData === null && this.state.gettingRecords) {
            this.props.dispatchBlockchainLoading();

            let recordManagerContract = this.props.blockchainReducer.recordManagerContract;
            let disputeContract = this.props.blockchainReducer.disputeContract;

            if (!this.props.appReducer.onRightNetwork && window.ethereum) {
                setTimeout(() => {
                    this.setState({
                        errorMessage: "Unable to connect to blockchain. " +
                            "Please check if you are on the correct network or use the quick fix in the support page (0)."
                    });
                }, 3000);
            }

            recordManagerContract.methods.getRootRecord(routeParam)
                .call({
                    from: this.props.walletReducer.connectedAddress,
                    gas: appConfig.currentConfig.blockchainGasLimit
                })
                .then((rootRecordReceipt) => {
                    recordManagerContract.methods.getCompleteRecord(routeParam)
                        .call({
                            from: this.props.walletReducer.connectedAddress,
                            gas: appConfig.currentConfig.blockchainGasLimit
                        })
                        .then((completeRecordReceipt) => {

                            disputeContract.methods.getDisputeDecisionsByRRid(parseInt(completeRecordReceipt[0][0]))
                                .call({
                                    from: this.props.walletReducer.connectedAddress,
                                    gas: appConfig.currentConfig.blockchainGasLimit
                                })
                                .then((disputeReceipt) => {
                                    let disputePromises = [];
                                    for (let i = 0; i < disputeReceipt.length; i += 1) {
                                        disputePromises.push(axios.get(appConfig.IPFS_ProjectURL + disputeReceipt[i]));
                                    }

                                    Promise.all(disputePromises)
                                        .then((disputePromisesRes) => {
                                            this.getCompleteRecordReceipt(completeRecordReceipt,
                                                rootRecordReceipt, routeParam, disputePromisesRes);
                                        })
                                        .catch((err) => {
                                            this.props.dispatchBlockchainLoadingStop();
                                            Logger.info(err.message);

                                            DataReporter.trackSentry(err, {
                                                extra: {additionalData: "Getting dispute records."}
                                            });
                                        })

                                })
                                .catch((err) => {
                                    if (err.message.includes("Not found")) {
                                        this.getCompleteRecordReceipt(completeRecordReceipt,
                                            rootRecordReceipt, routeParam, []);
                                    } else {
                                        this.setState({
                                            errorMessage: "Error: Failed during dispute check."
                                        }, () => {

                                            this.props.dispatchBlockchainLoadingStop();
                                            DataReporter.trackSentry(err, {
                                                extra: {additionalData: "Getting disputes."}
                                            });
                                        })
                                    }
                                })
                        })
                        .catch((err) => {
                            this.setState({
                                errorMessage: "Error: Couldn't find Ventrace Record on blockchain. Please try again."
                            }, () => {
                                this.props.dispatchBlockchainLoadingStop();

                                DataReporter.trackMixpanel(this.props, "Details error: No Record found", {category: "Error"});
                                DataReporter.trackSentry(err);
                            })

                        })
                })
                .catch((err) => {
                    this.setState({
                        errorMessage: "Unable to connect to blockchain. " +
                            "Please check if you are on the correct network or use the quick fix in the support page (1)."
                    }, () => {
                        this.props.dispatchBlockchainLoadingStop();

                        DataReporter.trackMixpanel(this.props, "Details error: No connection to blockchain",
                            {category: "Error"})

                        DataReporter.trackSentry(err, {
                            extra: {additionalData: "Getting RR from rp param."}
                        });
                    })
                })
        }
    }

    getPendingRR(pendingRouteParam) {
        try {
            axios
                .get(appConfig.currentConfig.backendApp.url + "/pendingRR/single",
                    {
                        params: {prp: pendingRouteParam},
                        headers: {
                            Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                            'Content-Type': 'application/json',
                        }

                    })
                .then(res => {
                    if (res.status === 200) {
                        this.setState({
                            gettingRecord: false,
                            pendingRecordData: res.data
                        }, () => {
                            setTimeout(() => {
                                this.getPendingExtensions();
                            }, 1000);
                        })
                    } else {
                        this.setState({
                            errorMessage: "Couldn't find matching Record data."
                        }, () => {
                            DataReporter.trackMixpanel(this.props,
                                "pRR not found", {category: "Error"});
                        })
                    }
                })
                .catch(err => {
                    let displayErr;

                    if (err.message === "Network Error") {
                        displayErr = `There was a problem. Please try again.`;
                    } else {
                        displayErr = err.message;
                    }

                    this.setState({
                        errorMessage: displayErr
                    }, () => {
                        DataReporter.trackMixpanel(this.props, "Error getting pRR",
                            {category: "Error"});

                        DataReporter.trackSentry(err, {
                            extra: {additionalData: "getPendingRR"}
                        });
                    })
                })

        } catch (err) {
            this.setState({
                errorMessage: "Data connection error. Please try again.",
            }, () => {
                DataReporter.trackMixpanel(this.props, "Error getting pRR from backend.",
                    {category: "Error"});

                DataReporter.trackSentry(err, {extra: {additionalData: "getPendingRR"}});
            });
        }
    }

    getPendingExtensions() {
        if (this.state.completeRecordData && !this.state.gettingPendingExt) {
            this.setState({gettingPendingExt: true}, () => {
                try {
                    axios
                        .get(appConfig.currentConfig.backendApp.url + "/pendingExt",
                            {
                                params: {SNR: this.state.completeRecordData[0].data.serialNr},
                                headers: {
                                    Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                                    'Content-Type': 'application/json',
                                }
                            })
                        .then(res => {
                            if (res.status === 200) {
                                this.setState({
                                    pendingExtensions: res.data,
                                    gettingPendingExt: false
                                });
                            } else {
                                DataReporter.trackMixpanel(this.props, "getPendingExtensions: Returned != 200",
                                    {category: "Error"});
                            }
                        })
                        .catch(err => {
                            DataReporter.trackSentry(err, {
                                extra: {additionalData: "getPendingExtensions"}
                            });
                        })

                } catch (err) {
                    DataReporter.trackSentry(err, {
                        extra: {additionalData: "getPendingExtensions"}
                    });
                }
            });
        }
    }

    async getCompleteRecord() {
        let routeParam = new URLSearchParams(this.props.location.search).get("rp");
        let pendingRouteParam = new URLSearchParams(this.props.location.search).get("prp");

        if (routeParam) {
            await this.getFromBlockchain(routeParam);
        } else if (pendingRouteParam) {
            await this.getPendingRR(pendingRouteParam);
        } else {
            this.setState({
                errorMessage: "No reference for Record found."
            }, () => {
                DataReporter.trackMixpanel(this.props, "Error: No Record param.", {
                    category: "Error"
                });
            })
        }
    }

    getCompleteRecordReceipt(completeRecordReceipt, rootRecordReceipt, routeParam, disputePromisesRes) {
        let recordItemPromises = [];

        for (let i = 0; i < completeRecordReceipt.length; i += 1) {
            recordItemPromises.push(axios.get(appConfig.IPFS_ProjectURL + completeRecordReceipt[i][1]));
        }

        Promise.all(recordItemPromises)
            .then((allRes) => {
                this.props.dispatchBlockchainLoadingStop();

                this.setState({
                    rootRecordID: completeRecordReceipt[0][0],
                    rootRecord: rootRecordReceipt,
                    routeParam: routeParam,
                    recordReceipt: completeRecordReceipt,
                    completeRecordData: allRes,
                    disputeDecisions: disputePromisesRes,
                    gettingRecord: false,
                    errorMessage: null
                }, () => {
                    setTimeout(() => {
                        this.getPendingExtensions();
                    }, 2000);
                    setTimeout(() => {
                        this.checkIfReportedLoss();
                    }, 500);
                });
            })
            .catch((err) => {
                this.setState({
                    errorMessage: "Error: Couldn't resolve record data. Please try again."
                }, () => {
                    this.props.dispatchBlockchainLoadingStop();

                    DataReporter.trackSentry(err, {
                        extra: {additionalData: "Getting complete record."}
                    });

                    DataReporter.trackMixpanel(this.props, "Error: Getting complete record", {
                        category: "Display",
                    });
                });
            })
    }

    getCurrentOwnerAddress() {
        if (this.state.completeRecordData) {
            let currentOwnerAddress = null;

            let branchOwner = null;
            let branchAccepted = false;

            for (let i = 0; i < this.state.completeRecordData.length; i += 1) {
                if (this.state.completeRecordData[i].data.recordType === "rootRecord"
                    || this.state.completeRecordData[i].data.recordType === "ownershipTransferConfirmation") {
                    currentOwnerAddress = this.state.completeRecordData[i].data.originator;
                    branchAccepted = false;
                } else if (this.state.completeRecordData[i].data.recordType === "branchCreated") {
                    branchOwner = this.state.completeRecordData[i].data.originator;
                    branchAccepted = false;
                } else if (this.state.completeRecordData[i].data.recordType === "branchAccepted") {
                    branchAccepted = true;
                } else if (this.state.completeRecordData[i].data.recordType === "branchedRoot") {
                    currentOwnerAddress = this.state.completeRecordData[i].data.originator;
                }
            }
            if (branchOwner !== null && branchAccepted) return branchOwner;
            return currentOwnerAddress;
        }
        return null;
    }

    getOpeningReceiverAddress() {
        if (this.state.completeRecordData) {
            let openingReceiverAddress = null;
            for (let i = 0; i < this.state.completeRecordData.length; i += 1) {
                if (this.state.completeRecordData[i].data.recordType === "ownershipTransferOpening") {
                    openingReceiverAddress = this.state.completeRecordData[i].data.nextOwnerWalletAddress;
                }

                if (this.state.completeRecordData[i].data.recordType === "ownershipTransferConfirmation"
                    && openingReceiverAddress === this.state.completeRecordData[i].data.originator.toLowerCase()) {
                    openingReceiverAddress = null;
                }

            }
            return openingReceiverAddress;
        }
        return null;
    }

    getRemainingPrivateMonths() {
        if (this.state.completeRecordData) {
            let startDate = null;
            let monthCount = null;

            for (let i = 0; i < this.state.completeRecordData.length; i += 1) {
                let curr = this.state.completeRecordData[i];
                if (curr.data.recordType === "setPrivateMode") {
                    startDate = moment(parseInt(curr.data.recordCreationDate));
                    monthCount = curr.data.privateMonthCount;
                }
            }

            if (startDate) {
                let monthsPassedSinceCreation = startDate.diff(moment(), "months");
                return Math.abs(monthsPassedSinceCreation - parseInt(monthCount));
            }

            return null;
        }
    }

    getDaysSinceLastUpdate() {
        let history = this.state.completeRecordData;

        if (history !== null && history.length > 0) {
            let last = history[history.length - 1];

            let lastDate = moment(parseInt(last.data.recordCreationDate));
            let difference = moment().diff(lastDate, 'days');
            return difference;
        }
    }

    getRelevantTimeStamp(extRecord) {
        // branchDueAccepted = recordCreationDate
        // setPublicModeDue = recordCreationDate
        // ownershipTransferOpening = changeDate
        // ownershipTransferConfirmation = recordCreationDate
        // RootRecord = recordCreationDate
        // branchAccepted = recordCreationDate
        // branchDismissed = recordCreationDate
        // setPrivateMode = recordCreationDate
        // setPublicMode = recordCreationDate
        // generalExtension = changeDate

        let _type = extRecord.recordType;

        if (_type === "ownershipTransferOpening" || _type === "generalExtension") {
            return extRecord.changeDate;
        } else if (_type === "rootRecord") return extRecord.purchaseDate;
        else return extRecord.recordCreationDate;
    }

    getLastUpdateTimestamp() {
        let latest = this.state.completeRecordData.at(-1);
        return this.getRelevantTimeStamp(latest.data);
    }

    checkIfRecordIsInvalidated() {
        if (this.state.disputeDecisions.length > 0) {
            for (let i = 0; i < this.state.disputeDecisions.length; i += 1) {
                let curr = this.state.disputeDecisions[i];

                if (curr.data.selectedRecordItem &&
                    parseInt(curr.data.selectedRecordItem.id) === parseInt(this.state.rootRecordID)
                    && curr.data.decision === "invalidateRR") {
                    return true;
                }
            }
        }
        return false;
    }

    // Events
    componentDidMount() {
        this.props.dispatchSetPage("details");
        DataReporter.trackMixpanel(this.props, "Page view: Record details",
            { pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop" }
        );
    }

    onShowTimeDividerChange(isChecked) {
        this.setState({
            showTimeBetweenRecords: isChecked
        }, () => {
            DataReporter.trackMixpanel(this.props,
                isChecked ? "Showing time between records" : "Hiding time between records", {
                category: "Interaction"
            });
        });
    }

    onCancelExtension() {
        this.setState({
            addExtensionMode: false,
            changeOwnershipMode: false,
            setPrivacyMode: false,
            createBranchMode: false,
            reportDisputeMode: false,
            reportLossMode: false,
            completeRecordData: null
        }, () => {
            setTimeout(() => {
                this.setState({gettingRecords: true}, () => {
                    this.getCompleteRecord();
                    this.getPendingExtensions();
                });
            }, 100);
        });
    }

    onExtendButtonClick() {
        if (!this.state.addExtensionMode) {
            this.setState({
                addExtensionMode: true
            }, () => {
                DataReporter.trackMixpanel(this.props, "Clicked on add ext. button", {
                    category: "Interaction"
                });
            });
        }
    }

    onTransferOwnershipClick() {
        if (!this.state.changeOwnershipMode) {
            this.setState({
                changeOwnershipMode: true
            }, () => {
                DataReporter.trackMixpanel(this.props, "Clicked on change ownership", {
                    category: "Interaction"
                });
            });
        }
    }

    onChangePrivacyClick() {
        this.setState({
            setPrivacyMode: true
        }, () => {
            DataReporter.trackMixpanel(this.props, "Clicked on change privacy", {
                category: "Interaction"
            });
        });
    }

    onReportDisputeModeClick() {
        this.setState({
            reportDisputeMode: true
        }, () => {
            if (this.state.reportDisputeMode) {
                DataReporter.trackMixpanel(this.props, "Clicked on dispute record", {
                    category: "Interaction"
                });
            }
        });
    }

    onReportLossClick() {
        this.setState({
            reportLossMode: true
        }, () => {
            if (this.state.reportDisputeMode) {
                DataReporter.trackMixpanel(this.props, "Clicked on report loss", {
                    category: "Interaction"
                });
            }
        });
    }

    onCreateBranch() {
        if (!this.state.createBranchMode) {
            this.setState({createBranchMode: true}, () => {
                DataReporter.trackMixpanel(this.props, "Creating new branch", {
                    category: "Interaction",
                });
            });
        }
    }

    onCopyLinkToClipboard() {
        try {
            let url = `${appConfig.currentConfig.currentDomain}/record?rp=${this.state.routeParam}`;

            navigator.clipboard.writeText(url).then(function () {
                },
                function (err) {
                    DataReporter.trackSentry(err, {
                        extra: {additionalData: "Copy record URL to clipboard."}
                    });
                });

            this.setState({copiedToClipboard: true}, () => {
                DataReporter.trackMixpanel(this.props, "Copied record URL to clipboard", {
                    category: "Interaction"
                });
            });
        } catch (error) {
            DataReporter.trackSentry(error, {
                extra: {additionalData: "Catch: Copy record URL to clipboard."}
            });
        }
    }

    filterUpdatesOnChange(e) {
        if (e === "Show all") e = null;

        this.setState({
            updateFilter: e
        }, () => {
            DataReporter.trackMixpanel(this.props, "Filtering updates by type", {
                category: 'Interaction', value: e
            });
        });
    }

    onClickLiveURL(rp) {
        DataReporter.trackMixpanel(this.props, "Clicked on live Record link", {
            category: "Interaction"
        });

        window.open(`/record?rp=${rp}`, "_self");
    }

    onClaimRecordButtonClick() {
        this.props.dispatchSetClaimingRecord(this.state.pendingRecordData);
        setTimeout(() => {
            window.open("/order", "_self");
        }, 2000);
    }

    onClaimExtension(ext) {
        this.props.dispatchSetClaimingExtension(ext);
        this.setState({addExtensionMode: true}, () => {
            window.scrollTo(0, 0);
        });
    }

    onSetDamageUpdateRef(set) {
        this.setState({
            damageUpdateRefSet: set
        });
    }

    onSetModUpdateRef(set) {
        this.setState({
            modUpdateRefSet: set
        });
    }

    onScrollToRR() {
        DataReporter.trackMixpanel(this.props, "QuickSummary: Scrolling to RR");
        const targetYPosition = this.rootRecordRef.current.getBoundingClientRect().top;

        window.scrollTo({
            top: window.scrollY + targetYPosition - 40,
            behavior: 'smooth',
        });
    }

    onScrollToFirstDamage() {
        DataReporter.trackMixpanel(this.props, "QuickSummary: Clicked on damage");
        const targetYPosition = this.damageUpdateRef.current.getBoundingClientRect().top;

        window.scrollTo({
            top: window.scrollY + targetYPosition - 40,
            behavior: 'smooth',
        });
    }

    onScrollToFirstMod() {
        DataReporter.trackMixpanel(this.props, "QuickSummary: Clicked on mod");
        const targetYPosition = this.modUpdateRef.current.getBoundingClientRect().top;

        window.scrollTo({
            top: window.scrollY + targetYPosition - 40,
            behavior: 'smooth',
        });
    }

    // Renderers
    renderLightDotsAnim() {
        if (appConfig.playVideos) {
            let poster = process.env.PUBLIC_URL + '/assets/video/DetailsLights.jpg';
            let src = process.env.PUBLIC_URL + '/assets/video/DetailsLights.mp4';

            return (
                <div id={"anim-wrapper"}>
                    <video
                        id={"light-dots-anim"}
                        poster={poster}
                        autoPlay preload muted loop playsInline>
                        <source
                            src={src}
                            type="video/mp4"
                        />
                    </video>
                </div>
            );
        } else {
            return (
                <div id={"anim-wrapper"}>
                    <img id={"light-dots-anim"}
                         src={process.env.PUBLIC_URL + '/assets/video/DetailsLights.jpg'}
                    />
                </div>
            );
        }
    }

    renderErrorMessage() {
        if (this.state.errorMessage !== null) {
            return <FeedbackMessage
                success={false}
                message={this.state.errorMessage}
            />;
        }
    }

    renderCopyToClipboard() {
        let inAnyMode = this.state.addExtensionMode
            || this.state.changeOwnershipMode
            || this.state.createBranchMode
            || this.state.setPrivacyMode
            || this.state.reportDisputeMode
            || this.state.reportLossMode;

        if (!inAnyMode) {
            let check = <AiOutlineCheck id={"check-icon"}/>;

            if (navigator.clipboard && navigator.clipboard.writeText) {
                return (
                    <div id={"copy-to-clipboard"} onClick={() => this.onCopyLinkToClipboard()}>
                        <RiFileCopyLine id={"copy-icon"}/>
                        <p>Copy URL</p>
                        {this.state.copiedToClipboard ? check : null}
                    </div>
                );
            }
        }
    }

    renderClaimBadge() {
        if (this.state.pendingRecordData.isClaimable) {
            return (
                <div id={"claim-badge-container"}>
                    <p id={"text"}>
                        If you are the owner of this product, you can claim this Ventrace Record and get insights
                        into the condition of this product.
                    </p>
                    <UserButton
                        id={"claim-button"}
                        value={"Claim Ventrace Record"}
                        onClick={() => this.onClaimRecordButtonClick()}
                    />
                </div>
            );
        }
    }

    renderBranchLink() {
        if (this.props.walletReducer.connectedAddress
            && this.props.walletReducer.connectedAddress !== this.getCurrentOwnerAddress()
            && !this.state.addExtensionMode
            && !this.state.changeOwnershipMode
            && !this.state.createBranchMode
            && !this.state.setPrivacyMode
            && !this.state.reportDisputeMode
            && !this.state.reportLossMode
            && this.state.rootRecord[3] !== "true"
            && this.state.rootRecord[7] === "false"
            && this.state.rootRecord[10] === "false"
            && this.state.rootRecord[15] === "false") {

            return (
                <div id={"branch-link"}>
                    <p>Do you own this Record's product but you can't get in touch with this record's owner?
                        <br/><span id={"start-branch-link"} onClick={() => this.onCreateBranch()}>Then click here</span>
                    </p>
                </div>
            );
        }
    }

    renderPrivacyNotice() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        DataReporter.trackMixpanel(this.props, "Rendering privacy notice",
            { category: "Display" }
        );

        return (
            <div id={"privacy-notice" + mobileSuffix}>
                <h1>Privacy notice</h1>
                <p id={"descr"}>
                    The current owner set this Record to private mode. This Record will be
                    publicly available after the determined privacy period ends or when the owner reactivates
                    public mode manually.
                </p>
            </div>
        );
    }

    renderInvalidatedNOtice() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        DataReporter.trackMixpanel(this.props, "Rendering invalidated notice", {
            category: "Display",
        });

        return (
            <div id={"privacy-notice" + mobileSuffix}>
                <h1>Invalidation notice</h1>
                <p id={"descr"}>
                    This Record has been invalidated by the Ventrace moderation team.<br/>
                    Possible reasons could be: Fake information, spamming or Record abandonment.
                </p>
            </div>
        );
    }

    renderOwnerButtons() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        if (!this.state.addExtensionMode && !this.state.changeOwnershipMode
            && !this.state.setPrivacyMode && !this.state.createBranchMode && !this.state.reportDisputeMode
            && !this.state.reportLossMode && !this.checkIfRecordIsInvalidated() && !this.state.reportedLoss) {

            // When this is not a branched record
            if (this.props.walletReducer.connectedAddress === this.getCurrentOwnerAddress()
                && this.state.rootRecord[8] === "") {

                let privateModeButtonValue = "Set to private mode";
                let privateModeButtonIcon = <AiOutlineEyeInvisible className={"text-icon"}/>;

                if (this.state.rootRecord[3] === "true") {
                    privateModeButtonValue = "Set to public mode";
                    privateModeButtonIcon = <MdPublic className={"text-icon"}/>;
                }

                let privateButton;
                if (this.state.rootRecord[15] === "true") {
                    privateButton = (
                        <UserButton
                            className={"user-button ext-button"}
                            id={"private-mode-button"}
                            value={privateModeButtonValue}
                            icon={privateModeButtonIcon}
                            onClick={() => this.onChangePrivacyClick()}
                        />
                    );
                }

                return (
                    <div id={"extension-buttons" + mobileSuffix} className={"inline" + mobileSuffix}>
                        <UserButton
                            className={"user-button ext-button"}
                            id={"create-extension-button"}
                            value={"Extend Record"}
                            icon={<VscExtensions className={"text-icon"}/>}
                            onClick={() => this.onExtendButtonClick()}
                        />

                        <UserButton
                            className={"user-button ext-button"}
                            id={"transfer-ownership-button"}
                            value={"Transfer ownership"}
                            icon={<GiHouseKeys className={"text-icon"}/>}
                            onClick={() => this.onTransferOwnershipClick()}
                        />

                        {privateButton}

                        <UserButton
                            className={"user-button ext-button"}
                            id={"dispute-record-button"}
                            value={"Dispute Record"}
                            icon={<AiFillThunderbolt className={"text-icon"}/>}
                            onClick={() => this.onReportDisputeModeClick()}
                        />

                        <UserButton
                            className={"user-button ext-button"}
                            id={"report-loss-button"}
                            value={"Report loss"}
                            icon={<HiOutlineGlobeAlt className={"text-icon"}/>}
                            onClick={() => this.onReportLossClick()}
                        />
                    </div>
                );
            } else if (!this.props.walletReducer.noWalletMode
                && this.props.walletReducer.connectedAddress.toLowerCase() === this.getOpeningReceiverAddress()) {
                return <OwnershipChanger
                    rootRecordID={this.state.rootRecordID}
                    currentOwnerAddress={this.getCurrentOwnerAddress()}
                    showConfirmationForm={true}
                    routeParam={this.state.routeParam}
                    completeRecordData={this.state.completeRecordData}
                    recordReceipt={this.state.recordReceipt}
                    updateDetailsCallback={() => this.onCancelExtension()}
                />;
            } else if (this.props.walletReducer.connectedAddress === this.getCurrentOwnerAddress()
                && this.state.rootRecord[8] !== "" && this.state.rootRecord[11] === "false"
                && this.state.rootRecord[12] === "false") {
                return (
                    <div id={"extension-buttons" + mobileSuffix} className={"inline" + mobileSuffix}>
                        <UserButton
                            className={"user-button ext-button"}
                            id={"create-extension-button"}
                            value={"Extend record"}
                            icon={<VscExtensions className={"text-icon"}/>}
                            onClick={() => this.onExtendButtonClick()}
                        />
                    </div>
                );
            }
        } else if (!this.state.reportLossMode && this.state.reportedLoss
            && this.props.walletReducer.connectedAddress === this.getCurrentOwnerAddress()) {
            return (
                <div id={"extension-buttons" + mobileSuffix} className={"inline" + mobileSuffix}>
                    <UserButton
                        className={"user-button ext-button"}
                        id={"report-loss-button"}
                        value={"Update lost product"}
                        icon={<HiOutlineGlobeAlt className={"text-icon"}/>}
                        onClick={() => this.onReportLossClick()}
                    />
                </div>
            );
        } else if (this.state.lossNoticeChecked
            && this.props.walletReducer.connectedAddress === this.getCurrentOwnerAddress()) {
            return (
                <div id={"extension-buttons" + mobileSuffix} className={"inline"}>
                    <UserButton
                        className={"user-button ext-button"}
                        id={"cancel-extension-button"}
                        value={"Back to Record"}
                        icon={<BsFillBackspaceFill className={"text-icon"}/>}
                        onClick={() => this.onCancelExtension()}
                    />
                </div>
            )
        }
    }

    renderOwnerPendingInfo() {
        if (this.props.walletReducer.connectedAddress === this.state.pendingRecordData.rootRecordData.originator) {
            if (this.state.pendingRecordData.routeParam) {
                return (
                    <div id={"pending-live-container"}>
                        <p id={"pending-live-text"}>
                            <AiFillInfoCircle id={"icon"}/>
                            See the live version of this Record&nbsp;
                            <span id={"link"}
                                  onClick={() => this.onClickLiveURL(this.state.pendingRecordData.routeParam)}>
                                here
                            </span>
                        </p>
                    </div>
                );
            } else {
                return (
                    <div id={"pending-info-container"}>
                        <p id={"pending-info-text"}>
                            <AiOutlineFieldTime id={"icon"}/>
                            This Record was recently created and is still being processed.
                        </p>
                    </div>
                );
            }
        }
    }

    renderRecordTimeDivider(currentExtIndex) {
        if (currentExtIndex > 0) {
            let prevItem = this.state.completeRecordData[currentExtIndex - 1].data;
            let curr = this.state.completeRecordData[currentExtIndex].data;

            let prevDate = moment(parseInt(this.getRelevantTimeStamp(prevItem)));
            let currDate = moment(parseInt(this.getRelevantTimeStamp(curr)));

            let duration = moment.duration(currDate.diff(prevDate));

            let years = Math.floor(duration.asYears());
            let months = Math.floor(duration.asMonths()) - years * 12;
            let days = Math.floor(duration.asDays()) - months * 30 - years * 365;

            let infoSince = "";
            if (years === 1) infoSince += `${years} year `;
            if (years > 1) infoSince += `${years} years `;

            if (months === 1) infoSince += `${months} month `;
            if (months > 1) infoSince += `${months} months `;

            if (days === 1) infoSince += `${days} day `;
            if (days > 1) infoSince += `${days} days `;
            infoSince += "later";

            if (years === 0 && months === 0 && days === 0) {
                infoSince = "On the same day";
            }

            return (
                <div className={"time-divider-container"}>
                    <div className={"time-divider"}>
                        <img className="divider-top" src={process.env.PUBLIC_URL + '/assets/TimeDivider.png'}
                             alt={"divider"}/>
                        <p className={"info-since"}>{infoSince}</p>
                        <img className="divider-bottom" src={process.env.PUBLIC_URL + '/assets/TimeDivider.png'}
                             alt={"divider"}/>
                    </div>
                </div>
            );
        }
    }

    renderTimeBetweenCheckbox() {
        if (this.state.completeRecordData.length > 1) {
            return (
                <CheckboxInput
                    id={"time-divider-checkbox"}
                    label={"Show time between updates"}
                    onChange={(e) => this.onShowTimeDividerChange(e)}
                />
            );
        }
    }

    renderFilterExtensionDropdown() {
        if (this.state.completeRecordData.length > 1) {
            let updateTypes = ["Show all", "Damage", "Internal", "External", "Other"];

            return (
                <DropdownInput
                    id={"update-filter-dropdown"}
                    label={"Filter updates by type"}
                    options={updateTypes}
                    onChange={(e) => this.filterUpdatesOnChange(e)}
                />
            );
        }
    }

    renderCryptoValueBox() {
        if (this.props.walletReducer.connectedAddress === this.getCurrentOwnerAddress()) {
            return (
                <RecordCryptoValueBox
                    forMobile={this.props.appReducer.mobileMode}
                    currentOwnerAddress={this.getCurrentOwnerAddress()}
                    rootRecordID={this.state.rootRecordID}
                    completeRecordData={this.state.completeRecordData}
                    singleRecordValues={this.state.singleRecordValues}
                    weeklyBonusValue={this.state.weeklyBonusValue}
                />
            );
        } else return null;
    }

    renderDisplayOptions() {
        if (this.state.completeRecordData.length > 1) {
            let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

            return (
                <div id={"display-options" + mobileSuffix}>
                    {this.renderTimeBetweenCheckbox()}
                    {this.renderFilterExtensionDropdown()}
                </div>
            );
        }
    }

    renderHeader() {
        let name;
        let firstImageCID;

        if (this.state.pendingRecordData) {
            name = this.state.pendingRecordData.rootRecordData.name;
            if (this.state.pendingRecordData.rootRecordData.imageCIDs) {
                firstImageCID = this.state.pendingRecordData.rootRecordData.imageCIDs[0];
            }
        } else {
            name = this.state.completeRecordData[0].data.name;
            firstImageCID = this.state.completeRecordData[0].data.imageCIDs[0];
        }

        let imageTag = (
            <img
                id={"product-img-preview"}
                src={appConfig.IPFS_ProjectURL + firstImageCID}
                alt={"Product image preview"}
            />
        );

        try {
            if (this.state.pendingRecordData.isClaimable !== "0") {
                imageTag = null;
            }
        } catch (err) {

        }

        return (
            <div id={"header-container"}>
                <div id={"seal-container"}>
                    <img
                        id="record-seal"
                        src={process.env.PUBLIC_URL + '/details/VS_BlackRed.png'}
                        alt={"Ventrace record seal"}
                    />
                </div>
                {imageTag}
                <div id={"product-name"}>
                    <h1>{name}</h1>
                    {this.renderCopyToClipboard()}
                </div>
            </div>
        );
    }

    renderRecordHistory() {
        let recordHistoryItems = [];
        let addedExtIDs = [];

        if (this.state.pendingRecordData) {
            recordHistoryItems.push(<RootRecord
                rootRecord={null}
                rootRecordID={null}
                pendingRecordData={this.state.pendingRecordData}
                data={null}
                reloadCallback={() => this.onCancelExtension()}
                currentOwner={this.getCurrentOwnerAddress()}
                disputeDecisions={null}
                parentRef={this.rootRecordRef}
            />);

            // Hint: This rendering is only for pending RRs
            recordHistoryItems.push(
                <PendingExtensions
                    onClaimExtension={(ext) => this.onClaimExtension(ext)}
                    ownerIsConnected={this.props.walletReducer.connectedAddress === this.getCurrentOwnerAddress()}
                    pendingExtensions={this.state.pendingExtensions}
                />
            );

            return (
                <div id={"record-history-container"}>
                    {this.renderClaimBadge()}

                    <div className={"summary-boxes"}>
                        <QuickSummaryBox
                            forMobile={this.props.appReducer.mobileMode}
                            completeRecordData={null}
                            pendingRecordData={this.state.pendingRecordData}
                            extDisputedPercentage={null}
                            onShowTimeDividerChange={false}
                            onScrollToRR={() => this.onScrollToRR()}
                        />
                    </div>

                    <div id={"history-items"}>
                        {recordHistoryItems}
                    </div>
                </div>
            );

        } else if (this.state.completeRecordData) {
            let recordHistory = this.state.completeRecordData;

            for (let i = 0; i < recordHistory.length; i += 1) {
                if (i === 0 && !this.state.updateFilter) {
                    recordHistoryItems.push(
                        <RootRecord
                        rootRecord={this.state.rootRecord}
                        rootRecordID={this.state.rootRecordID}
                        data={recordHistory}
                        reloadCallback={() => this.onCancelExtension()}
                        currentOwner={this.getCurrentOwnerAddress()}
                        disputeDecisions={this.state.disputeDecisions}
                        parentRef={this.rootRecordRef}
                    />);

                    recordHistoryItems.push(
                        <PendingExtensions
                            onClaimExtension={(ext) => this.onClaimExtension(ext)}
                            ownerIsConnected={this.props.walletReducer.connectedAddress === this.getCurrentOwnerAddress()}
                            pendingExtensions={this.state.pendingExtensions}
                        />
                    );
                } else {
                    if (this.state.showTimeBetweenRecords) {
                        recordHistoryItems.push(this.renderRecordTimeDivider(i));
                    }

                    let recordType = recordHistory[i].data.recordType;

                    if (recordType === "ownershipTransferOpening" && !this.state.updateFilter) {
                        recordHistoryItems.push(
                            <TransferOpening
                                data={recordHistory[i].data}
                                forMobile={this.props.appReducer.mobileMode}
                            />
                        );
                    } else if (recordType === "ownershipTransferConfirmation" && !this.state.updateFilter) {
                        recordHistoryItems.push(
                            <TransferConfirmation
                                data={recordHistory[i].data}
                                forMobile={this.props.appReducer.mobileMode}
                            />
                        );
                    } else if (recordType === "setPrivateMode" && !this.state.updateFilter) {
                        recordHistoryItems.push(
                            <PrivateModeExtension
                                data={recordHistory[i].data}
                                forMobile={this.props.appReducer.mobileMode}
                            />);
                    } else if (recordType === "setPublicMode" && !this.state.updateFilter) {
                        recordHistoryItems.push(
                            <PublicModeExtension
                                data={recordHistory[i].data}
                                forMobile={this.props.appReducer.mobileMode}
                            />);
                    } else if (recordType === "setPublicModeDue" && !this.state.updateFilter) {
                        recordHistoryItems.push(
                            <PublicModeDueExtension
                                data={recordHistory[i].data}
                                forMobile={this.props.appReducer.mobileMode}
                            />
                        );
                    } else if (recordType === "branchCreated" && !this.state.updateFilter) {
                        recordHistoryItems.push(
                            <BranchCreation
                                data={recordHistory[i].data}
                                forMobile={this.props.appReducer.mobileMode}
                            />
                        );
                    } else if (recordType === "branchAccepted" && !this.state.updateFilter) {
                        recordHistoryItems.push(
                            <BranchAccepted
                                data={recordHistory[i].data}
                                forMobile={this.props.appReducer.mobileMode}
                            />);
                    } else if (recordType === "branchDueAccepted" && !this.state.updateFilter) {
                        recordHistoryItems.push(
                            <BranchDueAccepted
                                data={recordHistory[i].data}
                            />
                        );
                    } else if (recordType === "branchDismissed" && !this.state.updateFilter) {
                        recordHistoryItems.push(
                            <BranchDismissed
                                data={recordHistory[i].data}
                                forMobile={this.props.appReducer.mobileMode}
                            />
                        );
                    } else if (recordType === "generalExtension") {
                        recordHistoryItems.push(
                            <ExtensionRecord
                                data={recordHistory[i].data}
                                updateFilter={this.state.updateFilter}
                                currentOwnerAddress={this.getCurrentOwnerAddress()}
                                extID={this.state.recordReceipt[i][0]}
                                disputeNotes={this.state.extensionDisputes}
                                disputeDecisions={this.state.disputeDecisions}
                                damageUpdateRef={this.damageUpdateRef}
                                modUpdateRef={this.modUpdateRef}
                                damageUpdateRefSet={this.state.damageUpdateRefSet}
                                modUpdateRefSet={this.state.modUpdateRefSet}
                                damageUpdateIsSet={(set) => this.onSetDamageUpdateRef(set)}
                                modUpdateRefIsSet={(set) => this.onSetModUpdateRef(set)}
                            />
                        );
                        addedExtIDs.push(this.state.recordReceipt[i][0]);
                    }
                }
            }
            
            this.getUpdateDisputes(addedExtIDs);
            this.getSingleRecordValues();

            return (
                <div id={"record-history-container"}>
                    <div className={"summary-boxes"}>
                        <QuickSummaryBox
                            forMobile={this.props.appReducer.mobileMode}
                            completeRecordData={recordHistory}
                            extDisputedPercentage={this.state.extDisputedPercentage}
                            onShowTimeDividerChange={(e) => this.onShowTimeDividerChange(e)}
                            onScrolltoFirstDamage={() => this.onScrollToFirstDamage()}
                            onScrollToFirstMod={() => this.onScrollToFirstMod()}
                            onScrollToRR={() => this.onScrollToRR()}
                        />
                        {this.renderCryptoValueBox()}
                    </div>

                    {this.renderDisplayOptions()}

                    <div id={"history-items"}>
                        {recordHistoryItems}
                    </div>
                </div>
            );
        }
    }

    renderContentSpinner() {
        let viewBox = "0 0 100 50";
        let width = 100;

        let y1 = 24.5;
        let y2 = 31;
        let height1 = 5;
        let height2 = 6;

        let mobileSuffix = "";
        if (this.props.appReducer.mobileMode) {
            mobileSuffix = "-mobile";
            viewBox = "0 0 110 40";
            width = 108;
            y1 = 5;
            y2 = 19;
            height1 = 10;
            height2 = 11;
        }

        return (
            <div id={"content-spinner" + mobileSuffix}>
                <ContentLoader viewBox={viewBox}
                               backgroundColor={"#dddbdb"}
                               foregroundColor={"#e87373"}
                               speed={1.5}
                >
                    <rect x={0} y={y1} rx={1} ry={1} width={width} height={height1}/>
                    <rect x={0} y={y2} rx={1} ry={1} width={width} height={height2}/>
                </ContentLoader>
            </div>
        );
    }

    renderContent() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        if (!this.state.gettingRecords && (!this.state.completeRecordData || !this.state.pendingRecordData)) {
            setTimeout(() => {
                this.setState({gettingRecords: true}, () => {
                    this.getCompleteRecord();
                });
            }, 1000);

            return (
                <div>
                    {this.renderContentSpinner()}
                </div>
            );
        } else if (this.state.gettingRecords
            && (!this.state.completeRecordData && !this.state.pendingRecordData)
            && !this.state.errorMessage) {

            return (<div> {this.renderContentSpinner()} </div>);

        } else if (this.state.pendingRecordData !== null) { // If pendingRR
            document.title = `Ventrace - ${this.state.pendingRecordData.rootRecordData.name}`;

            return (
                <div>
                    {this.renderLightDotsAnim()}
                    {this.renderHeader()}

                    <div style={{display: "block"}}/>

                    {this.renderOwnerPendingInfo()}
                    {this.renderRecordHistory()}

                </div>
            );
        } else if (this.state.completeRecordData !== null) {
            let content;

            if (this.state.rootRecord[3] === "true"
                && this.props.walletReducer.connectedAddress !== this.getCurrentOwnerAddress()) {
                content = this.renderPrivacyNotice();

                if (this.getRemainingPrivateMonths() === 0) {
                    content = <DuePublicSetter
                        rootRecord={this.state.rootRecord}
                        reloadRecord={() => this.onCancelExtension()}
                    />;
                }
            } else if (this.state.addExtensionMode) {
                content = <ExtensionCreator
                    recordRouteParam={this.state.routeParam}
                    rootRecordID={this.state.rootRecordID}
                    completeRecordData={this.state.completeRecordData}
                    lastUpdateTimestamp={this.getLastUpdateTimestamp()}
                />;
            } else if (this.state.changeOwnershipMode) {
                content = <OwnershipChanger
                    rootRecordID={this.state.rootRecordID}
                    routeParam={this.state.routeParam}
                    completeRecordData={this.state.completeRecordData}
                />;
            } else if (this.state.setPrivacyMode) {
                content = <RecordPrivacySwitcher
                    rootRecord={this.state.rootRecord}
                    completeRecord={this.state.completeRecordData}
                    reloadRecord={() => this.onCancelExtension()}
                    remainingPrivateMonths={this.getRemainingPrivateMonths()}
                />;
            } else if (this.state.createBranchMode) {
                content = <RecordBranchCreator
                    rootRecord={this.state.rootRecord}
                    rootRecordData={this.state.completeRecordData[0]}
                />;
            } else if (this.state.reportDisputeMode) {
                content = <DisputeReporter
                    recordRouteParam={this.state.routeParam}
                    rootRecord={this.state.rootRecord}
                    completeRecord={this.state.completeRecordData}
                    recordReceipt={this.state.recordReceipt}
                    disputes={this.state.extensionDisputes}
                />;
            } else if (this.state.reportLossMode) {
                content = <LossNoticeReporter
                    recordRouteParam={this.state.routeParam}
                    rootRecord={this.state.rootRecord}
                    SNR={this.state.completeRecordData[0].data.serialNr}
                    reportedLoss={this.state.reportedLoss}
                />;
            } else if (this.checkIfRecordIsInvalidated()) {
                content = this.renderInvalidatedNOtice();
            } else {
                content = this.renderRecordHistory();
            }

            let privacyInfo = null;
            if (this.state.rootRecord[3] === "true"
                && this.props.walletReducer.connectedAddress === this.getCurrentOwnerAddress()) {
                privacyInfo = <p id={"privacy-info-owner" + mobileSuffix}>
                    This Record is in private mode
                </p>;
            }

            document.title = `Ventrace - ${this.state.completeRecordData[0].data.name}`;

            return (
                <div>
                    {this.renderLightDotsAnim()}
                    {this.renderHeader()}

                    <div style={{display: "block"}}/>

                    {this.renderOwnerButtons()}

                    {privacyInfo}

                    {content}

                    {this.renderBranchLink()}

                </div>
            );
        }
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        if (this.props.blockchainReducer.web3 === null) {
            return <UIConfigLoader/>
        } else {
            return (
                <div>
                    <Navigation/>
                    <div className={"content-container" + mobileSuffix}
                         id={"content-container" + mobileSuffix}>
                        <div id={"error-container"}>
                            {this.renderErrorMessage()}
                        </div>
                        {this.renderContent()}
                    </div>
                    <Footer
                        forMobile={this.props.appReducer.mobileMode}
                    />
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        walletReducer: state.wallet,
        blockchainReducer: state.blockchain,
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchBlockchainLoading: () => {
            dispatch(requestSetBlockchainLoading())
        },
        dispatchBlockchainLoadingStop: () => {
            dispatch(requestSetBlockchainLoadingStop())
        },
        dispatchSetClaimingRecord: (claimingRecord) => {
            dispatch(requestSetClaimingRecord(claimingRecord));
        },
        dispatchSetClaimingExtension: (claimingExt) => {
            dispatch(requestSetClaimingExtension(claimingExt));
        },
        dispatchSetPage: (pageName) => {
            dispatch(requestSetCurrentPage(pageName));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordDetails);