import React from "react";
import {connect} from "react-redux";
import UserButton from "../components/inputs/UserButton";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import {DataReporter} from "../DataReporter";
import {AiFillPhone} from "react-icons/ai";
import "../styles/pages/about.scss";
import {requestSetCurrentPage} from "../store/actions/app";

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    // Events
    onGoToBlockClick() {
        DataReporter.trackMixpanel(this.props,"Visit blog", {category: "Interaction"});
        window.open("https://ventrace.blog", "_blank");
    }

    onOpenIGLink(profile) {
        DataReporter.trackMixpanel(this.props, "Open IG", {category: "Interaction", profile: profile});
        window.open("https://www.instagram.com/" + profile, "_blank");
    }

    componentDidMount() {
        this.props.dispatchSetPage("about");
        DataReporter.trackMixpanel(this.props, "Page view: About", {
            pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop"
        });
    }


    // Renderers
    renderTeam() {
        return (
            <div id={"team-container"}>
                <h2>Team</h2>

                <div id={"portraits"}>
                    <img className={"portrait"}
                         src={process.env.PUBLIC_URL + '/about/Onur.jpg'}
                    />
                    <p className={"person-descr"}>
                        <span className={"name"}>Onur Karaman</span>
                        Founder, original developer and current operator of Ventrace.
                    </p>
                </div>

            </div>
        );
    }

    renderVision() {
        return (
            <div id={"why-container"}>
                <h2>The Vision: A global platform for a new era of ownership</h2>
                <p className={"p-content"}>
                    Ventrace introduces a new era of digital ownership, allowing individuals to possess their
                    products digitally through Ventrace Records. <br /> <br />
                    These Records provide owners with insightful updates on product health, repair reports, and
                    a global recovery system for lost items. Demonstrating genuine
                    ownership through these records ensures products find a second life in secondary markets,
                    contributing to a more eco-friendly future.
                </p>
            </div>
        );
    }

    renderBlog() {
        return (
            <div id={"blog-container"}>
                <h2 id={"blog-h2"}>Insights based on experience</h2>
                <div>
                    <p id={"p1"} className={"p-content"}>
                        The Ventrace blog is regularly publishing valuable content about Ventrace itself and all
                        relevant industries around the platform. Have a look and learn more about the industry.
                    </p>

                    <p id={"p2"} className={"p-content"}>
                        The Ventrace blog is hosted externally on WordPress.
                    </p>

                    <UserButton
                        forMobile={this.props.appReducer.mobileMode}
                        id={"go-to-button"}
                        value={"Visit Blog"}
                        onClick={() => this.onGoToBlockClick()}
                    />
                </div>
            </div>
        );
    }

    renderContactDetalis() {
        let phoneNr = this.props.appReducer.supportPhoneNrs.USA;
        if (this.props.appReducer.userRegionInEurope) {
            phoneNr = this.props.appReducer.supportPhoneNrs.Europe;
        }

        let addr = this.props.appReducer.officeAddress;

        return (
            <div id={"contact-container"}>
                <p id={"h"}>Contact information</p>

                <p className={"contact-method"}>
                    Office phone number
                </p>
                <p id={"phone-nr"}>
                    <AiFillPhone id={"phone-icon"}/>
                    {phoneNr}
                </p>
                <p id={"phone-info"}>
                    This is a free service phone number. However, your phone service provider may generally charge
                    you for outgoing calls.
                </p>

                <p className={"contact-method"}>
                    Office address
                </p>
                <p id={"address"}>
                    {addr.name}<br/>
                    {addr.street}<br/>
                    {addr.postcode} {addr.city}<br />
                    {addr.country}
                </p>

            </div>
        );
    }

    renderSocialLinks() {
        let igPath = process.env.PUBLIC_URL + "/Instagram_icon.png";

        return (
            <div id={"social-container"}>
                <h2>On Social Media</h2>

                <div id={"ig-links"}>

                    <div className={"ig-link"} onClick={() => this.onOpenIGLink("ventrace.official")}>
                        <img className={"logo"} src={igPath} alt={"ig-icon"}/>
                        <div className={"text"}>
                            <p className={"profile"}>ventrace.official</p>
                            <p className={"descr"}>
                                General news about Ventrace, platform updates and growth.
                            </p>
                        </div>
                    </div>

                    <div className={"ig-link"} onClick={() => this.onOpenIGLink("ventrace.watches")}>
                        <img className={"logo"} src={igPath} alt={"ig-icon"}/>
                        <div className={"text"}>
                            <p className={"profile"}>ventrace.watches</p>
                            <p className={"descr"}>
                                News, tips and guides about the world of watches and how they can be combined
                                effectively with Ventrace.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        );
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        return (
            <div>
                <Navigation/>
                <div className={"content-container" + mobileSuffix}>
                    <h1 id={"h1-about"}>About Ventrace</h1>
                    <div className={"inline"}>
                        {this.renderTeam()}
                        {this.renderContactDetalis()}
                    </div>

                    {this.renderVision()}
                    {this.renderBlog()}
                    {this.renderSocialLinks()}
                </div>
                <Footer
                    forMobile={this.props.appReducer.mobileMode}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchSetPage: (pageName) => {
            dispatch(requestSetCurrentPage(pageName));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(About);