import React from "react";
import moment from 'moment';
import {connect} from "react-redux";
import appConfig from "../../appConfig";
import InfoToolTip from "../InfoToolTip";
import {BsPerson} from "react-icons/bs";
import {RiCoinLine} from "react-icons/ri";
import {requestSetBlockchainLoading, requestSetBlockchainLoadingStop} from "../../store/actions/blockchain";
import "../../styles/components/details_ownershipvaluebox.scss"
import {DataReporter} from "../../DataReporter";

class RecordCryptoValueBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            singleRecordValues: [],
            gettingSingleRecordValues: false
        }
    }

    calcCurrentTRV() {
        if (this.props.singleRecordValues.length > 0 && this.props.weeklyBonusValue !== null) {
            let val = 0;

            for (let i = 0; i < this.props.singleRecordValues.length; i += 1) {
                let srv = this.props.singleRecordValues[i];
                if (srv[1] === this.props.currentOwnerAddress.toLowerCase()) val += parseInt(srv[2]);
            }

            // Find out since when the record belongs to the current owner and get # of weeks
            let startDate = null;
            let branchDate = null;
            let branchAccepted = false;

            for (let i = 0; i < this.props.completeRecordData.length; i += 1) {
                let r = this.props.completeRecordData[i];

                if (r.data.recordType === "rootRecord" || r.data.recordType === "ownershipTransferConfirmation"
                    || r.data.recordType === "branchCreated") {
                    startDate = r.data.recordCreationDate;
                } else if (r.data.recordType === "branchCreated") {
                    branchDate = r.data.recordCreationDate;
                    branchAccepted = false;
                } else if (r.data.recordType === "branchAccepted") {
                    branchDate = r.data.recordCreationDate;
                    branchAccepted = true;
                }
            }

            if (branchDate !== null && branchAccepted) startDate = branchDate;
            let _startDate = moment(parseInt(startDate));
            let weeksPassed = moment().diff(_startDate, "weeks");

            val+= weeksPassed * parseInt(this.props.weeklyBonusValue);

            return val / 10;
        }
        return -1;
    }

    calcTotalTRV() {
        if (this.props.singleRecordValues.length > 0) {
            let val = 0;
            for (let i = 0; i < this.props.singleRecordValues.length; i += 1) {
                val += parseInt(this.props.singleRecordValues[i][2]);
            }

            let startDate = this.props.completeRecordData[0].data.recordCreationDate;
            let _startDate = moment(parseInt(startDate));
            let weeksPassed = moment().diff(_startDate, "weeks");
            val+= weeksPassed * parseInt(this.props.weeklyBonusValue);

            return val / 10;
        }
        return 0;
    }

    clickOwnerAddress(val) {

        DataReporter.trackMixpanel(this.props, "Value box: Open owner address", {
            category: "Interaction",
        });

        let baseUrl = appConfig.currentConfig.explorerURLs.address;
        window.open(baseUrl + val, "_blank");
    }

    // Renderers
    renderCurrentOwner() {
        let ownerAddress = this.props.currentOwnerAddress;

        let sliceLen = 7;
        ownerAddress = ownerAddress.slice(0,sliceLen) + "-" + ownerAddress.slice(-sliceLen);

        return (
            <div id={"current-owner-container"}>
                <div id={"address-container"}>
                    <BsPerson className={"icon"}/>
                    <a id={"address"}
                       onClick={() => this.clickOwnerAddress(this.props.currentOwnerAddress)}>{ownerAddress}
                    </a>
                </div>
                <p className={"label"}>Current owner of Record</p>
            </div>
        );
    }

    renderCurrentValueContainer() {
        let currentValue = this.calcCurrentTRV();
        let currentValueP = <p>Calculating...</p>;

        if (currentValue > -1) {
            currentValueP = (
                <p>{currentValue} <span className={"vtr-hl"}> VTR</span></p>
            );
        }

        return (
            <div id={"current-value-container"}>

                <div className={"value-container"}>
                    <div className={"value inline"}>
                        <RiCoinLine className={"icon"}/>
                        {currentValueP}
                    </div>
                    <p className={"label"}>Current Record value</p>
                    <InfoToolTip
                        text={"The value generated by the current owner."}
                    />
                </div>
            </div>
        );
    }

    renderTotalValueContainer() {
        let totalValue = this.calcTotalTRV();
        let totalValueP = <p>Calculating...</p>;

        if (totalValue > -1) {
            totalValueP = (
                <p>{totalValue} <span className={"vtr-hl"}> VTR</span></p>
            );
        }

        return (
            <div id={"total-value-container"}>

                <div className={"value-container"}>
                    <div className={"value inline"}>
                        <RiCoinLine className={"icon"}/>
                        {totalValueP}
                    </div>
                    <p className={"label"}>Total Record value</p>
                    <InfoToolTip
                        text={"The total crypto value of this record based on all added updates."}
                    />
                </div>

            </div>
        );
    }

    render() {
        let mobileSuffix = this.props.forMobile ? "-mobile" : "";

        return (
            <div id={"ownership-value-box" + mobileSuffix}>
                <div id={"name-container"}>
                    <p id={"name"}>Digital value of Record</p>
                    <InfoToolTip
                        text={
                        "The crypto value of this record by itself. "
                        + "It grows when updates are added. See more info at the help page."}
                    />
                </div>

                <div id={"stats"}>
                    {this.renderCurrentValueContainer()}
                    {this.renderCurrentOwner()}
                    {this.renderTotalValueContainer()}
                </div>
            </div>
        );
    }
}

const
    mapStateToProps = (state) => {
        return {
            walletReducer: state.wallet,
            blockchainReducer: state.blockchain
        }
    }

const
    mapDispatchToProps = dispatch => {
        return {
            dispatchBlockchainLoading: () => {
                dispatch(requestSetBlockchainLoading())
            },
            dispatchBlockchainLoadingStop: () => {
                dispatch(requestSetBlockchainLoadingStop())
            }
        }
    }


export default connect(mapStateToProps, mapDispatchToProps)(RecordCryptoValueBox);
