import React from "react";
import {connect} from "react-redux";
import appConfig from "../appConfig";
import axios from "axios";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import TextInput from "../components/inputs/TextInput";
import {DataReporter} from "../DataReporter";
import ContentLoader from "react-content-loader";
import FeedbackMessage from "../components/FeedbackMessage";
import {BsCalendarWeek} from "react-icons/bs";
import {DateConverter} from "../DateConverter";
import {VscExtensions} from "react-icons/vsc";
import {AiFillInfoCircle, AiOutlineSearch} from "react-icons/ai";
import CTANoSearchResult from "../components/CTANoSearchResult";
import {RiRadarLine} from "react-icons/ri";
import {requestSetBlockchainLoading, requestSetBlockchainLoadingStop} from "../store/actions/blockchain";
import {requestSetClaimingRecord, requestSetCurrentPage} from "../store/actions/app";
import UIConfigLoader from "../components/UIConfigLoader";
import "../styles/pages/search.scss";
import * as Sentry from "@sentry/react";

class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputSerialNr: null,
            searchRes: [],
            bigSearchViewed: false,
            errorMessage: null,
            searchedOnChain: false,
            searchedOnDB: false,
            searching: false,
            inputIsValid: true,
            inputValidMsg: null,
        }
        this.searchFormRef = React.createRef();
    }

    getProductWord(capitalize) {
        let productWord = "product";
        if (this.props.appReducer.activePersona === appConfig.personas.watches) {
            productWord = "watch";
        }
        if (capitalize) {
            return productWord.charAt(0).toUpperCase() + productWord.slice(1);
        } else {
            return productWord;
        }
    }

    searchInputIsValid() {
        let canSubmit = true;
        this.setState({
            inputIsValid: true,
            inputValidMsg: null,
        });

        if (this.state.inputSerialNr === null || this.state.inputSerialNr.length < this.state.minInputLength) {
            this.setState({
                inputIsValid: false,
                inputValidMsg: "Your input is too short. Please enter a full serial number."
            });
            canSubmit = false;
        }
        return canSubmit;
    }

    checkForLossNotices() {
        try {
            axios
                .post(appConfig.currentConfig.backendApp.url + "/lossnotice/check",
                    {SNR: this.state.inputSerialNr.trimStart().trimEnd()},
                    {
                        headers: {
                            Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.lossNoticeQuery}`,
                            'Content-Type': 'application/json',
                        },
                    })
                .then(res => {
                    if (!res.data.success && !appConfig.onDev) {
                        Sentry.captureEvent("Couldn't check for loss notices");
                    }
                })
                .catch(err => {
                    DataReporter.trackSentry(err, {extra: {additionalData: "checkForLossNotices 1"}});
                })
        } catch (err) {
            DataReporter.trackSentry(err, {extra: {additionalData: "checkForLossNotices 2"}});
        }
    }

    async searchOnBlockchain() {
        if (this.searchInputIsValid()) {
            this.props.dispatchBlockchainLoading();

            let recordManagerContract = this.props.blockchainReducer.recordManagerContract;

            recordManagerContract.methods.getRecordsByHashedUID(this.state.inputSerialNr.trimStart().trimEnd())
                .call({
                    from: this.props.walletReducer.connectedAddress, gas: appConfig.currentConfig.blockchainGasLimit
                })
                .then((receipt) => {
                    let searchRes = [];
                    let IPFSPromises = [];

                    for (let i = 0; i < receipt.length; i += 1) {
                        IPFSPromises.push(axios.get(appConfig.IPFS_ProjectURL + receipt[i][3]))
                    }

                    Promise.all(IPFSPromises)
                        .then((allRes => {
                            for (let i = 0; i < allRes.length; i += 1) {
                                searchRes.push({
                                    "id": receipt[i][0],
                                    "extensionCount": receipt[i][1],
                                    "routeParam": receipt[i][2],
                                    "IPFS_CID": receipt[i][3],
                                    "IPFS_Data": allRes[i].data,
                                    "fromProvider": false
                                });
                            }

                            this.setState({
                                errorMessage: null,
                                searchRes: searchRes.reverse(),
                                bigSearchViewed: true,
                                searchedOnChain: true,
                            }, () => {
                                this.props.dispatchBlockchainLoadingStop();
                            });

                        }))
                        .catch((err) => {
                            this.setState({
                                errorMessage: "Error during search (1). Please try again later."
                            }, () => {
                                this.props.dispatchBlockchainLoadingStop();

                                DataReporter.trackSentry(err, {
                                    extra: {additionalData: "Search: Getting IPFS data via promises."}
                                });
                            });
                        })
                })
                .catch((err) => {
                    this.setState({
                        errorMessage: "Unable to search when your crypto wallet is on the wrong network." +
                            " Please use the quick fix in the support page"
                    }, () => {
                        this.props.dispatchBlockchainLoadingStop();

                        DataReporter.trackMixpanel(this.props, "Search: No connection to Blockchain",
                            {category: "Error"});

                        DataReporter.trackSentry(err, {
                            extra: {additionalData: "Executing contract getRecordsByHashedUID."}
                        });

                    });
                })
        }
    }

    async searchOnDB() {
        try {
            axios
                .get(appConfig.currentConfig.backendApp.url + "/pendingRR/all",
                    {
                        headers: {
                            Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                            'Content-Type': 'application/json',
                        },
                        params: {
                            SNR: this.state.inputSerialNr.trimStart().trimEnd()
                        }
                    }
                )
                .then(res => {
                    if (res.status === 200) {
                        let searchRes = this.state.searchRes;

                        for (let i = 0; i < res.data.length; i += 1) {
                            let curr = res.data[i];
                            if (curr.isClaimable) {
                                searchRes.push({
                                    "id": curr.id,
                                    "extensionCount": 0,
                                    "routeParam": curr.pendingRouteParam,
                                    "IPFS_CID": null,
                                    "IPFS_Data": curr.rootRecordData,
                                    "fromProvider": true,
                                    "pendingRouteParam": curr.pendingRouteParam
                                });
                            }
                        }

                        this.setState({
                            errorMessage: null,
                            searchRes: searchRes.reverse(),
                            bigSearchViewed: true,
                            searchedOnDB: true,
                        });
                    }
                })
                .catch(err => {
                    this.setState({
                        searchedOnDB: true
                    });
                })
        } catch (err) {
            DataReporter.trackMixpanel(this.props, "Error searching on DB", {category: "Error"});
            DataReporter.trackSentry(err);
            this.setState({
                searchedOnDB: true
            });
        }
    }

    // Events
    componentDidMount() {
        this.props.dispatchSetPage("search");

        DataReporter.trackMixpanel(this.props, "Page view: Search",
            {
                pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop",
            });
    }

    onClickSearch() {
        let targetYPosition = this.searchFormRef.current.getBoundingClientRect().top;

        window.scrollTo({
            top: window.scrollY + targetYPosition - 120,
            behavior: 'smooth',
        });
    }

    onQueryInputChange(e) {
        if (e.enterPressed && this.state.inputSerialNr) {
            this.props.dispatchSetClaimingRecord(null);
            this.setState({
                searchedOnChain: false,
                searchedOnDB: false,
                searching: true
            }, () => {
                DataReporter.trackMixpanel(this.props, "Searching for VR",
                    {
                        pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop",
                    });

                this.searchOnBlockchain();
                setTimeout(() => this.searchOnDB(), 1000);
                setTimeout(() => this.checkForLossNotices(), 3000);
                setTimeout(() => this.setState({searching: false}), 2000);
            })
        } else {
            this.setState({inputSerialNr: e.inputValue});
        }
    }

    onInputReset() {
        this.setState({searchRes: []}, () => {
            DataReporter.trackMixpanel(this.props,
                "Search Reset", {category: "Display"});
        });
    }

    // Renderers
    renderErrorMessage() {
        if (this.state.errorMessage !== null) {
            return <FeedbackMessage
                centered={true}
                success={false}
                message={this.state.errorMessage}
            />;
        }
    }

    renderContentSpinner() {
        if (this.state.searching) {
            let height = this.props.appReducer.mobileMode ? 12 : 5;
            return (
                <div id={"content-spinner"}>
                    <ContentLoader viewBox={"0 0 100 25"}
                                   backgroundColor={"#eeeeee"}
                                   foregroundColor={"#dadada"}
                                   speed={1.5}
                    >

                        <rect x={0} y={0} rx={1} ry={1} width={100} height={height}/>
                    </ContentLoader>
                </div>
            );
        }
    }

    renderNoSearchResultsInfo() {
        let meaning;
        if (this.props.appReducer.mobileMode) {
            meaning = (
                <p id={"meaning"}>
                    The digital certificate for the {this.getProductWord()} you're looking for hasn't been activated yet.
                </p>
            );
        } else {
            meaning = (
                <p id={"meaning"}>
                    The digital certificate for the {this.getProductWord()} you're looking for hasn't been activated yet.<br/>
                    If you're the owner of that {this.getProductWord()}, you can easily order the digital certificate
                    ("Ventrace Record") yourself - see below.
                </p>
            );
        }

        return (
            <div id={"no-result-container"}>
                <p id={"no-res-count"}>
                    <RiRadarLine id={"no-signal"}/>
                    No Ventrace Record found
                </p>
                {meaning}
            </div>
        );
    }

    renderSearchResult() {
        let resCountP;
        let resItems = [];
        let ctaCreateRecord = null;

        if (this.state.searchRes.length > 0) {
            let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

            for (let i = 0; i < this.state.searchRes.length; i += 1) {
                let curr = this.state.searchRes[i];

                let originatorAddress;
                if (curr.fromProvider) {
                    originatorAddress = curr.IPFS_Data.providerName;
                } else {
                    let sliceLen = 7;
                    originatorAddress = curr.IPFS_Data.originator;
                    originatorAddress = originatorAddress.slice(0, sliceLen) + "-" + originatorAddress.slice(-sliceLen);
                }

                resItems.push(
                    <div className={"result-item" + mobileSuffix}
                         key={i}
                         onClick={() => this.onSeachResultClick(curr)}>

                        <p className={"name"}>{curr.IPFS_Data.name}</p>
                        <p className={"originator"}>
                            {curr.fromProvider ? "Provided by" : "Creator"}
                            <span className={curr.fromProvider ? "data-p" + mobileSuffix : "data" + mobileSuffix}>
                                {originatorAddress}
                            </span>
                        </p>
                        <p className={"date-added" + mobileSuffix}>
                            Record activation
                            <span className={"data" + mobileSuffix}>
                                <BsCalendarWeek className={"cal-icon"}/>
                                {DateConverter.dateObjToString(parseInt(this.state.searchRes[i].IPFS_Data.recordCreationDate))}
                            </span>
                        </p>
                        <div className={"ext-count"}>
                            <p className={"label"}>
                                Updates
                            </p>
                            <p className={"val"}>
                                <VscExtensions className={"text-icon"}/>
                                {this.state.searchRes[i].extensionCount}
                            </p>
                        </div>
                    </div>
                )
            }

            if (this.state.bigSearchViewed && this.state.searchRes.length >= 1) {
                if (this.state.searchRes.length >=2) {
                    resCountP = (
                        <div>
                            <p id={"res-count"}>
                                Found <b>{this.state.searchRes.length}</b> Records
                            </p>
                            <p id={"why-multiple-results"}>
                                <AiFillInfoCircle id={"icon"} />
                                Multiple results can happen by chance when different owners order a
                                Ventrace Record for the same product, or when various factories use the same
                                serial number for their products.
                            </p>
                        </div>
                    );
                } else {
                    resCountP = (
                        <p id={"res-count"}>
                            Found <b>{this.state.searchRes.length}</b> Record
                        </p>
                    );
                }

                DataReporter.trackMixpanel(this.props, "Showing search results",
                    {
                        category: "Display",
                        searchResCount: resItems.length,
                        query: this.state.inputSerialNr
                    });
            }

        } else if (this.state.bigSearchViewed && resItems.length === 0
            && this.state.searchedOnChain && this.state.searchedOnDB) {
            DataReporter.trackMixpanel(this.props, "Showing NoRes CTA",
                {
                    category: "Searching",
                    query: this.state.inputSerialNr ? this.state.inputSerialNr : ""
                });

            resCountP = this.renderNoSearchResultsInfo();
            ctaCreateRecord = (
                <CTANoSearchResult forMobile={this.props.appReducer.mobileMode}/>
            );
        }

        if (this.state.bigSearchViewed) {
            window.scrollTo(0, 0);

            return (
                <div id={"search-res-container"}>
                    {resCountP}
                    {resItems}
                    {ctaCreateRecord}
                </div>
            );
        }
    }

    renderSearchInfo() {
        if (!this.state.searchedOnChain && !this.state.searchedOnDB) {
            let descr;
            if (this.props.appReducer.mobileMode) {
                descr = (
                    <p id={"descr"}>
                        Look for existing Records to find out about essential {this.getProductWord()} details
                        such as production years, repair dates or international trades.
                    </p>
                );
            } else {
                descr = (
                    <p id={"descr"}>
                        Look for existing Records to find out about essential {this.getProductWord()} details
                        such as production years,<br/>repair dates or international trades.
                    </p>
                );
            }


            return (
                <div id={"search-info-container"}>
                    <div id={"header-container"}>
                        <AiOutlineSearch id={"icon"} />
                        <h2>Search for existing Ventrace Records</h2>
                    </div>
                    {descr}
                </div>
            );
        }
    }

    renderSearchForm() {
        return (
            <div id={"compact-search-form-container"} ref={this.searchFormRef}>
                <h1>Ventrace Record Search</h1>
                <div id={"compact-search-form"}>
                    <p id={"label"}>{this.getProductWord(true)} Serial Number</p>
                    <div className={"inline"}>
                        <TextInput
                            id={"serial-nr"}
                            inputID={"serial-nr-input"}
                            renderLabel={false}
                            placeholder={`${this.getProductWord(true)} Serial Number`}
                            value={this.state.inputSerialNr}
                            onChange={(e) => this.onQueryInputChange(e)}
                            width={null}
                            showSubmitButton={true}
                            onReset={() => this.onInputReset()}
                            validationError={!this.state.inputIsValid}
                            validationMsg={this.state.inputValidMsg}
                        />
                    </div>
                    {this.renderErrorMessage()}
                </div>
                {this.renderContentSpinner()}
            </div>
        );
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";
        let b = this.props.blockchainReducer;

        if (!b.web3 || !b.contractsReady) {
            return <UIConfigLoader/>
        } else {

            document.title = "Ventrace - Search";
            return (
                <div>
                    <Navigation
                        onClickSearch={() => this.onClickSearch()}
                    />

                    <div className={"search-container" + mobileSuffix}>
                        {this.renderSearchForm()}
                        {this.renderSearchInfo()}
                        {this.renderSearchResult()}
                    </div>

                    <Footer
                        forMobile={this.props.appReducer.mobileMode}
                    />
                </div>
            );
        }

    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app,
        blockchainReducer: state.blockchain,
        walletReducer: state.wallet,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchBlockchainLoading: () => {
            dispatch(requestSetBlockchainLoading());
        },
        dispatchBlockchainLoadingStop: () => {
            dispatch(requestSetBlockchainLoadingStop());
        },
        dispatchSetPage: (pageName) => {
            dispatch(requestSetCurrentPage(pageName));
        },
        dispatchSetClaimingRecord: (claimingRecord) => {
            dispatch(requestSetClaimingRecord(claimingRecord));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);