import React from "react";
import {connect} from "react-redux";
import appConfig from "../../appConfig";
import FeedbackMessage from "../FeedbackMessage";
import UserButton from "../inputs/UserButton";
import {DataReporter} from "../../DataReporter";
import "../../styles/components/headline_section.scss";

class HeadlineSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            glowSearch: false,
        }
    }

    getProductWord(capitalize) {
        let productWord = "product";
        if (this.props.appReducer.activePersona === appConfig.personas.watches) {
            productWord = "watch";
        }
        if (capitalize) {
            return productWord.charAt(0).toUpperCase() + productWord.slice(1);
        } else {
            return productWord;
        }
    }

    // Events
    onOrderButtonclick() {
        window.open("/order?t=hls", "_self");
    }

    // Renderers
    renderFlyingRecordsAnim() {
        let srcVideo = process.env.PUBLIC_URL + "/assets/video/RecordsFlying.mp4";
        let srcPoster = process.env.PUBLIC_URL + "/assets/video/RecordsFlying.jpg";

        if (this.props.forMobile) {
            srcVideo = process.env.PUBLIC_URL + "/assets/video/RecordsFlying_mobile.mp4";
            srcPoster = process.env.PUBLIC_URL + "/assets/video/RecordsFlying_mobile.jpg";
        }

        if (appConfig.playVideos) {
            return (
                <video
                    id={"flying-records-anim"}
                    poster={srcPoster}
                    autoPlay preload muted loop playsInline>
                    <source
                        src={srcVideo}
                        type="video/mp4"
                    />
                </video>
            );
        } else {
            return (
                <img id={"flying-records-anim"}
                     src={srcPoster}
                />
            );
        }
    }

    renderHeadline() {
        let w1 = "smartphone";
        let w2 = "watch";
        let w3 = "instrument";
        let w4 = "collectible";

        if (this.props.appReducer.activePersona === appConfig.personas.watches) {
            w1 = "Rolex";
            w2 = "Patek";
            w3 = "Cartier";
            w4 = "Vacheron"
        }

        return (
            <div id={"headline-container"}>
                <div id={"headline-text"}>
                    <p className={"headline"}>
                        Protect and Preserve Your
                    </p>
                    <section className="animation">
                        <div>
                            <div className={"headline"} id={"product"}>
                                {this.getProductWord(true)}
                            </div>
                        </div>
                        <div>
                            <div className={"headline"}>{w1}</div>
                        </div>
                        <div>
                            <div className={"headline"}>{w2}</div>
                        </div>
                        <div>
                            <div className={"headline"}>{w3}</div>
                        </div>
                        <div>
                            <div className={"headline"}>{w4}</div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }

    renderErrorMessage() {
        if (this.props.errorMessage !== null) {
            return <FeedbackMessage
                centered={true}
                success={false}
                message={this.props.errorMessage}
            />;
        }
    }

    renderOrderButton() {
        return (
            <div id={"order-button-container"}>
                <UserButton
                    forMobile={this.props.appReducer.mobileMode}
                    id={"order-button"}
                    value={"Get Your Digital Certificate"}
                    onClick={() => this.onOrderButtonclick()}
                />
            </div>
        );
    }

    render() {
        let mobileSuffix = this.props.forMobile ? "-mobile" : "";
        let ventraceExplainerClass = "headline-package";

        let instantBenefit = (
            <p id={"instant-benefit"}>
                Get essential details about your {this.getProductWord()} automatically, find your lost {this.getProductWord()},
                and prolong the value of your watch for decades upon decades.
            </p>
        );

        if (this.props.forMobile) {
            ventraceExplainerClass += mobileSuffix;
            instantBenefit = (
                <p id={"instant-benefit"}>
                    Get essential details about your {this.getProductWord()} automatically, find your lost {this.getProductWord()}{" "}
                    and prolong the value of your watch for decades upon decades.
                </p>
            );
        }



        return (
            <div id={ventraceExplainerClass}>
                {this.renderFlyingRecordsAnim()}
                <div id={"content"}>
                    {this.renderHeadline()}

                    <p id={"platform"}>
                        Ventrace is a platform which lets you create and manage digital certificates
                        for any {this.getProductWord()} on earth.
                    </p>

                    {instantBenefit}
                    {this.renderOrderButton()}

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        walletReducer: state.wallet,
        blockchainReducer: state.blockchain,
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(HeadlineSection);