import React from "react";
import {connect} from "react-redux";
import {IoIosCheckmarkCircle, IoIosCheckmarkCircleOutline} from "react-icons/io";
import {BiLinkExternal} from "react-icons/bi";
import { GoDotFill } from "react-icons/go";
import {AiFillPhone} from "react-icons/ai";
import {DataReporter} from "../DataReporter";
import "../styles/components/ordersuccess.scss";

class OrderSuccess extends React.Component {
    // Events
    componentDidMount() {
        DataReporter.trackMixpanel(this.props, "Page View: Order Success", {
            category: "Display"
        });
        window.scrollTo(0, 0);
    }

    onClickRecordLink() {
        window.open(`/record?prp=${this.props.prp}`, "_blank");
    }

    renderNextSteps() {
        return (
            <div id={"next-steps-container"}>
                <p id={"next-steps-h"}>What happens now?</p>

                <div id={"next-steps-items"}>
                    <div className={"inline item"} id={"ordered-item"}>
                        <IoIosCheckmarkCircle className={"full-check-icon"}/>
                        You ordered a Ventrace Record
                    </div>
                    <p id={"record-prp-link"} onClick={() => this.onClickRecordLink()}>
                        <BiLinkExternal id={"icon"}/>
                        Go to your newly created Ventrace Record
                    </p>

                    <div className={"inline item"}>
                        <IoIosCheckmarkCircleOutline className={"empty-check-icon"}/>
                        You will receive a confirmation email
                    </div>

                    <div className={"inline item"} id={"process-item"}>
                        <IoIosCheckmarkCircleOutline className={"empty-check-icon"}/>
                        Ventrace will process your order (2-3 days)
                    </div>

                    <div id={"process-steps"}>
                        <div className={"step inline"}>
                            <GoDotFill className={"icon"}/>
                            <p className={"text"}>
                                Your Ventrace Record will be securely stored on the global Ethereum database.
                            </p>
                        </div>

                        <div className={"step inline"}>
                            <GoDotFill className={"icon"}/>
                            <p className={"text"}>
                                Your wallet will receive crypto funds. These will be &#8202;
                                <span className={"vtr-hl"} id={"matic"}>Polygon (POL)</span> and the official
                                Ventrace coin &#8202;
                                <span className={"vtr-hl"}>VTR</span>. You will need these funds when you to updates
                                to your new Ventrace Record. You can learn more about that in at the Ventrace support page.
                            </p>
                        </div>

                        <div className={"step inline"}>
                            <GoDotFill className={"icon"}/>
                            <p className={"text"}>
                                You will have access to your actual, live Ventrace Record
                            </p>
                        </div>

                    </div>

                </div>
            </div>
        );
    }

    // Renderers
    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        let phoneNr = this.props.appReducer.supportPhoneNrs.USA;
        if (this.props.appReducer.userRegionInEurope) {
            phoneNr = this.props.appReducer.supportPhoneNrs.Europe;
        }

        return (
            <div id={"order-success-container" + mobileSuffix}>
                <div className={"inline"}>
                    <h1 id={"order-h"}>
                        Your order is confirmed
                    </h1>
                    <img
                        id={"sparkles"}
                        src={process.env.PUBLIC_URL + '/sparkles.gif'}
                        alt={"Sparkles"}
                    />
                </div>

                <p id={"order-descr"}>
                    Thank you for your order. You will receive the order details per email shortly.
                </p>

                <div className={"inline" + mobileSuffix}>
                    <div id={"l"}>
                        {this.renderNextSteps()}

                        <div id={"my-records"}>
                            You can also find your new Ventrace Record in <a href={"/myrecords"}>My Records</a>.
                        </div>
                    </div>

                    <div id={"r"}>
                        <div id={"support"}>
                            <p id={"support-h"}>Any questions?</p>

                            <div className={"q-item"}>
                                <p className={"h"}>Call Ventrace free of charge</p>
                                <p id={"phone-nr"}><AiFillPhone id={"phone-icon"}/>{phoneNr}</p>
                                <p id={"phone-descr"}>
                                    This is a free service phone number. However, your phone service provider may charge
                                    you for outgoing calls.
                                </p>
                            </div>


                            <div className={"q-item"}>
                                <p className={"h"}>Contact per email and more</p>
                                <a href={"/support"} id={"support-link"}>Support page</a>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app
    }
}

export default connect(mapStateToProps, null)(OrderSuccess);