import React from "react";
import {connect} from "react-redux";
import appConfig from "../appConfig";
import axios from "axios";
import Mixpanel from "mixpanel-browser";
import TextArea from "../components/inputs/TextArea";
import {DateConverter} from "../DateConverter";
import UserButton from "./inputs/UserButton";
import DatePicker from "react-datepicker";
import SidebarExplainer from "./SidebarExplainer";
import {BsCalendarCheck} from "react-icons/bs";
import HintContainer from "./HintContainer";
import TextInput from "./inputs/TextInput";
import * as Sentry from "@sentry/react";
import FeedbackMessage from "./FeedbackMessage";
import {DataReporter} from "../DataReporter";
import "../styles/components/loss_notice_reporter.scss";


class LossNoticeReporter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            minInputLength: 3,
            submitting: false,
            submissionSuccessful: false,
            lossDate: Date.now(),
            lossDetails: null,
            lossDetailsValid: true,
            emailAddress: null,
            emailValid: true,
            errorMessage: null,
            alreadyReported: null,
            alreadyReportedData: null
        }
    }

    getSideBarText() {
        return [
            {
                "name": "Reporting your product as lost",
                "descr": "By filling out and submitting the form on the left, you essentially activate the Ventrace recovery" +
                    " system for your lost product. You won't be able to add any updates while your product is marked as lost."
            },
            {
                "name": "Platform-wide checks and inspections",
                "descr": "Ventrace will inspect all of its worldwide activity for mentions of your lost product by looking for" +
                    " the serial number."
            },
            {
                "name": "Fast notification",
                "descr": "Your provided email address (see form on left) will be used to directly contact you as soon as activity is detected." +
                    " Ventrace will support you with as much information and further assistance as possible."
            },
        ];
    }

    validateForm() {
        let canSave = true;
        this.setState({
            lossDetailsValid: true,
            emailValid: true
        }, () => {
            DataReporter.trackMixpanel(this.props, "Validating Loss notice", {category: "Interaction"});
        });

        let s = this.state;

        if (s.lossDetails === null || s.lossDetails.length < s.min) {
            this.setState({
                lossDetailsValid: false
            }, () => {
                DataReporter.trackMixpanel(this.props, "Validation Error: lossDetails", {category: "Feedback"});
            });

            canSave = false;
        }

        if (s.emailAddress === null || s.emailAddress.length < s.minInputLength
            || !s.emailAddress.includes("@")) {
            this.setState({
                emailValid: false
            }, () => {
                DataReporter.trackMixpanel(this.props, "Validation Error: Email", {category: "Feedback"});
            });

            canSave = false;
        }

        return canSave;
    }

    submitReport() {
        if (!this.state.submitting && this.validateForm()) {
            this.setState({submitting: true}, async () => {
                axios
                    .post(appConfig.currentConfig.backendApp.url + "/lossnotice", {
                        lossDate: this.state.lossDate,
                        RRid: parseInt(this.props.rootRecord[0]),
                        reporterEmail: this.state.emailAddress,
                        productData: {
                            name: this.props.rootRecord[13],
                            lossDetails: this.state.lossDetails,
                            rp: this.props.recordRouteParam,
                            SNR: this.props.SNR
                        }
                    }, {
                        headers: {
                            Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                            'Content-Type': 'application/json',
                        },
                    })
                    .then(res => {
                        if (res.data.success) {
                            this.setState({
                                submissionSuccessful: true,
                                submitting: false,
                                alreadyReported: true
                            })
                        } else {
                            this.handleError({message: "Loss report not successful. Please try again."});
                        }
                    })
                    .catch(err => {
                        this.handleError(err);
                    })
            })
        }
    }

    // Events
    handleError(err) {
        let displayErr;
        if (err.message === "Network Error") {
            displayErr = `There was a connection error. Please try again.`;
        } else {
            displayErr = err.message;
        }

        this.setState({
            errorMessage: displayErr,
            submitting: false,
        }, () => {
            let reason = "Loss reporter: Backend network error"
            DataReporter.trackMixpanel(this.props, reason, {category: "Error"});
            DataReporter.trackSentry(err, {extra: {additionalData: reason}});
        });
    }

    lossDateOnChange(date) {
        this.setState({
            lossDate: DateConverter.getUTCMilliseconds(date)
        }, () => {
            DataReporter.trackMixpanel(this.props, "Changed loss date", {
                category: "Interaction"
            });
        });
    }

    detailsOnChange(e) {
        if (e.inputValue.length >= this.state.minInputLength) {
            this.setState({lossDetails: e.inputValue});
        }
    }

    detailsOnPaste(e) {
        this.descrOnChange({inputValue: e.clipboardData.getData("text")});
    }

    emailOnChange(e) {
        if (e.inputValue.length >= this.state.minInputLength) {
            this.setState({emailAddress: e.inputValue});
        }
    }

    emailOnPaste(e) {
        this.emailOnChange({inputValue: e.clipboardData.getData("text")});
    }

    // Renderers
    renderSpinner() {
        if (this.state.submitting) {
            return (
                <div id={"spinner-container"}>
                    <img id={"spinner"} src={process.env.PUBLIC_URL + '/spinner.gif'} alt={"spinner"}/>
                </div>
            );
        }
    }

    renderErrorMessage() {
        if (this.state.errorMessage) {
            return (
                <FeedbackMessage
                    success={false}
                    message={this.state.errorMessage}
                />
            );
        }
    }

    renderSuccess() {
        if (this.state.submissionSuccessful) {
            return (
                <div id={"success-container"}>
                    <FeedbackMessage
                        success={true}
                        message={"Your product loss has been reported."}
                    />
                </div>
            );
        }
    }

    renderAlreadyReported() {
        if (this.props.reportedLoss) {
            return (
                <div id={"already-reported-container"}>
                    <h1>Loss notice active</h1>
                    <p id={"descr"}>
                        The loss of your product has already been reported to Ventrace.
                        You will receive emails when following happens:
                    </p>

                    <div id={"when-bulletpoints"}>
                        <div className={"bp"}>
                            <div className={"bullet"}/>
                            <p className={"text"}>
                                When your product is being searched for on Ventrace.
                            </p>
                        </div>

                        <div className={"bp"}>
                            <div className={"bullet"}/>
                            <p className={"text"}>
                                When your product receives automatic updates. This includes new owners (when your
                                product
                                gets sold to a store), or updates on any internal or external changes or damages.
                            </p>
                        </div>
                    </div>

                    <p id={"mark-found-info"}>
                        If you found your lost product by now, you can contact <a href={"/support"} target={"_blank"}>support</a>&#8202;
                        to remove the loss notice and re-enable you to update your Ventrace Record.
                    </p>
                </div>
            );
        }
    }

    renderReportForm() {
        if (!this.state.submissionSuccessful && !this.props.reportedLoss) {
            let minDate = new Date();
            minDate.setFullYear(minDate.getFullYear() - 30);

            return (
                <did id={"report-form"}>
                    <h1>Report your lost product</h1>
                    <p id={"descr"}>
                        By reporting your lost product, Ventrace will activate its recovery system for you.
                        You will be notified when one of the following events occur:
                    </p>

                    <div id={"when-bulletpoints"}>
                        <div className={"bp"}>
                            <div className={"bullet"}/>
                            <p className={"text"}>
                                When your product is being searched for on Ventrace.
                            </p>
                        </div>

                        <div className={"bp"}>
                            <div className={"bullet"}/>
                            <p className={"text"}>
                                When your product receives automatic updates. This includes new owners (when your
                                product
                                gets sold to a store), or updates on any internal or external changes or damages.
                            </p>
                        </div>
                    </div>

                    <div id={"loss-date-input-container"}>
                        <p className={"input-label"}>
                            <BsCalendarCheck className={"label-icon"}/>
                            Estimated or known loss date
                        </p>
                        <DatePicker
                            id={"loss-date-input"}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            yearDropdownItemNumber={20}
                            dropdownMode="select"
                            dateFormat="yyyy-MM-dd"
                            selected={this.state.lossDate}
                            onChange={(date) => this.lossDateOnChange(date)}
                            minDate={minDate}
                            maxDate={new Date()}
                        />
                    </div>

                    <div id={"details-container"}>
                        <TextArea
                            inputID={"details-input"}
                            label={"Details"}
                            maxLen={500}
                            rows={2}
                            placeholder={"Details"}
                            onChange={(e) => this.detailsOnChange(e)}
                            onPaste={(e) => this.detailsOnPaste(e)}
                            validationError={!this.state.lossDetailsValid}
                            validationMsg={"Your text is too short."}
                            hints={
                                <HintContainer
                                    forMobile={this.props.appReducer.mobileMode}
                                    id={"details-hints"}
                                    hints={[
                                        "Add further details to your report",
                                        "Such as: Where you lost it, what condition it was in",
                                        "or any kind of special attribute to your product."
                                    ]}
                                />
                            }
                        />
                    </div>

                    <TextInput
                        id={"email-input-container"}
                        inputID={"email-input"}
                        label={"Your email address*"}
                        placeholder={"Your email address"}
                        onChange={(e) => this.emailOnChange(e)}
                        onPaste={(e) => this.emailOnPaste(e)}
                        validationError={!this.state.emailValid}
                        validationMsg={"This email address is invalid"}
                        maxLen={50}
                    />


                    <p id={"report-info-text"}>
                        After submitting this report, you will receive a confirmation email.
                        Also, you <b>won't be able to add updates or changes</b> to your Ventrace Record, until you
                        report your product as recovered.
                    </p>

                    <div id={"button-container"}>
                        <UserButton
                            forMobile={this.props.appReducer.mobileMode}
                            id={"report-button"}
                            value={"Report loss"}
                            disabled={this.state.submitting}
                            onClick={() => this.submitReport()}
                        />
                        {this.renderSpinner()}
                    </div>
                </did>
            );
        }
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        return (
            <div className={"loss-notice-reporter-container" + mobileSuffix}>
                <div id={"left"}>
                    {this.renderSuccess()}
                    {this.renderReportForm()}
                    {this.renderAlreadyReported()}
                    {this.renderErrorMessage()}
                </div>
                <div id={"right"}>
                    <SidebarExplainer
                        forMobile={this.props.appReducer.mobileMode}
                        id={"sidebar-explainer"}
                        h1={"How the Recovery System works"}
                        steps={this.getSideBarText()}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        walletReducer: state.wallet,
        blockchainReducer: state.blockchain,
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LossNoticeReporter);