import * as types from "../actionTypes/wallet";
import * as actionResponse from "./actionResponse";

export function requestDisconnectWallet() {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.DISCONNECT_WALLET));
    }
}

export function requestSetWalletAddress(param) {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.WALLET_SET_ADDRESS, param));
    }
}

export function requestSetNoWalletMode() {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.SET_NO_WALLET_MODE));
    }
}

export function requestSetVTRBalance(param) {
    return (dispatch) => {
        dispatch(actionResponse.gen(types.SET_VTR_BALANCE, param));
    }
}

