import React from "react";
import {connect} from "react-redux";
import appConfig from "../../appConfig";
import Logger from "js-logger";
import {DateConverter} from "../../DateConverter";
import ImagePresenter from "./ImagePresenter";
import TextArea from "../inputs/TextArea";
import UserButton from "../inputs/UserButton";
import VTRCalculator from "../VTRCalculator";
import TRXReceipt from "../TRXReceipt";
import CreatorInfo from "./CreatorInfo";
import {requestSetBlockchainLoading, requestSetBlockchainLoadingStop} from "../../store/actions/blockchain";
import {BiCommentError, BiComment, BiChip} from "react-icons/bi";
import {BsCalendarWeek, BsBoxSeam} from "react-icons/bs";
import {AiOutlineTool, AiOutlineInfoCircle, AiOutlineWarning} from "react-icons/ai";
import {DataReporter} from "../../DataReporter";
import {GoReport} from "react-icons/go";
import {create} from "ipfs-http-client";
import {EIP712types} from "../../EIP712types";
import HintContainer from "../HintContainer";
import DisputeDecision from "../DisputeDecision";
import "../../styles/components/details_extensionrecord.scss";
import axios from "axios";


class ExtensionRecord extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showImg: false,
            minInputLength: 10,
            addDisputeNoteMode: false,
            addDisputeNoteMsg: null,
            addDisputeNoteValid: true,
            submissionSuccessful: null,
            disputeNotes: null,
            showDisputeNotes: false,
            disputeRateToPay: null,
            disputeEstimatedGasUse: null,
            submittingDispute: null,
            extInvalid: false,
            showInvalidated: false,
            parentRef: null,
            provider: null
        }
    }

    getDisputeJSON() {
        return {
            "disputeCreationDate": Date.now().toString(),
            "originator": this.props.walletReducer.connectedAddress,
            "note": this.state.addDisputeNoteMsg,
            "extID": parseInt(this.props.extID)
        }
    }

    disputeIsValidToSave() {
        let canSave = true;
        this.setState({
            addDisputeNoteValid: true,
        }, () => {
            DataReporter.trackMixpanel(this.props, "Validating dispute note",
                {category: "Interaction"}
            );
        });

        if (this.state.addDisputeNoteMsg == null || this.state.addDisputeNoteMsg.length < this.state.minInputLength) {
            this.setState({
                addDisputeNoteValid: false
            }, () => {
                DataReporter.trackMixpanel(this.props, "Validation Error: Dispute note not valid",
                    {category: "Interaction"}
                );

            });
            canSave = false;
        }

        return canSave;
    }

    async submitDispute() {
        if (this.disputeIsValidToSave()) {
            this.setState({submittingDispute: true}, () => {
                this.props.dispatchBlockchainLoading();

                try {
                    // Get approval for VTR cost
                    let vtrTokenContract = this.props.blockchainReducer.vtrTokenContract;
                    vtrTokenContract.methods.approve(appConfig.currentConfig.address_VTR_Manager,
                        this.state.rateToPay * appConfig.approvalMultiply)
                        .send({
                            from: this.props.walletReducer.connectedAddress,
                            gas: appConfig.currentConfig.blockchainGasLimit
                        })
                        .then(async (receipt) => {
                            Logger.info(`Successfully approved ${this.state.rateToPay} VTR with TRX hash: ${receipt.transactionHash}`);

                            // Upload data as JSON to IPFS
                            let auth = "Basic " + Buffer.from(appConfig.IPFS_ProjectID + ":" + appConfig.IPFS_Key).toString("base64");
                            let client = create({url: appConfig.IPFS_Endpoint, headers: {authorization: auth}});

                            let storedJSON = await client.add(JSON.stringify(this.getDisputeJSON()));
                            let recordManagerContract = this.props.blockchainReducer.recordManagerContract;

                            let signature = await window.ethereum.request({
                                method: 'eth_signTypedData_v4',
                                params: [this.props.walletReducer.connectedAddress,
                                    JSON.stringify(EIP712types.newDispute(parseInt(this.props.extID)))
                                ],
                            });

                            let rsv = EIP712types.getRSVfromSignature(signature);

                            recordManagerContract.methods.addExtensionDispute(parseInt(this.props.extID),
                                storedJSON.path, rsv.r, rsv.s, rsv.v)
                                .send({
                                    from: this.props.walletReducer.connectedAddress,
                                    gas: this.state.disputeEstimatedGasUse
                                })
                                .then((receipt) => {
                                    this.props.dispatchBlockchainLoadingStop();

                                    this.setState({
                                        submittingDispute: false,
                                        submissionSuccessful: true,
                                        trxReceipt: receipt
                                    }, () => {
                                        DataReporter.trackMixpanel(this.props, "Ext. Record: Submitted dispute", {
                                            category: "Interaction",
                                        });

                                    });
                                })
                                .catch((err) => {
                                    this.props.dispatchBlockchainLoadingStop();
                                    this.setState({
                                        submittingDispute: false,
                                        submissionSuccessful: false,
                                    }, () => {
                                        this.onCancelAddDisputeNote();

                                        DataReporter.trackSentry(err, {
                                            extra: {additionalData: "ExtensionRecord: addExtensionDispute."}
                                        });


                                    });
                                    Logger.info(err.message);
                                })
                        })
                        .catch((err) => {
                            this.props.dispatchBlockchainLoadingStop();

                            this.setState({
                                submittingDispute: false,
                                submissionSuccessful: false,
                            }, () => {
                                this.onCancelAddDisputeNote();

                                DataReporter.trackSentry(err, {
                                    extra: {additionalData: "ExtensionRecord: Approve dispute submission."}
                                });

                                DataReporter.trackMixpanel(this.props, "Error: Approve dispute creation", {
                                    category: "Interaction",
                                });

                            });

                            Logger.info(err.message);
                        })
                } catch (e) {
                    Logger.error(e);
                }

            });
        }
    }

    getDisputeNotes() {
        if (this.props.disputeNotes) {
            for (let i = 0; i < this.props.disputeNotes.length; i += 1) {

                let dispute = this.props.disputeNotes[i];
                if (dispute.extID === this.props.extID) {
                    this.setState({
                        disputeNotes: dispute.res
                    });
                    break;
                }
            }
        }
    }

    setParentRefs() {
        if (this.props.data.changeType === "Damage") {
            if (!this.props.damageUpdateRefSet) {
                this.setState({
                    parentRef: this.props.damageUpdateRef
                }, () => {
                    this.props.damageUpdateIsSet(true);
                });
            }
        }

        if (this.props.data.changeType === "Internal" || this.props.data.changeType === "External") {
            if (!this.props.modUpdateRefSet) {
                this.setState({
                    parentRef: this.props.modUpdateRef
                }, () => {
                    this.props.modUpdateRefIsSet(true);
                });
            }
        }
    }

    // Events
    componentDidMount() {
        try {
            if (this.props.data.providerID) {
                this.setState({
                    fromProvider: true,
                    providerID: this.props.data.providerID
                });
            }
        }
        catch (err) {}
    }

    onDisputeNoteMsgChange(e) {
        if (e.inputValue.length >= this.state.minInputLength) {
            this.setState({addDisputeNoteMsg: e.inputValue});
        }
    }

    onAddDisputeNote() {
        if (!this.state.addDisputeNoteMode) {
            this.setState({addDisputeNoteMode: true});
        }
    }

    onCancelAddDisputeNote() {
        this.setState({addDisputeNoteMode: false});
    }

    onShowDisputeNotes() {
        this.setState({showDisputeNotes: true}, () => {
            DataReporter.trackMixpanel(this.props, "Show dispute notes", {
                category: "Interaction",
            });

        });
    }

    onHideDisputeNotes() {
        this.setState({showDisputeNotes: false}, () => {
            DataReporter.trackMixpanel(this.props, "Hide dispute notes", {
                category: "Interaction",
            });

        });
    }

    onVTRGetRate(rate) {
        if (this.props.walletReducer.vtrBalance) {
            this.setState({
                enoughBalance: this.props.walletReducer.vtrBalance >= Math.abs(rate),
                rateToPay: Math.abs(rate)
            });
        }
    }

    onAddDisputeGetGasUseEstimate(estimate) {
        this.setState({
            disputeEstimatedGasUse: estimate
        })
    }

    onClickCreatorAddress(val) {
        let baseUrl = appConfig.explorerURLs.testnet.address;

        DataReporter.trackMixpanel(this.props, "Ext: Opening creator address", {
            category: "Interaction",
        });

        window.open(baseUrl + val, "_blank");
    }

    onInvalidateExt() {
        if (!this.state.extInvalid) {
            this.setState({extInvalid: true}, () => {
                DataReporter.trackMixpanel(this.props, "Ext. record: Invalidated by mods.");
            });
        }
    }

    // Renderers
    renderTRXReceipt() {
        if (this.state.submissionSuccessful === true && this.state.trxReceipt) {
            let last = this.state.trxReceipt;

            DataReporter.trackMixpanel(this.props, "TRX receipt: Dispute created", {
                category: "Display"
            });

            return <TRXReceipt
                descr={"Added dispute note"}
                trxHash={last.transactionHash}
                trxGasUsed={last.gasUsed}
                estimatedGas={this.state.disputeEstimatedGasUse}
            />;
        }
    }

    renderDisputeSubmitButtons() {
        if (this.state.submissionSuccessful === null) {
            let balance = this.props.walletReducer.vtrBalance ? this.props.walletReducer.vtrBalance : 0;
            balance = parseInt(balance);

            let spinner;
            if (this.state.submittingDispute) {
                spinner = <div id={"spinner-container"}>
                    <img id="spinner" src={process.env.PUBLIC_URL + '/spinner.gif'} alt={"spinner"}/>
                </div>;
            }

            return (
                <div>
                    <div id={"button-container"} className={"inline"}>
                        <UserButton
                            forMobile={this.props.appReducer.mobileMode}
                            id={"submit-button"}
                            value={this.state.submittingDispute ? "Submitting dispute" : "Add dispute"}
                            onClick={(e) => this.submitDispute(e)}
                            icon={<BiCommentError className={"text-icon"}/>}
                            disabled={this.state.addDisputeNoteMsg === null
                                || this.state.submittingDispute || (balance - this.state.rateToPay) < 0}
                        />
                        <UserButton
                            forMobile={this.props.appReducer.mobileMode}
                            id={"cancel-button"}
                            value={"Cancel"}
                            onClick={() => this.onCancelAddDisputeNote()}
                            disabled={this.state.submittingDispute || (balance - this.state.rateToPay) < 0}
                        />
                        {spinner}
                    </div>
                </div>
            );
        }
    }

    renderExistingDisputeNotes() {
        if (this.state.disputeNotes) {
            if (this.state.showDisputeNotes) {
                let notes = [];
                for (let i = 0; i < this.state.disputeNotes.length; i += 1) {
                    let current = this.state.disputeNotes[i].data;

                    let originatorAddress = current.originator;
                    if (this.props.appReducer.mobileMode) {
                        originatorAddress = originatorAddress.slice(0, 5) + "-" + originatorAddress.slice(-5);
                    }

                    originatorAddress = originatorAddress.slice(0, appConfig.address_SliceLen)
                        + "-" + originatorAddress.slice(-appConfig.address_SliceLen);

                    notes.push(
                        <div className={"single"} key={i}>
                            <p className={"date"}>Created
                                on {DateConverter.dateObjToString(parseInt(current.disputeCreationDate))}</p>
                            <p className={"originator"}>
                                Creator
                                <a onClick={() => this.onClickCreatorAddress(current.originator)}
                                   className={"data"}>{originatorAddress}</a>
                            </p>
                            <p className={"note"}><BiComment className={"text-icon"}/> {current.note}</p>
                        </div>
                    );
                }
                return (
                    <div className={"existing-dispute-notes"}>
                        <h3>Dispute notes</h3>
                        <p className={"info"}>
                            <AiOutlineInfoCircle className={"icon"}/>
                            Dispute notes can only be created by transferred owners.
                        </p>
                        <p className={"hide-link"} onClick={() => this.onHideDisputeNotes()}>Hide dispute notes</p>
                        {notes}
                    </div>
                )

            } else {
                return (
                    <div className={"existing-dispute-notes"} onClick={() => this.onShowDisputeNotes()}>
                        <p className={"counter"}><BiCommentError
                            className={"text-icon"}/><b>{this.state.disputeNotes.length}</b> dispute notes</p>
                    </div>
                )
            }
        } else {
            this.getDisputeNotes();
        }
    }

    renderAddDispute() {
        // Dispute notes can only be added by owners other than extension originators
        if (this.props.walletReducer.connectedAddress === this.props.data.originator
            || (this.props.walletReducer.connectedAddress === this.props.currentOwnerAddress)
            && !this.state.submissionSuccessful) {

            let content = <p className={"add-link"} onClick={() => this.onAddDisputeNote()}>Add dispute note</p>;

            if (this.state.addDisputeNoteMode) {
                content = <div className={"dispute-form"}>
                    <VTRCalculator
                        id={"vtr-calc"}
                        posName={"Adding dispute note"}
                        rateName={"getRateAddDisputeNote"}
                        onGetRate={(rate) => this.onVTRGetRate(rate)}
                        onGetGasUseEstimate={(estimate) => this.onAddDisputeGetGasUseEstimate(estimate)}
                    />
                    <TextArea
                        className={"text-area dispute-descr"}
                        label={"Dispute description"}
                        cols={120}
                        maxLen={140}
                        placeholder={"Dispute description"}
                        onChange={(e) => this.onDisputeNoteMsgChange(e)}
                        validationError={!this.state.addDisputeNoteValid}
                        validationMsg={`The given data has to be at least ${this.state.minInputLength} characters long.`}
                    />
                    {this.renderDisputeSubmitButtons()}
                    <HintContainer
                        forMobile={this.props.appReducer.mobileMode}
                        id={"submit-dispute-hint-container"}
                        hints={[
                            "You will be asked to approve two transactions.",
                            "The first one is for your payment in VTR.",
                            "The second is for the actual submission of your dispute."
                        ]}
                    />
                </div>
            }

            return (
                <div className={"add-dispute-container"}>
                    {content}
                </div>
            );
        }
    }

    renderChangeTypeIcon() {
        let type = this.props.data.changeType;
        if (type === "Internal") return <BiChip className={"type-icon"}/>
        else if (type === "External") return <AiOutlineTool className={"type-icon"}/>
        else if (type === "Damage") return <GoReport className={"type-icon"}/>
        else if (type === "Other") return <BsBoxSeam className={"type-icon"}/>
    }

    renderBody() {
        if (!this.state.extInvalid) {

            let originatorAddress = this.props.data.originator;
            originatorAddress = originatorAddress.slice(0, appConfig.address_SliceLen)
                + "-" + originatorAddress.slice(-appConfig.address_SliceLen);

            let dateString;
            if (this.state.fromProvider) {
                dateString = DateConverter.dateObjToString(this.props.data.changeDate);
            } else {
                dateString = DateConverter.dateObjToString(parseInt(this.props.data.changeDate));
            }

            return (
                <div>
                    <div className={"l2"}>
                        <CreatorInfo
                            title={"Updated by verified owner"}
                            creatorAddress={this.props.data.originator}
                            defaultRender={(
                                <p className={"originator"}>
                                    Creator
                                    <a className={"data"}
                                       onClick={() => this.onClickCreatorAddress(this.props.data.originator)}>
                                        {originatorAddress}
                                    </a>
                                </p>
                            )}
                            fromProvider={this.state.fromProvider}
                            providerID={this.state.providerID}
                        />

                    </div>

                    <div className={"l3"}>
                        <div className={"record-creation-date"}>
                            <p className={"label"}>Happened on</p>
                            <p className={"data"}>
                                <BsCalendarWeek className={"cal-icon"}/>
                                {dateString}
                            </p>
                        </div>
                    </div>

                    <div className={"l4"}>
                        <div className={"descr-container"}>
                            <p className={"descr-label"}>Description added by creator</p>
                            <div className={"inline descr-val"}>
                                <p className={"descr"}>
                                    <span className={"red-quotes"}>“</span>
                                    {this.props.data.description}
                                    <span className={"red-quotes"}>”</span>
                                </p>
                            </div>
                        </div>
                        {this.renderTRXReceipt()}
                    </div>

                    <div className={"dispute-container"}>
                        {this.renderExistingDisputeNotes()}
                        {this.renderAddDispute()}
                    </div>

                    <div className={"img-presenter"}>
                        <ImagePresenter
                            forMobile={this.props.appReducer.mobileMode}
                            imageCIDs={this.props.data.imageCIDs}
                        />
                    </div>

                </div>);
        }
    }

    render() {
        if (this.props.data
            && (this.props.updateFilter
                && this.props.updateFilter.toLowerCase() === this.props.data.changeType.toLowerCase())
            || !this.props.updateFilter) {
            let disputed = "";
            let disputedTag = null;

            if (this.state.disputeNotes && this.state.disputeNotes.length > 0) {
                disputed += "disputed";
                disputedTag = <p className={"disputed-tag"}>
                    <AiOutlineWarning className={"icon"}/>
                    Disputed information
                </p>;
            }

            let mobileSuffix = "";
            let changeDateLabel = "Added on";

            if (this.props.appReducer.mobileMode) {
                mobileSuffix = "-mobile";
                changeDateLabel = "";
            }

            this.setParentRefs();

            return (
                <div className={"record-extension-container" + mobileSuffix + " " + disputed}
                     ref={this.state.parentRef}>
                    <div className={"record-content"}>
                        {disputedTag}

                        <div className={"l1 inline"}>
                            <p className={`record-type ext-` + this.props.data.changeType.toLowerCase()}>
                                {this.renderChangeTypeIcon()}
                                {this.props.data.changeType} {!this.props.appReducer.mobileMode ? "update" : ""}
                            </p>
                            <div className={"added-on-container data-" + this.props.data.changeType.toLowerCase()}>
                                <p className={"label"}>{changeDateLabel}&nbsp;</p>
                                <p className={"data"}>
                                    <span className={"data"}>
                                    <BsCalendarWeek className={"cal-icon"}/>
                                        {DateConverter.dateObjToString(parseInt(this.props.data.recordCreationDate))}
                                    </span>
                                </p>
                            </div>
                        </div>

                        {this.renderBody()}
                        <DisputeDecision
                            itemType={"Ext"}
                            itemID={this.props.extID}
                            decisions={this.props.disputeDecisions}
                            onInvalidate={() => this.onInvalidateExt()}
                        />

                    </div>
                </div>
            );
        }
    }

}

const mapStateToProps = (state) => {
    return {
        walletReducer: state.wallet,
        blockchainReducer: state.blockchain,
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchBlockchainLoading: () => {
            dispatch(requestSetBlockchainLoading())
        },
        dispatchBlockchainLoadingStop: () => {
            dispatch(requestSetBlockchainLoadingStop())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtensionRecord);
