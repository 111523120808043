import React from "react";
import Web3 from "web3";
import appConfig from "../appConfig";
import {connect} from "react-redux";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import FAQBox from "../components/support/FAQBox";
import {DataReporter} from "../DataReporter";
import {AiOutlineMail, AiOutlineReddit, AiOutlineInstagram, AiFillPhone} from "react-icons/ai";
import {TbWorldPlus} from "react-icons/tb";
import {PiCoins} from "react-icons/pi";
import customWagmiChains from "../customWagmiChains";
import {requestSetCurrentPage} from "../store/actions/app";
import "../styles/pages/support.scss";

class Support extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryRecords: "catRecords",
            categoryCrypto: "catCrypto",
            categoryUsing: "catUsing",
            activeCategoryFilter: null
        };
    }

    // Events
    componentDidMount() {
        this.props.dispatchSetPage("support");
        DataReporter.trackMixpanel(this.props, "Page view: Support", {
            pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop"
        });
    }

    onEmailClick() {
        DataReporter.trackSentry(this.props, "Contact via mail", {
            category: "Interaction"
        });
        window.location.href = `mailto:${appConfig.supportEmail}`;
    }

    onRedditClick() {
        DataReporter.trackMixpanel(this.props, "Contact via reddit", {
            category: "Interaction"
        });

        setTimeout(() => {
            let redditURL = "https://www.reddit.com/user/OnurKaraman_Ventrace";
            window.open(redditURL, "_blank");
        }, 500);
    }

    onInstagramClick() {
        DataReporter.trackMixpanel(this.props, "Contact via Instagram",
            {category: "Interaction"});

        setTimeout(() => {
            let instaURL = "https://www.instagram.com/ventrace.official";
            window.open(instaURL, "_blank");
        }, 500);
    }

    onTweetClick() {
        DataReporter.trackMixpanel(this.props, "Contact via twitter", {
            category: "Interaction"
        });

        let twitterURL = "https://twitter.com/onur_ventrace";
        window.open(twitterURL, "_blank");
    }

    async onAddVTRClick() {
        DataReporter.trackMixpanel(this.props, "Clicked on quick fix: Add VTR", {
            category: "Interaction"
        });

        if (window.ethereum) {
            let web3 = new Web3(window.ethereum);
            web3.currentProvider.sendAsync({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: appConfig.currentConfig.contracts.address_VTR_Token,
                        symbol: "VTR Token",
                        decimals: 5,
                    },
                },
                id: Math.round(Math.random() * 100000),
            }, (error, result) => {
                if (error) {
                    DataReporter.trackSentry(error, {
                        extra: {additionalData: "Support: Add VTR to wallet."}
                    });
                } else {
                    DataReporter.trackMixpanel(this.props, "Added token to wallet",
                        {
                            category: "Interaction"
                        });
                }
            });
        } else {
            // MetaMask is not installed
            console.error('MetaMask is not available');
        }
    }

    onConnectToNetwork() {
        DataReporter.trackMixpanel(this.props, "Clicked on quick fix: Add network", {
            category: "Interaction"
        });

        if (window.ethereum) {
            let customChain;
            if (appConfig.useMainnet) customChain = customWagmiChains.getPolygonMainnet();
            else customChain = customWagmiChains.getAmoy();

            window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                    chainId: appConfig.useMainnet ? "0x89" : "0x13882",
                    chainName: customChain.name,
                    nativeCurrency: {
                        name: customChain.nativeCurrency.name,
                        symbol: customChain.nativeCurrency.symbol,
                        decimals: customChain.nativeCurrency.decimals,
                    },
                    rpcUrls: [customChain.rpcUrls.default.http[0]],
                    blockExplorerUrls: [customChain.blockExplorers.default.url],
                }],
            })
        }
    }

    onFilterClick(cat) {
        if (cat === this.state.activeCategoryFilter) {
            this.setState({activeCategoryFilter: null});
        } else {
            this.setState({activeCategoryFilter: cat});
        }
    }

    // Renderers
    renderPhoneBox() {
        let phoneNr = this.props.appReducer.supportPhoneNrs.USA;
        if (this.props.appReducer.userRegionInEurope) {
            phoneNr = this.props.appReducer.supportPhoneNrs.Europe;
        }

        return (
            <div id={"phone-box-container"}>
                <p id={"h"}>Contact per phone</p>
                <p id={"phone-nr"}><AiFillPhone id={"phone-icon"}/> {phoneNr}</p>
                <p id={"d"}>
                    This is a free service phone number. However, your phone service
                    provider may generally charge you for outgoing calls.
                </p>
            </div>
        );
    }

    renderSocialBox() {
        return (
            <div id={"social-box-container"}>
                <p id={"h"}>Contact on Social Media</p>
                <p id={"d"}>
                    You can get direct support through various social media channels.
                </p>

                <div id={"platforms-container"}>
                    <div className={"social-media-platform"}
                         onClick={() => this.onInstagramClick()}>
                        <AiOutlineInstagram className={"icon"} id={"ig-icon"}/>
                        <p className={"name"}>Message on Instagram</p>
                    </div>

                    <div className={"social-media-platform"}
                         onClick={() => this.onRedditClick()}>
                        <AiOutlineReddit className={"icon"} id={"reddit-icon"}/>
                        <p className={"name"}>Message on reddit</p>
                    </div>
                </div>
            </div>
        )
    }

    renderEmailBox() {
        return (
            <div id={"email-box"}>
                <AiOutlineMail id={"icon"} onClick={() => this.onEmailClick()}/>
                <div id={"descr-container"} onClick={() => this.onEmailClick()}>
                    <p id={"h"}>Contact per email</p>
                    <p id={"descr"}>
                        Send your question per mail and get help directly.
                        You can expect to get an answer within 1-2 days.
                    </p>
                </div>
            </div>
        );
    }

    renderFAQFilter() {
        let s = this.state;
        return (
            <div id={"faq-filter"}>
                <p id={"descr"}>Category filter</p>
                <div id={"cats"}>
                    <p className={"cat" + (s.activeCategoryFilter === s.categoryRecords ? "-active" : "")}
                       onClick={() => this.onFilterClick(s.categoryRecords)}>
                        Creating and handling Records
                    </p>

                    <p className={"cat" + (s.activeCategoryFilter === s.categoryUsing ? "-active" : "")}
                       onClick={() => this.onFilterClick(s.categoryUsing)}>
                        Using Ventrace
                    </p>

                </div>
            </div>
        );
    }

    renderFAQBoxes() {
        return (
            <div id={"faqs"}>
                <FAQBox
                    forMobile={this.props.appReducer.mobileMode}
                    question={"How is Ventrace getting the data of all products on earth?"}
                    answer={"Ventrace is a platform which is receiving product details from a wide range of 'Ventrace Providers'. " +
                        "Ventrace Providers are international businesses such as product factories or retail shops. By collecting the data" +
                        " from its various providers, Ventrace is able to create a Ventrace Records for any product on earth."}
                    category={this.state.categoryRecords}
                    filterCategory={this.state.activeCategoryFilter}
                />
                <FAQBox
                    forMobile={this.props.appReducer.mobileMode}
                    question={"How are Ventrace Records created and stored?"}
                    answer={"Ventrace will first receive an order for a Ventrace Record and verify the quality of " +
                        "the user-submitted product details. If the order passes, Ventrace will then start specific procedures" +
                        " which will ultimately create a Ventrace Record on a public blockchain. The Ventrace Record will " +
                        "remain stored on the public blockchain and will be changable by the person who placed the Record order."}
                    category={this.state.categoryRecords}
                    filterCategory={this.state.activeCategoryFilter}
                />
                <FAQBox
                    forMobile={this.props.appReducer.mobileMode}
                    question={"How does the Money-Back Guarantee work?"}
                    answer={"Ventrace offers a full refund within 30 days of purchase. " +
                        "Customers can request a refund via email without providing a reason. " +
                        "Refunds are typically processed within a week of receiving the request."}
                    category={this.state.categoryUsing}
                    filterCategory={this.state.activeCategoryFilter}
                />
                <FAQBox
                    forMobile={this.props.appReducer.mobileMode}
                    question={"Why do I need crypto funds to update my Record?"}
                    answer={"Ventrace Records are stored on a public blockchain, where modifying data typically " +
                        "costs a transaction fee in cryptocurrency. Therefore, Ventrace customers receive a sufficient" +
                        " amount of cryptocurrency to cover these fees when ordering a Ventrace Record."}
                    category={this.state.categoryUsing}
                    filterCategory={this.state.activeCategoryFilter}
                />

            </div>
        );
    }

    renderQuickFixes() {
        if (window.ethereum) {
            return (
                <div id={"quick-fix-container"}>
                    <h2>Quick fixes</h2>
                    <p id={"quick-fix-descr"}>
                        Use these fixes when you experience issues with your crypto wallet on Ventrace.
                    </p>
                    <div className={"quick-fix"}>
                        <p className={"descr"} onClick={() => this.onConnectToNetwork()}>
                            <TbWorldPlus id={"network-icon"}/>
                            Connect your wallet to the blockchain of Ventrace
                        </p>
                    </div>

                    <div className={"quick-fix"}>
                        <p className={"descr"} onClick={() => this.onAddVTRClick()}>
                            <PiCoins id={"coins-icon"}/>
                            Make your <span className={"vtr-hl"}>VTR</span> balance visible in your MetaMask wallet
                        </p>
                    </div>
                </div>
            );
        }
    }

    renderMobileLayout() {
        return (
            <div>
                <Navigation/>
                <div className={"content-container-mobile"}>
                    <h1>Help and support</h1>
                    <h2>How can we help you?</h2>

                    {this.renderEmailBox()}
                    {this.renderPhoneBox()}
                    {this.renderSocialBox()}

                    <div id={"faq-container"}>
                        <h2 id={"faq-h2"}>Frequently asked questions</h2>
                        {this.renderFAQFilter()}
                        {this.renderFAQBoxes()}
                    </div>

                    {this.renderQuickFixes()}
                </div>
                <Footer
                    forMobile={true}
                />
            </div>
        );
    }

    renderDesktopLayout() {
        return (
            <div>
                <Navigation/>
                <div className={"content-container"}>
                    <h1>Help and support</h1>
                    <h2>How can we help you?</h2>

                    <div id={"l"}>
                        {this.renderEmailBox()}

                        {this.renderQuickFixes()}

                        <div id={"faq-container"}>
                            <h2 id={"faq-h2"}>Frequently asked questions</h2>
                            {this.renderFAQFilter()}
                            {this.renderFAQBoxes()}
                        </div>

                    </div>

                    <div id={"r"}>
                        {this.renderPhoneBox()}
                        {this.renderSocialBox()}
                    </div>
                    <div id={"clearer"}/>
                </div>
                <Footer
                    forMobile={this.props.appReducer.mobileMode}
                />
            </div>
        );
    }

    render() {
        document.title = `Ventrace - Support`;
        if (this.props.appReducer.mobileMode) {
            return this.renderMobileLayout();
        } else {
            return this.renderDesktopLayout();
        }
    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchSetPage: (pageName) => {
            dispatch(requestSetCurrentPage(pageName));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Support);