import React from "react";
import {connect} from "react-redux";
import appConfig from "../appConfig";
import {DateConverter} from "../DateConverter";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import FeedbackMessage from "../components/FeedbackMessage";
import DropdownInput from "../components/inputs/DropdownInput";
import TextInput from "../components/inputs/TextInput";
import TextArea from "../components/inputs/TextArea";
import HintContainer from "../components/HintContainer";
import RecordCheckout from "../components/RecordCheckout";
import DatePicker from "react-datepicker";
import ImageUploadInput from "../components/inputs/ImageUploadInput";
import OrderSuccess from "../components/OrderSuccess";
import UIConfigLoader from "../components/UIConfigLoader";
import {BsCalendarCheck} from "react-icons/bs";
import {
    requestSetBlockchainLoading,
    requestSetBlockchainLoadingStop
} from "../store/actions/blockchain";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/pages/order_record.scss";
import {DataReporter} from "../DataReporter";
import {requestSetCurrentPage, requestSetOrderData} from "../store/actions/app";
import axios from "axios";

global.Buffer = global.Buffer || require('buffer').Buffer;

class OrderRecord extends React.Component {
    constructor(props) {
        super(props);

        let freshOrder = new URLSearchParams(this.props.location.search).get("f")
        let appReducer = this.props.appReducer;

        let createName = null;
        let createDescr = null;
        let createSNr = null;
        let createPurchaseDateValid = true;

        if (appReducer.claimingRecord) {
            createName = appReducer.claimingRecord.rootRecordData.name;
            createDescr = appReducer.claimingRecord.rootRecordData.description;
            createSNr = appReducer.claimingRecord.rootRecordData.serialNr;
            createPurchaseDateValid = false;
        }
        else if (appReducer.orderData && freshOrder !== "1") {
            createName = appReducer.orderData.createName;
            createDescr = appReducer.orderData.createDescr;
            createSNr = appReducer.orderData.createSNr;
            createPurchaseDateValid = false;
        }

        this.state = {
            minInputLength: 5,
            selectedOriginationLvl: "private",
            productNameExample: this.getProductNameExample(),
            createName: createName,
            createNameValid: true,
            createDescr: createDescr,
            createDescrValid: true,
            createPurchaseDate: Date.now(),
            createSNr: createSNr,
            createSNrValid: true,
            createSNrValidationMsg: null,
            createImageFiles: null,
            createImageCID: null,
            createPurchaseDateValid: createPurchaseDateValid,
            createPurchaseDateValidMsg: "Please re-check this date",
            imageUploadValidationErr: null,
            imageUploadValidationMsg: null,
            submissionSuccessful: null,
            trxReceipt: null,
            submittingRecord: false,
            estimatedGasUse: null,
            errorMessage: null,
            formIsValid: false,
            checkoutSuccess: false,
            orderPRP: null,
            redirectChecking: false, // true if this page is opened via payment redirect URL,
            checkoutErrorMessage: null
        }

        this.orderFormRef = React.createRef();
    }

    getProductWord(capitalize) {
        let productWord = "product";
        if (this.props.appReducer.activePersona === appConfig.personas.watches) {
            productWord = "watch";
        }
        if (capitalize) {
            return productWord.charAt(0).toUpperCase() + productWord.slice(1);
        } else {
            return productWord;
        }
    }

    getProductNameExample() {
        let examples = [
            "Apple iPhone 15 Pro, 256 GB, Blue",
            "Lenovo ThinkPad T14, 512 GB SSD",
            "Samsung Crystal UHD TV 4K 75\" 2023"
        ];

        if (this.props.appReducer.activePersona === appConfig.personas.watches) {
            examples = [
                "Rolex Submariner Date (Starbucks)",
                "Omega Speedmaster (Black) 2024",
                "Breitling Navitimer (Automatic 41)"
            ];
        }

        return examples[Math.floor(Math.random() * examples.length)];
    }

    getRecordJSON(imageCIDs) {
        if (this.state.createName && this.state.createDescr
            && this.state.createSNr && this.props.walletReducer.connectedAddress) {
            return {
                formatVersion: 1,
                recordCreationDate: Date.now().toString(),
                recordType: "rootRecord",
                originator: this.props.walletReducer.connectedAddress.trim(),
                name: this.state.createName.trim(),
                description: this.state.createDescr.trim(),
                imageCIDs: imageCIDs,
                serialNr: this.state.createSNr.trim(),
                purchaseDate: this.state.createPurchaseDate
            }
        }
        return null;
    }

    getOrderStatus(orderID) {
        try {
            axios
                .get(appConfig.currentConfig.backendApp.url + "/order/statusupdate?orderID=" + orderID,
                    {
                        headers: {
                            Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                            'Content-Type': 'application/json',
                        },
                    })
                .then(res => {
                    if (res.status === 200) {
                        let s = res.data.status;
                        let statusIsFinal = (s === "paid" || s === "expired" || s === "failed" || s === "canceled")

                        if (statusIsFinal) {
                            if (s === "paid") {
                                this.setState({
                                    checkoutSuccess: true,
                                    orderPRP: res.data.prp,
                                    redirectChecking: false
                                }, () => {
                                    this.props.dispatchSetOrderData(null);
                                });
                            } else {
                                this.setState({
                                    checkoutErrorMessage: "The payment has failed. Please try again.",
                                    redirectChecking: false,
                                }, () => {
                                    DataReporter.trackMixpanel(this.props, "orderStatusFail", {
                                        category: "Payment",
                                        failReason: s
                                    })
                                });
                            }

                        } else {
                            setTimeout(() => this.getOrderStatus(orderID), 3000);
                        }
                    } else {
                        this.setState({
                            checkoutErrorMessage: "The payment has failed. Please try again.",
                        });
                    }
                })
                .catch(err => {
                    DataReporter.trackSentry(err,
                        { extra: {additionalData: "checkRedirectedOrder 01"} });
                    this.setState({
                        redirectChecking: false
                    });
                })

        } catch (err) {
            DataReporter.trackSentry(err,
                { extra: {additionalData: "checkRedirectedOrder 02"} });
            this.setState({
                redirectChecking: false,
                errorMessage: "Can't confirm payment. Please contact support."
            });
        }
    }

    validateForm() {
        let canSave = true;
        this.setState({
            createNameValid: true,
            createDescrValid: true,
            createSNrValid: true,
            createSNrValidationMsg: null,
            showValidErrorHint: false,
            imageUploadValidationErr: null,
        }, () => {
            DataReporter.trackMixpanel(this.props, "Validating Record", {
                category: "Interaction"
            });
        });

        if (this.state.createName === null || this.state.createName.length < this.state.minInputLength) {
            this.setState({
                createNameValid: false
            }, () => {
                DataReporter.trackMixpanel(this.props,"Validation Error: Name too short",
                    { category: "Feedback" });
            });

            canSave = false;
        }

        if (this.state.createDescr === null || this.state.createDescr.length < this.state.minInputLength) {
            this.setState({
                createDescrValid: false
            }, () => {
                DataReporter.trackMixpanel(this.props, "Validation Error: Description too short", {
                    category: "Feedback"
                });
            });
            canSave = false;
        }

        if (this.state.createSNr === null || this.state.createSNr.length < this.state.minInputLength) {
            this.setState({
                createSNrValid: false,
                createSNrValidationMsg: "The given serial number is too short."
            }, () => {
                DataReporter.trackMixpanel(this.props, "Validation Error: Serial Nr too short", {
                    category: "Feedback"
                });
            });
            canSave = false;
        }

        let regex = /^[a-zA-Z0-9-]+$/;
        if (!regex.test(this.state.createSNr)) {
            this.setState({
                createSNrValid: false,
                createSNrValidationMsg: "The serial number cannot have any special characters (including spaces)."
            }, () => {
                DataReporter.trackMixpanel(this.props, "Validating Error: Serial Nr has special characters", {
                    category: "Feedback"
                });
            });
            canSave = false;
        }

        if (this.state.createImageFiles === null || this.state.createImageFiles.length < 2) {
            this.setState({
                imageUploadValidationErr: true,
                imageUploadValidationMsg: `You need to attach at least 2 images (of ${this.getProductWord()} or receipt).`,
            }, () => {

                DataReporter.trackMixpanel(this.props,
                    `Validation Error: Not enough images (${this.state.createImageFiles.length} attached).`, {
                    category: "Feedback"
                });
            });
            canSave = false;
        }

        this.setState({formIsValid: canSave});
    }

    getDescriptionHints() {
        if (this.props.appReducer.activePersona === appConfig.personas.watches) {
            return [
                "Describe your watch in detail",
                "What type of watch (e. g. diver, chronograph, etc.)",
                "Which movement, what type of bracelet",
            ];
        } else {
            return [
                `The general description of the product (color, size, etc.).`,
            ];
        }
    }

    checkRedirectedOrder() {
        let orderID = new URLSearchParams(this.props.location.search).get("oid");

        if (orderID) {
            this.setState({
                redirectChecking: true,
                createPurchaseDateValid: true,
                createPurchaseDateValidMsg: null
            }, () => {
                setTimeout(() => {
                    let targetYPosition = this.orderFormRef.current.getBoundingClientRect().top;

                    window.scrollTo({
                        top: window.scrollY + targetYPosition - 300,
                        behavior: 'smooth',
                    });
                }, 200);

                this.getOrderStatus(orderID);
            })
        }
    }

    checkOrderReset() {
        let freshOrder = new URLSearchParams(this.props.location.search).get("f");
        if (freshOrder === "1") {
            this.props.dispatchSetOrderData(null);
        }
    }

    // Events
    componentDidMount() {
        this.props.dispatchSetPage("order");
        DataReporter.trackMixpanel(this.props,
            "Page view: Order Record",
            {
                pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop",
                trigger: new URLSearchParams(this.props.location.search).get("t")
            });

        this.checkOrderReset();
        this.checkRedirectedOrder();
    }

    nameOnChange(e) {
        if (e.inputValue.length >= this.state.minInputLength) {
            this.setState({createName: e.inputValue});
        }
    }

    nameOnPaste(e) {
        this.nameOnChange({inputValue: e.clipboardData.getData("text")});
    }

    descrOnChange(e) {
        if (e.inputValue.length >= this.state.minInputLength) {
            this.setState({createDescr: e.inputValue});
        }
    }

    descrOnPaste(e) {
        this.descrOnChange({inputValue: e.clipboardData.getData("text")});
    }

    purchaseDateOnChange(date) {
        this.setState({
            createPurchaseDate: DateConverter.getUTCMilliseconds(date)
        }, () => {
            DataReporter.trackMixpanel(this.props, "Changed record date", {
                category: "Interaction"
            });
        });
    }

    serialNrOnChange(e) {
        if (e.inputValue.length >= this.state.minInputLength) {
            this.setState({createSNr: e.inputValue});
        }
    }

    serialNrOnPaste(e) {
        this.serialNrOnChange({inputValue: e.clipboardData.getData("text")});
    }

    createImageFileOnChange(e) {
        let a = [];
        for (let i = 0; i < e.length; i += 1) {
            if (e[i] !== null) a.push(e[i]);
        }
        this.setState({
            createImageFiles: a
        }, () => {
            DataReporter.trackMixpanel(this.props, "Changed image attachment", {
                category: "Interaction",
                value: e.length
            });

            this.validateForm();
        });
    }

    resetImageFile(e) {
        this.setState({createImageFile: null});
    }

    onCheckoutSuccess(prp) {
        this.setState({
            checkoutSuccess: true,
            orderPRP: prp
        });
    }

    onSaveOrderData(checkoutInfos) {
        if (this.state.createName) {
            this.props.dispatchSetOrderData({
                createName: this.state.createName,
                createDescr: this.state.createDescr ? this.state.createDescr : null,
                createSNr: this.state.createSNr ? this.state.createSNr : null,
                premiumPackageSelected: checkoutInfos.premiumPackageSelected,
                firstname: checkoutInfos.firstname,
                lastname: checkoutInfos.lastname,
                email: checkoutInfos.email,
                selectedMethod: checkoutInfos.selectedMethod,
                sendNewsletter: checkoutInfos.sendNewsletter
            });
        }
    }

    // Renderers
    renderErrorMessage() {
        if (this.state.errorMessage !== null) {
            return <FeedbackMessage
                success={false}
                message={this.state.errorMessage}
            />;
        }
    }

    renderDateValidation() {
        if (!this.state.createPurchaseDateValid) {
            return (
                <div className={"input-error"} id={"purchase-date-validation"}>
                    <p className={"msg"}>
                        {this.state.createPurchaseDateValidMsg}
                    </p>
                </div>
            )
        }
    }

    renderForm() {
        if (this.state.submissionSuccessful === null && !this.state.checkoutSuccess) {
            let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

            let originationLevels = ["Private owner"];
            let validationMsg = `This field can't be empty.`;

            let minDate = new Date();
            minDate.setFullYear(minDate.getFullYear() - 30);

            return (
                <div className={"add-new-container" + mobileSuffix}>
                    <h1>Order your Ventrace Record</h1>

                    <div id={"form-container"}>
                        <div id={"form-left"}>
                            <DropdownInput
                                id={"origination-dropdown"}
                                label={"Origination level"}
                                options={originationLevels}
                                disabled={true}
                            />

                            <p id={"details-h"}>Details about your {this.getProductWord()}</p>
                            <p>
                                Please enter the details of your {this.getProductWord()}, for which you want
                                to order a Ventrace Record.
                            </p>

                            <div id={"product-name-inputs-container"}>
                                <TextInput
                                    inputID={"product-name-input"}
                                    label={`${this.getProductWord(true)} Name`}
                                    value={this.state.createName}
                                    placeholder={`${this.getProductWord(true)} Name`}
                                    onChange={(e) => this.nameOnChange(e)}
                                    onPaste={(e) => this.nameOnPaste(e)}
                                    validationError={!this.state.createNameValid}
                                    validationMsg={validationMsg}
                                    maxLen={50}
                                    hints={
                                        <HintContainer
                                            forMobile={this.props.appReducer.mobileMode}
                                            hints={[
                                                "Mention the brand, the model and further specifics.",
                                                `Example: ${this.state.productNameExample}.`,
                                            ]}
                                        />
                                    }
                                />
                            </div>

                            <div id={"product-descr-inputs-container"}>
                                <TextArea
                                    id={"product-descr-input"}
                                    label={`${this.getProductWord(true)} Description`}
                                    value={this.state.createDescr}
                                    maxLen={500}
                                    rows={3}
                                    placeholder={`${this.getProductWord(true)} Description`}
                                    onChange={(e) => this.descrOnChange(e)}
                                    onPaste={(e) => this.descrOnPaste(e)}
                                    validationError={!this.state.createDescrValid}
                                    validationMsg={validationMsg}
                                    hints={
                                        <HintContainer
                                            forMobile={this.props.appReducer.mobileMode}
                                            id={"product-descr-hints-mobile"}
                                            hints={this.getDescriptionHints()}
                                        />
                                    }
                                />
                            </div>

                            <div id={"purchase-date-input-container"}>
                                <p className={"input-label"}>
                                    <BsCalendarCheck className={"label-icon"}/>
                                    Purchase Date
                                </p>
                                <DatePicker
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    yearDropdownItemNumber={20}
                                    dropdownMode="select"
                                    dateFormat="yyyy-MM-dd"
                                    className={"purchase-date-input"}
                                    selected={this.state.createPurchaseDate}
                                    onChange={(date) => this.purchaseDateOnChange(date)}
                                    minDate={minDate}
                                    maxDate={new Date()}
                                />
                                {this.renderDateValidation()}
                            </div>

                            <div id={"product-uid-input-container"}>
                                <TextInput
                                    inputID={"product-uid-input"}
                                    label={`${this.getProductWord(true)} Serial Number`}
                                    value={this.state.createSNr}
                                    disabled={this.props.appReducer.claimingRecord}
                                    placeholder={`${this.getProductWord(true)} Serial Number`}
                                    onChange={(e) => this.serialNrOnChange(e)}
                                    onPaste={(e) => this.serialNrOnPaste(e)}
                                    validationError={!this.state.createSNrValid}
                                    validationMsg={this.state.createSNrValidationMsg}
                                    maxLen={50}
                                    hints={
                                        <HintContainer
                                            forMobile={this.props.appReducer.mobileMode}
                                            id={"product-uid-hints-mobile"}
                                            hints={[
                                                `The unique serial number of your ${this.getProductWord()}.`,
                                                `You can find the serial number at the back side of your ${this.getProductWord()}.`
                                            ]}
                                        />
                                    }
                                />
                            </div>

                            <div id={"image-upload-container"}>
                                <p className={"input-label"}>
                                    {`${this.getProductWord(true)} Images (at least 2)`}
                                </p>
                                <ImageUploadInput
                                    forMobile={this.props.appReducer.mobileMode}
                                    onChange={(e) => this.createImageFileOnChange(e)}
                                    onReset={() => this.resetImageFile()}
                                    validationError={this.state.imageUploadValidationErr}
                                    validationMsg={this.state.imageUploadValidationMsg}
                                    hints={
                                        <HintContainer
                                            forMobile={this.props.appReducer.mobileMode}
                                            id={"product-imgs-hints-mobile"}
                                            hints={[
                                                `Try to upload a picture which shows the whole ${this.getProductWord()}.`,
                                                "If possible, also try to upload a picture showing the serial number."
                                            ]}
                                        />
                                    }
                                />
                            </div>
                        </div>

                        <RecordCheckout
                            recordFormIsValid={this.state.formIsValid}
                            validateRecordForm={() => this.validateForm()}
                            rrData={this.getRecordJSON()}
                            imageFiles={this.state.createImageFiles}
                            onCheckoutSuccess={(prp) => this.onCheckoutSuccess(prp)}
                            saveOrderData={(ci) => this.onSaveOrderData(ci)}
                            redirectChecking={this.state.redirectChecking}
                            errorMessage={this.state.checkoutErrorMessage}
                            orderFormRef={this.orderFormRef}
                        />
                    </div>
                </div>
            )
        }
    }

    renderDocumentTitle() {
        if (this.state.createName && this.state.createName.length >= this.state.minInputLength)
            document.title = `Ventrace - ${this.state.createName}`;
        else document.title = "Ventrace - Order Record";
    }

    renderOrderSuccess() {
        if (this.state.checkoutSuccess) {
            document.title = "Ventrace - Order succcessful";
            return (
                <OrderSuccess
                    prp={this.state.orderPRP}
                />
            );
        }
    }

    render() {
        let b = this.props.blockchainReducer;
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        if (!b.web3 || !b.contractsReady) {
            return <UIConfigLoader/>
        } else {
            this.renderDocumentTitle();

            return (
                <div>
                    <Navigation />
                    <div className={"content-container" + mobileSuffix}>
                        {this.renderForm()}
                        {this.renderOrderSuccess()}
                    </div>
                    <Footer
                        forMobile={this.props.appReducer.mobileMode}
                    />
                </div>
            );
        }

    }
}

const mapStateToProps = (state) => {
    return {
        walletReducer: state.wallet,
        blockchainReducer: state.blockchain,
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchBlockchainLoading: () => {
            dispatch(requestSetBlockchainLoading());
        },
        dispatchBlockchainLoadingStop: () => {
            dispatch(requestSetBlockchainLoadingStop());
        },
        dispatchSetOrderData: (orderData) => {
            dispatch(requestSetOrderData(orderData));
        },
        dispatchSetPage: (pageName) => {
            dispatch(requestSetCurrentPage(pageName));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderRecord);