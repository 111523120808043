import React from "react";
import {connect} from "react-redux";
import axios from "axios";
import appConfig from "../appConfig";
import ContentLoader from "react-content-loader";
import Footer from "../components/Footer";
import Navigation from "../components/Navigation";
import FeedbackMessage from "../components/FeedbackMessage";
import CTANoSearchResult from "../components/CTANoSearchResult";
import {DataReporter} from "../DataReporter";
import TextInput from "../components/inputs/TextInput";
import {requestSetBlockchainLoading, requestSetBlockchainLoadingStop} from "../store/actions/blockchain";
import CreatedRecord from "../components/myRecords/CreatedRecord";
import UIConfigLoader from "../components/UIConfigLoader";
import "../styles/pages/myrecords.scss";
import {requestSetClaimingRecord, requestSetCurrentPage} from "../store/actions/app";
import Web3 from "web3";
import * as recordManagerBuild from "../ABIs/recordManager";
import * as recordStorageBuild from "../ABIs/recordStorage";

class MyRecords extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            recordsReceived: false,
            recordsComponents: [],
            recordsRootData: [],
            recordsCIDData: [],
            gettingRecords: false,
            pendingRecords: false,
            minFilterLength: 3,
            filterByName: "",
            errorMessage: null,
            liveRecordsLastIndex: null
        }
    }

    async getPendingRecords() {
        try {
            axios
                .get(appConfig.currentConfig.backendApp.url + "/pendingRR/all",
                    {
                        headers: {
                            Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                            'Content-Type': 'application/json',
                        },
                        params: {
                            originator: this.props.walletReducer.connectedAddress
                        }
                    }
                )
                .then(res => {
                    if (res.status === 200) {
                        let components = [];
                        let rootDatas = [];

                        for (let i = 0; i < res.data.length; i += 1) {
                            if (res.data[i].status === "PENDING") {
                                rootDatas.push(res.data[i]);

                                components.push(
                                    <CreatedRecord
                                        rootRecord={res.data[i]}
                                        CIDJson={res.data[i]}
                                        pending={true}
                                        key={i + 10}
                                    />
                                );
                            }
                        }

                        this.setState({
                            recordsComponents: this.state.recordsComponents.concat(components.reverse()),
                            recordsRootData: this.state.recordsRootData.concat(rootDatas.reverse()),
                            recordsCIDData: this.state.recordsCIDData.concat(rootDatas.reverse()),
                            errorMessage: null
                        }, () => {

                            DataReporter.trackMixpanel(this.props, "Listing pending Records", {
                                category: "Display"
                            });
                        });
                    } else {
                        this.setState({
                            errorMessage: "Error getting pending Records. Please try again."
                        }, () => {
                            DataReporter.trackMixpanel(this.props,
                                "pRR not found", {category: "Error"});
                        })
                    }
                })
                .catch(err => {
                    let displayErr;

                    if (err.message === "Network Error") {
                        displayErr = "Your pending Records couldn't be loaded. Please try again.";
                    } else {
                        displayErr = err.message;
                    }

                    this.setState({
                        errorMessage: displayErr
                    }, () => {

                        DataReporter.trackSentry(err, {
                            extra: {additionalData: "getFromSQLDB"}
                        });
                    })
                })

        } catch (err) {
            this.setState({
                errorMessage: "Data connection error. Please try again.",
            }, () => {
                DataReporter.trackMixpanel(this.props, `Error getting pRR from backend.`,
                    {category: "Error"});
                DataReporter.trackSentry(err, {extra: {additionalData: "getPendingRecords"}});
            });
        }
    }

    async getRecordsFromBlockchain() {
        if (this.state.recordsComponents.length === 0) {
            this.props.dispatchBlockchainLoading();

            let recordManagerContract = this.props.blockchainReducer.recordManagerContract;
            let recordStorageContract = this.props.blockchainReducer.recordStorageContract;

            if (!appConfig.onDev) {
                let web3 = new Web3(appConfig.testnetPublicRPC);
                recordManagerContract = new web3.eth.Contract(recordManagerBuild.abi,
                    appConfig.currentConfig.contracts.address_RecordManager);

                recordStorageContract = new web3.eth.Contract(recordStorageBuild.abi,
                    appConfig.currentConfig.contracts.address_RecordStorage);

            }

            // Get all record's route params first.
            recordStorageContract.methods.getRecordsCreatedBySender(this.props.walletReducer.connectedAddress)
                .call({
                    from: this.props.walletReducer.connectedAddress,
                    gas: appConfig.currentConfig.blockchainGasLimit
                })
                .then((receipt) => {
                    let rootRecordPromises = [];
                    let CIDPromises = [];

                    // Filter out duplicate route params
                    let uniqueRouteParams = [];
                    for (let i = 0; i < receipt.length; i += 1) {
                        if (!uniqueRouteParams.includes(receipt[i])) uniqueRouteParams.push(receipt[i]);
                    }

                    // Add getRootRecord(routeParam) promises array
                    if (receipt.length > 0) {
                        for (let i = 0; i < uniqueRouteParams.length; i += 1) {
                            rootRecordPromises.push(recordManagerContract.methods.getRootRecord(uniqueRouteParams[i])
                                .call({
                                    from: this.props.walletReducer.connectedAddress,
                                    gas: appConfig.currentConfig.blockchainGasLimit
                                }));
                        }
                        // Execute all promises
                        Promise.all(rootRecordPromises)
                            .then(async resPromises => {
                                // Get all JSONs from CID
                                for (let i = 0; i < resPromises.length; i += 1) {
                                    CIDPromises.push(axios.get(appConfig.IPFS_ProjectURL + resPromises[i][4]))
                                }

                                Promise.all(CIDPromises)
                                    .then(async CIDPromises => {
                                        let resItems = [];

                                        for (let i = 0; i < resPromises.length; i += 1) {
                                            if (resPromises[i][11] !== "true" && resPromises[i][12] !== "true") {
                                                resItems.push(
                                                    <CreatedRecord
                                                        rootRecord={resPromises[i]}
                                                        CIDJson={CIDPromises[i]}
                                                        key={i}
                                                    />);
                                            }
                                        }

                                        this.setState({
                                            recordsReceived: true,
                                            pendingRecords: false,
                                            recordsComponents: resItems.reverse(),
                                            recordsRootData: resPromises.reverse(),
                                            recordsCIDData: CIDPromises.reverse(),
                                            errorMessage: null,
                                            liveRecordsLastIndex: resItems.length-1
                                        }, () => {
                                            this.getPendingRecords();

                                            DataReporter.trackMixpanel(this.props, "Listing user Records",
                                                {
                                                    category: "Display",
                                                    myRecordCount: resPromises.length
                                                });

                                            this.props.dispatchBlockchainLoadingStop();
                                        });
                                    })
                                    .catch((err) => {
                                        this.setState({
                                            errorMessage: "Error while getting records (1). Please try again later."
                                        }, () => {
                                            this.props.dispatchBlockchainLoadingStop();

                                            DataReporter.trackSentry(err, {
                                                extra: {additionalData: "MyRecords: Resolve RR CIDs."}
                                            });

                                        });
                                    })
                            })
                            .catch((err) => {
                                this.setState({
                                    errorMessage: "Error while getting records (2). Please try again later."
                                }, () => {
                                    this.props.dispatchBlockchainLoadingStop();

                                    DataReporter.trackSentry(err, {
                                        extra: {additionalData: "MyRecords: Get RR data of each created."}
                                    });
                                });
                            })

                    } else {
                        this.props.dispatchBlockchainLoadingStop();
                        this.setState({
                            recordsReceived: true
                        }, () => {
                            this.props.dispatchBlockchainLoadingStop();
                            this.getPendingRecords();
                        });
                    }
                })
                .catch((err) => {
                    this.setState({
                        errorMessage: "Error while getting records. Please make sure you are on the right network (see Support)."
                    }, () => {
                        this.props.dispatchBlockchainLoadingStop();
                        this.getPendingRecords();

                        DataReporter.trackSentry(err, {
                            extra: {additionalData: "MyRecords: Get RR created by sender."}
                        });

                        DataReporter.trackMixpanel(this.props, "Error: Getting created RR", {
                            category: "Display"
                        });

                    });
                })

        }
    }

    getFilteredRecords() {
        if (this.state.filterByName.length >= this.state.minFilterLength) {
            DataReporter.trackMixpanel(this.props, "Filtering my Records", {
                category: "Display"
            });

            let s = this.state;
            let filtered = [];

            for (let i = 0; i < s.recordsCIDData.length; i += 1) {
                let currName;
                if (i > s.liveRecordsLastIndex) {
                    currName = s.recordsCIDData[i].rootRecordData.name;
                } else {
                    currName = s.recordsCIDData[i].data.name;
                }
                if (s.filterByName.length > 0 && currName.toLowerCase().includes(s.filterByName.toLowerCase())) {
                    filtered.push(
                        <CreatedRecord
                            rootRecord={this.state.recordsRootData[i]}
                            CIDJson={this.state.recordsCIDData[i]}
                            pending={i > s.liveRecordsLastIndex}
                            key={i}
                        />
                    );
                }
            }
            return filtered;
        }
        return this.state.recordsComponents;
    }

    // Events
    componentDidMount() {
        this.props.dispatchSetPage("myrecords");
        this.props.dispatchSetClaimingRecord(null);

        DataReporter.trackMixpanel(this.props, "Page view: My records", {
            pageDisplayMode: this.props.appReducer.mobileMode ? "mobile" : "desktop"
        });
    }

    onFilterChange(e) {
        this.setState({
            filterByName: e.inputValue
        });
    }

    // Renderers
    renderErrorMessage() {
        if (this.state.errorMessage !== null) {
            return <FeedbackMessage
                success={false}
                message={this.state.errorMessage}
            />;
        }
    }

    renderContentSpinner() {
        return (
            <div id={"content-spinner"}>
                <ContentLoader viewBox={"0 0 100 25"}
                               backgroundColor={"#dddbdb"}
                               foregroundColor={"#e87373"}
                               speed={1.5}>

                    <rect x={0} y={0} rx={1} ry={1} width={30} height={4}/>
                    <rect x={0} y={8} rx={1} ry={1} width={100} height={16}/>
                </ContentLoader>
            </div>
        );
    }

    renderFilter() {
        if (this.state.recordsReceived && this.state.recordsComponents) {
            return (
                <TextInput
                    id={"filter-text-input"}
                    label={"Filter by Records name"}
                    placeholder={"Record name"}
                    onChange={(e) => this.onFilterChange(e)}
                />
            );
        }
    }

    renderRecords() {
        if (this.props.walletReducer.connectedAddress !== null && this.props.blockchainReducer.web3 !== null) {
            let content = null;

            if (!this.state.recordsReceived) {
                if (!this.state.gettingRecords) {
                    this.setState({gettingRecords: true}, () => {
                        this.getRecordsFromBlockchain();
                    });
                }
                content = this.renderContentSpinner();
            } else {
                // Records received
                let recordCount = 0;
                if (this.state.recordsComponents) recordCount = this.state.recordsComponents.length;

                let records = this.getFilteredRecords();
                if ((!records || records.length === 0) && this.state.filterByName.length === 0) {
                    records = <CTANoSearchResult
                        forMobile={this.props.appReducer.mobileMode}
                    />;
                }

                content = (
                    <div>
                        <h2 id={"h2"}>
                            You own<span id={"count"}>{recordCount}</span>Ventrace
                            Record{recordCount > 1 || recordCount === 0 ? "s" : ""}
                        </h2>

                        {this.renderFilter()}
                        <div id={"records-container"}>
                            {this.renderErrorMessage()}
                            {records}
                        </div>

                    </div>
                );
            }

            let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";
            return (
                <div id={"my-records-container" + mobileSuffix}>
                    <h1>Records owned by you</h1>
                    {content}
                </div>
            );

        }
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";
        let b = this.props.blockchainReducer;
        let w = this.props.walletReducer;

        if (!w.noWalletMode) {
            if (!b.web3 || !b.contractsReady) {
                return <UIConfigLoader/>
            } else {
                document.title = `Ventrace - My Records`;

                return (
                    <div>
                        <Navigation/>
                        <div className={"content-container" + mobileSuffix}>
                            {this.renderRecords()}
                        </div>
                        <Footer
                            forMobile={this.props.appReducer.mobileMode}
                        />
                    </div>
                );
            }
        } else {
            window.open(`/`, "_self");
        }
    }
}

const mapStateToProps = (state) => {
    return {
        walletReducer: state.wallet,
        blockchainReducer: state.blockchain,
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchBlockchainLoading: () => {
            dispatch(requestSetBlockchainLoading())
        },
        dispatchBlockchainLoadingStop: () => {
            dispatch(requestSetBlockchainLoadingStop())
        },
        dispatchSetClaimingRecord: (claimingRecord) => {
            dispatch(requestSetClaimingRecord(claimingRecord));
        },
        dispatchSetPage: (pageName) => {
            dispatch(requestSetCurrentPage(pageName));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyRecords);