import React from "react";
import {connect} from "react-redux";
import appConfig from "../../appConfig";
import UserButton from "../inputs/UserButton";
import { AiFillPhone } from "react-icons/ai";
import { BsSpeedometer2 } from "react-icons/bs";
import { GoShieldCheck } from "react-icons/go";
import {DataReporter} from "../../DataReporter";
import "../../styles/components/ventracerecord_section.scss";

class RecordSection extends React.Component {

    getProductWord(capitalize=false) {
        let productWord = "product";
        if (this.props.appReducer.activePersona === appConfig.personas.watches) {
            productWord = "watch";
        }
        if (capitalize) {
            return productWord.charAt(0).toUpperCase() + productWord.slice(1);
        } else {
            return productWord;
        }
    }

    onCreateClick() {
        DataReporter.trackMixpanel(this.props, "Record section: Order",
            { category: "Interaction" });

        window.open("/order?t=rs", "_self");
    }

    // Renderers
    renderLightsAnim() {
        if (appConfig.playVideos) {
            let poster = process.env.PUBLIC_URL + '/assets/video/VR_lights.jpg';
            let src = process.env.PUBLIC_URL + '/assets/video/VR_lights.mp4';

            if (this.props.forMobile) {
                poster = process.env.PUBLIC_URL + '/assets/video/VR_lights_mobile.jpg';
                src = process.env.PUBLIC_URL + '/assets/video/VR_lights_mobile.mp4';
            }
            return (
                <video
                    id={"lights-anim"}
                    poster={poster}
                    autoPlay preload muted loop playsInline>
                    <source
                        src={src}
                        type="video/mp4"
                    />
                </video>
            );
        } else {
            return (
                <img id={"lights-anim"}
                     src={process.env.PUBLIC_URL + '/assets/video/VR_lights.jpg'}
                />
            );
        }
    }

    renderHeadline() {
        return (
            <p id={"headline"}>
                The new&nbsp;
                <span className={"hl"}>era</span>&nbsp;
                of {this.getProductWord()}
                &nbsp;&nbsp;
                <span className={"hl"}>
                    ownership
                </span>
            </p>
        );
    }

    renderBulletPoints() {
        return (
            <div id={"bp-container"}>
                <div className={"bp-item"}>
                    <div className={"bullet"} />
                    <p className={"bp"}>
                        Get invaluable insights about the history and condition of your {this.getProductWord()}*,
                        which can help you to discover the heritage of your watch, while you prevent damages.
                    </p>
                </div>

                <div id={"updates-context-container"}>
                    <div id={"update-types-container"}>
                        <p id={"label"}>
                            Your Record can automatically receive verified updates from official Ventrace providers*.
                            These updates are about your watch and will be about:
                        </p>
                        <div className={"inline"}>
                            <p className={"ut"}>Health & Condition</p>
                            <p className={"ut"}>History and Trading</p>
                            <p className={"ut"}>Investment Tracking</p>
                        </div>
                    </div>
                </div>

                <div className={"bp-item"} id={"bp2"}>
                    <div className={"bullet"} />
                    <p className={"bp"}>
                        In case of&nbsp;
                        <span className={"hl"}>loss</span>,
                        you can activate the Recovery System to find your lost {this.getProductWord()}. Ventrace
                        will check the global activity on its platform until your product makes an appearance.
                    </p>
                </div>

                <div className={"bp-item"}>
                    <div className={"bullet"} />
                    <p className={"bp"}>
                        You can add additional details to further enhance the value of your {this.getProductWord()}
                        on the secondary market.
                    </p>
                </div>
            </div>
        );
    }

    renderFootnotes() {
        return (
            <div id={"footnotes"}>
                <p><sup>*</sup>Only deliverable if Ventrace receives relevant data from providers.</p>
                <p><sup>**</sup>Ventrace can only detect activity on it's own platform.</p>
            </div>
        );
    }

    renderRecordPresentation() {
        return (
            <div id={"record-presentation"}>
                <div id={"img-container"}>
                    <img id={"record-img"}
                         src={process.env.PUBLIC_URL + '/ventrace_record/VentraceRecord_R02_Frontal.png'} alt={"recordFrontal"}
                    />
                </div>

                <p id={"name"}>Official Ventrace Record</p>
                <p id={"cat"}>Secure digital object</p>
            </div>
        );
    }

    renderPriceBox() {
        let price = "$" + this.props.appReducer.VRprices.standard.toFixed(2);

        if (this.props.appReducer.userRegionInEurope) {
            price = this.props.appReducer.VRprices.standard.toFixed(2) + "€";
        }

        return (
            <div id={"price-box"}>
                <div id={"price"}>
                    {price}
                </div>

                <div id={"guarantees"}>
                    <p className={"guarantee"}>
                        <GoShieldCheck id={"shield-icon"} />
                        30 days full money-back guarantee
                    </p>

                    <p className={"guarantee"}>
                        <BsSpeedometer2 id={"speed-icon"} />
                        Fast order processing
                    </p>
                </div>
            </div>
        );
    }

    renderPaymentMethods() {
        return (
            <div id={"payment-methods"}>
                <img id={"cc"}
                     src={process.env.PUBLIC_URL + '/payment/ccLogos.png'} alt={"cc"}
                />
                <img id={"paypal"}
                     src={process.env.PUBLIC_URL + '/payment/paypalLogo.png'} alt={"paypal"}
                />
            </div>
        );
    }

    renderSupportInfos() {
        let phone = this.props.appReducer.supportPhoneNrs.USA;
        if (this.props.appReducer.userRegionInEurope) {
            phone = this.props.appReducer.supportPhoneNrs.Europe;
        }

        return (
            <div id={"support-infos-container"}>
                <p id={"hotline"}>
                    Any questions? Call <AiFillPhone id={"icon"} /> {phone}
                </p>
            </div>
        );
    }

    render() {
        let mobileSuffix = this.props.forMobile ? "-mobile" : "";

        return (
          <div id={"vr-section-cotainer" + mobileSuffix}>
              {this.renderLightsAnim()}
              <div id={"content"}>
                  {this.renderHeadline()}

                  <div id={"descr"}>
                      Automatically protect and preserve the quality and condition of your {this.getProductWord()},<br/>
                      while you're saving money and time.
                  </div>

                  <div id={"sub-content"}>
                      <div id={"l"}>
                          {this.renderBulletPoints()}
                          {this.renderFootnotes()}
                      </div>

                      <div id={"r"}>
                          {this.renderRecordPresentation()}
                          {this.renderPriceBox()}

                          <UserButton
                              forMobile={this.props.forMobile}
                              id={"order-button"}
                              value={"Order now"}
                              onClick={() => this.onCreateClick()}
                          />

                          {this.renderPaymentMethods()}
                          {this.renderSupportInfos()}
                      </div>
                  </div>
              </div>
          </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        appReducer: state.app
    }
}
const mapDispatchToProps = dispatch => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordSection);