module.exports = {
    "_format": "hh-sol-artifact-1",
    "contractName": "VTR_Bonus",
    "sourceName": "contracts/VTR_Bonus.sol",
    "abi": [
        {
            "inputs": [],
            "stateMutability": "nonpayable",
            "type": "constructor"
        },
        {
            "inputs": [],
            "name": "getAddExtensionBonus",
            "outputs": [
                {
                    "internalType": "uint64",
                    "name": "",
                    "type": "uint64"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "getAddNewRecordBonus",
            "outputs": [
                {
                    "internalType": "uint64",
                    "name": "",
                    "type": "uint64"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "t1",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "t2",
                    "type": "uint256"
                }
            ],
            "name": "isBetweenTimestamps",
            "outputs": [
                {
                    "internalType": "bool",
                    "name": "",
                    "type": "bool"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }
    ],
    "bytecode": "0x608060405234801561001057600080fd5b506363fe95806000556364f12980600155636518b680600255610110806100386000396000f3fe6080604052348015600f57600080fd5b5060043610603c5760003560e01c806389d74362146041578063d0e7197c146041578063eb562461146065575b600080fd5b60476083565b60405167ffffffffffffffff90911681526020015b60405180910390f35b6074607036600460b9565b60a1565b6040519015158152602001605c565b6000609160005460015460a1565b15609b5750600190565b50600090565b600082421015801560b25750814211155b9392505050565b6000806040838503121560cb57600080fd5b5050803592602090910135915056fea2646970667358221220e29b8f96141880690a84c284dbfd5da914a5b8a2748c175be86d8f12efe57d1764736f6c63430008110033",
    "deployedBytecode": "0x6080604052348015600f57600080fd5b5060043610603c5760003560e01c806389d74362146041578063d0e7197c146041578063eb562461146065575b600080fd5b60476083565b60405167ffffffffffffffff90911681526020015b60405180910390f35b6074607036600460b9565b60a1565b6040519015158152602001605c565b6000609160005460015460a1565b15609b5750600190565b50600090565b600082421015801560b25750814211155b9392505050565b6000806040838503121560cb57600080fd5b5050803592602090910135915056fea2646970667358221220e29b8f96141880690a84c284dbfd5da914a5b8a2748c175be86d8f12efe57d1764736f6c63430008110033",
    "linkReferences": {},
    "deployedLinkReferences": {}
}

