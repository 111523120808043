import React, {useEffect, useState} from 'react';
import appConfig from "../appConfig";
import UserButton from "./inputs/UserButton";
import {VscDebugDisconnect} from "react-icons/vsc";
import {useSelector, useDispatch} from 'react-redux'
import {useWeb3Modal} from "@web3modal/wagmi/react";
import {useAccount} from "wagmi";
import {requestSetWalletAddress} from "../store/actions/wallet";
import Mixpanel from "mixpanel-browser";
import Web3 from "web3";
import Logger from "js-logger";
import * as Sentry from "@sentry/react";
import {DataReporter} from "../DataReporter";

const CheckoutConnectButton = () => {
    // States
    const [walletIsConnected, setWalletIsConnected] = useState(false);

    // Redux
    const walletReducer = useSelector(state => state.wallet);
    const appReducer = useSelector(state => state.app);

    const dispatch = useDispatch()
    const dispatchSetWalletAddress = (address) => {
        dispatch(requestSetWalletAddress(address))
    }

    // Effects
    const {open} = useWeb3Modal();
    const {address} = useAccount();

    useEffect(() => {
        if (walletIsConnected && address && !walletReducer.connectedAddress) {
            dispatchSetWalletAddress(address);
            setWalletIsConnected(true); // value was 'false'
        }
    });

    const onConnectWalletClick = async () => {
        await open();
        setWalletIsConnected(true);

        if (appConfig.currentConfig.trackAnalytics) {
            Mixpanel.track("Checkout: Wallet connected", {
                category: "Interaction"
            });
        }

        let web3 = new Web3(window.ethereum);
        web3.eth.net.getId().then((networkId) => {
            if (!appConfig.onDev) {
                //dispatchSetOnRightNetwork(networkId === customWagmiChains.getInfuraAmoy().id);
            }
        }).catch((error) => {
            DataReporter.trackSentry(error, {
                extra: {additionalData: "UIConfigLoader: Failed to get network ID."}});
        })
    }

    return (
        <div>
            <UserButton
                forMobile={appReducer.mobileMode}
                id={"connect-wallet-button"}
                value={"Connect crypto wallet"}
                icon={<VscDebugDisconnect className={"text-icon"}/>}
                onClick={() => onConnectWalletClick()}
            />
        </div>
    );
}

export default CheckoutConnectButton;