import React from "react";
import {connect} from "react-redux";
import {isMobile} from 'react-device-detect';
import {Link} from "react-router-dom";
import {IoMdAdd} from "react-icons/io";
import {RiStackLine} from "react-icons/ri";
import {AiOutlineSearch, AiFillQuestionCircle} from "react-icons/ai";
import {MdOutlineContactSupport} from "react-icons/md";
import {GiHamburgerMenu} from "react-icons/gi";
import {TbWorld} from "react-icons/tb";
import Logo from "./Logo";
import WalletControls from "./WalletControls";
import {requestSetMobileMode, requestSetOrderData} from "../store/actions/app";
import "../styles/navigation.scss";
import {DataReporter} from "../DataReporter";

class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMobileSideMenu: false,
            showingWalletInfo: false,
        }
        this.wrapperRef = null; // Ref to rendered DOM
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        window.addEventListener('resize', this.handleResize);

        if (isMobile || navigator.userAgent.toLowerCase().includes("metamaskmobile")) {
            this.props.dispatchSetMobileMode(true);
            console.log("Setting mobile mode");
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    // Events
    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    handleClickOutside = (event) => {
        if (this.wrapperRef
            && !this.wrapperRef.contains(event.target)
            && this.state.showMobileSideMenu) {
            this.toggleMobileSideMenu();
        }
    };

    handleResize() {
        if (isMobile || navigator.userAgent.toLowerCase().includes("metamaskmobile")) {
            this.props.dispatchSetMobileMode(true);
        } else {
            if (window.innerWidth < this.props.appReducer.switchMobileWidth) {
                if (!this.props.appReducer.mobileMode) {
                    this.props.dispatchSetMobileMode(true);
                }
            } else {
                if (this.props.appReducer.mobileMode) {
                    this.props.dispatchSetMobileMode(false);
                }
            }
        }
    }

    toggleMobileSideMenu() {
        this.setState({
            showMobileSideMenu: !this.state.showMobileSideMenu
        }, () => {
            DataReporter.trackMixpanel(this.props,
                this.state.showMobileSideMenu ? "Open mobile SideMenu" : "Close mobile SideMenu",
                {category: "Navigation"});
        });
    }

    walletInfoToggleCallback(set) {
        this.setState({
            showingWalletInfo: set
        });
    }

    orderOnClick() {
        this.props.dispatchSetOrderData(null);
    }

    onNavToSearch() {
        if (this.props.appReducer.currentPage === "search") {
            this.setState({showMobileSideMenu: false}, () => {
                this.props.onClickSearch();
            });
        }
    }

    onQuestionWalletClick() {

    }

    // Renderers
    renderDesktopNav(addNewLink, myRecordsLink) {
        let noWalletSuffix = "";
        if (!addNewLink && !myRecordsLink) noWalletSuffix = "-no-wallet";

        return (
            <div id={"nav-container" + noWalletSuffix}>
                <div id={"nav-content"}>
                    <Logo />
                    <div id={"links-container"}>
                        <Link
                            className="link"
                            to={"/search"}
                            id={"nav-landing"}
                            onClick={() => this.onNavToSearch()}
                        >
                            <p><AiOutlineSearch className={"nav-icon"}/>Search</p>
                        </Link>

                        {addNewLink}
                        {myRecordsLink}

                        <Link className="link" to={"/support"} id={"nav-support"}>
                            <p><MdOutlineContactSupport className={"nav-icon"}/>Help & Support</p>
                        </Link>

                        <Link className="link" to={"/about"} id={"nav-about"}>
                            <p><TbWorld className={"nav-icon"}/>About</p>
                        </Link>
                    </div>
                    <WalletControls
                        toggleShowContextMenuCallback={(set) => this.walletInfoToggleCallback(set)}
                    />
                </div>
            </div>
        );
    }

    renderMobileNav(addNewLink, myRecordsLink) {
        return (
            <div id={"nav-container-mobile"} ref={this.setWrapperRef}>
                <div id={"nav-content"}>
                    <Logo/>
                    <GiHamburgerMenu
                        id={"hamburger-icon"}
                        onClick={() => this.toggleMobileSideMenu()}
                    />
                </div>
                {this.renderMobileSideMenu(addNewLink, myRecordsLink)}
            </div>
        );
    }

    renderMobileSideMenu(addNewLink, myRecordsLink) {
        let className = "open";
        if (this.props.walletReducer.connectedAddress && !this.state.showingWalletInfo) {
            className = "closed-connected";
        } else if (!this.state.showingWalletInfo) {
            className = "closed";
        }

        if (this.state.showMobileSideMenu) {
            return (
                <div id={"side-menu"} style={{height: window.innerHeight}}>
                    <WalletControls
                        toggleShowContextMenuCallback={(set) => this.walletInfoToggleCallback(set)}
                    />

                    <div id={"links-container"} className={className}>
                        <Link
                            className="link"
                            to={"/search"}
                            id={"nav-landing"}
                            onClick={() => this.onNavToSearch()}
                        >
                            <p><AiOutlineSearch className={"nav-icon"}/>Search</p>
                        </Link>

                        {addNewLink}
                        {myRecordsLink}

                        <Link className="link" to={"/support"} id={"nav-support"}>
                            <p><MdOutlineContactSupport className={"nav-icon"}/>Help & Support</p>
                        </Link>

                        <Link className="link" to={"/about"} id={"nav-blog"}>
                            <p><TbWorld className={"nav-icon"}/>About</p>
                        </Link>

                    </div>
                </div>
            )
        }
    }

    renderNavigation() {
        let orderLink = (
            <Link className="link" to={"/order"} id={"nav-new"} onClick={() => this.orderOnClick()}>
                <p><IoMdAdd className={"nav-icon"}/>Order Record</p>
            </Link>
        );

        let myRecordsLink;
        if (this.props.walletReducer.connectedAddress) {
            myRecordsLink =
                <Link className="link" to={"/myrecords"} id={"nav-myrecords"}>
                    <p><RiStackLine className={"nav-icon"}/>My Records</p>
                </Link>;
        }

        if (!this.props.appReducer.mobileMode) {
            return this.renderDesktopNav(orderLink, myRecordsLink);
        } else {
            return this.renderMobileNav(orderLink, myRecordsLink);
        }
    }

    render() {
        return this.renderNavigation();
    }
}

const mapStateToProps = (state) => {
    return {
        walletReducer: state.wallet,
        blockchainReducer: state.blockchain,
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchSetMobileMode: (mobileModeOn) => {
            dispatch(requestSetMobileMode(mobileModeOn));
        },
        dispatchSetOrderData: (orderData) => {
            dispatch(requestSetOrderData(orderData));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);