import Mixpanel from "mixpanel-browser";
import appConfig from "./appConfig";
import * as Sentry from "@sentry/react";

export class DataReporter {
    static trackMixpanel(storageProps, eventName, context={}) {
        try {
            if (storageProps.appReducer.cookiesAccepted && appConfig.currentConfig.trackAnalytics) {
                Mixpanel.track(eventName, context);
            }
        } catch (err) {
            console.log("Error tracking event");
            this.trackSentry(err, {"Log event": eventName});
        }
    }

    static trackSentry(error, context={}) {
        try {
            if (!appConfig.onDev) {
                Sentry.captureException(error, context);
            }
        } catch (err) {
            console.log("Error tracking Sentry");
            console.log(err);
        }
    }
}
