import React from "react";
import {connect} from "react-redux";
import axios from "axios";
import appConfig from "../appConfig";
import CheckboxInput from "./inputs/CheckboxInput";
import CheckoutConnectButton from "./CheckoutConnectButton";
import {requestSetBlockchainLoading, requestSetBlockchainLoadingStop} from "../store/actions/blockchain";
import {AiFillLock, AiOutlineShopping, AiOutlineShoppingCart, AiFillPhone, AiOutlineInfoCircle} from "react-icons/ai";
import {CgRadioCheck, CgRadioChecked} from "react-icons/cg";
import {IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import {RiShieldCheckFill} from "react-icons/ri";
import {GoEyeClosed} from "react-icons/go";
import {BiMessageAltAdd} from "react-icons/bi";
import {HiOutlineGlobeAlt} from "react-icons/hi";
import TextInput from "./inputs/TextInput";
import {DataReporter} from "../DataReporter";
import UserButton from "./inputs/UserButton";
import {create} from "ipfs-http-client";
import {requestSetClaimingRecord} from "../store/actions/app";
import FeedbackMessage from "./FeedbackMessage";
import "../styles/components/recordcheckout.scss";

class RecordCheckout extends React.Component {
    constructor(props) {
        super(props);

        let appReducer = this.props.appReducer;

        let addPremiumPackage = false;
        let firstName = null;
        let lastName = null;
        let email = null;
        let selectedMethod = null;
        let sendNewsletter = false;

        if (appReducer.orderData) {
            addPremiumPackage = appReducer.orderData.premiumPackageSelected;
            firstName = appReducer.orderData.firstname;
            lastName = appReducer.orderData.lastname;
            email = appReducer.orderData.email;
            selectedMethod = appReducer.orderData.selectedMethod;
            sendNewsletter = appReducer.orderData.sendNewsletter;
        }

        this.state = {
            creatingOrder: false,
            addPremiumPackage: addPremiumPackage,
            minInputLength: 3,
            firstName: firstName,
            firstNameValid: true,
            lastName: lastName,
            lastNameValid: true,
            emailAddress: email,
            emailValid: true,
            updatesAccepted: sendNewsletter,
            orderErrorMsg: null,
            orderSuccessful: false,
            orderCheckoutURL: null,
            pendingRouteParam: null,
            selectedPaymentMethod: selectedMethod,
            selectedCurrency: this.props.appReducer.userRegionInEurope ? "EUR" : "USD",
            checkoutExpanded: false,
            failedValidationCheck: false,
            expandWhyWallet: false,
            expandDirectWallet: false
        }
    }

    getProductWord(capitalize) {
        let productWord = "product";
        if (this.props.appReducer.activePersona === appConfig.personas.watches) {
            productWord = "watch";
        }
        if (capitalize) {
            return productWord.charAt(0).toUpperCase() + productWord.slice(1);
        } else {
            return productWord;
        }
    }

    async getPendingRouteParam() {
        function randomReplacement() {
            return Math.floor(Math.random() * 9) + 1; // Generates a number between 1 and 9
        }

        let combined = this.props.rrData.name + this.props.rrData.description;

        let encoder = new TextEncoder();
        let data = encoder.encode(combined);

        let hashBuffer = await crypto.subtle.digest('SHA-256', data);

        let hashArray = Array.from(new Uint8Array(hashBuffer));
        let base64Hash = btoa(hashArray.map(byte => String.fromCharCode(byte)).join(''));

        base64Hash = base64Hash
            .replace(/\+/g, randomReplacement())
            .replace(/\//g, randomReplacement())
            .replace(/=/g, randomReplacement());
        base64Hash = base64Hash.substring(0, 21);

        const randomNumber = Math.floor(Math.random() * 10000).toString().padStart(4, '0');
        return (base64Hash + randomNumber).toUpperCase();
    }

    getPersonalData() {
        return {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.emailAddress,
            updatesAccepted: this.state.updatesAccepted,
            selectedCurrency: this.state.selectedCurrency,
            persona: this.props.appReducer.activePersona
        };
    }

    getShoppingBasket() {
        return {
            addPremiumPackage: this.state.addPremiumPackage,
            paymentMethod: this.state.selectedPaymentMethod
        }
    }

    handleError(err, requestLevel) {
        let displayErr;
        if (err.message === "Network Error") {
            displayErr = `There was a problem (error code: ${requestLevel}). Please try again.`;
        } else {
            displayErr = err.message;
        }

        this.setState({
            orderErrorMsg: displayErr,
            creatingOrder: false,
        }, () => {
            let reason = `Checkout: Backend network error (${requestLevel})`;
            DataReporter.trackMixpanel(this.props, reason, {category: "Error"});
            DataReporter.trackSentry(err, {extra: {additionalData: reason}});
        });
    }

    createOrder() {
        this.props.saveOrderData({
            premiumPackageSelected: this.state.addPremiumPackage,
            firstname: this.state.firstName,
            lastname: this.state.lastName,
            email: this.state.emailAddress,
            selectedMethod: this.state.selectedPaymentMethod,
            sendNewsletter: this.state.updatesAccepted
        });

        if (this.props.appReducer.claimingRecord) {
            this.claimRecordOrder();
        } else {
            this.createCustomOrder();
        }
    }

    claimRecordOrder() {
        if (!this.state.creatingOrder && this.props.recordFormIsValid && this.validateCheckout()) {
            // Create pendingRR first
            this.setState({
                creatingOrder: true,
                orderErrorMsg: null
            }, async () => {

                // Upload images
                let auth = "Basic " + Buffer.from(appConfig.IPFS_ProjectID + ":" + appConfig.IPFS_Key).toString("base64");
                let client = create({url: appConfig.IPFS_Endpoint, headers: {authorization: auth}});

                let imageUploadPaths = [];
                for await (const result of client.addAll(this.props.imageFiles)) {
                    imageUploadPaths.push(result.path);
                }

                let rrData = this.props.rrData;
                rrData.imageCIDs = imageUploadPaths;
                rrData.isPremium = this.state.addPremiumPackage;
                rrData.providerName = this.props.appReducer.claimingRecord.rootRecordData.providerName;
                rrData.providerID = this.props.appReducer.claimingRecord.rootRecordData.providerID;

                // First create pending RR
                axios
                    .put(appConfig.currentConfig.backendApp.url + "/pendingRR",
                        {
                            id: this.props.appReducer.claimingRecord.id,
                            originator: this.props.walletReducer.connectedAddress,
                            rootRecordData: rrData,
                            isClaimable: false
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                                'Content-Type': 'application/json',
                            },
                        })
                    .then(res => {
                        if (res.data.success) {

                            let claimingRecord = this.props.appReducer.claimingRecord;
                            try {
                                axios
                                    .post(appConfig.currentConfig.backendApp.url + "/order",
                                        {
                                            walletAddress: this.props.walletReducer.connectedAddress,
                                            personalData: this.getPersonalData(),
                                            shoppingBasket: this.getShoppingBasket(),
                                            pendingRootRecordID: claimingRecord.id,
                                            pendingRootRecordProviderID: claimingRecord.rootRecordData.providerID
                                        },
                                        {
                                            headers: {
                                                Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                                                'Content-Type': 'application/json',
                                            },
                                        })
                                    .then(res => {
                                        if (res.data.success) {
                                            this.onOrderSuccess(claimingRecord.pendingRouteParam);
                                            this.props.dispatchSetClaimingRecord(null);
                                        } else {
                                            this.handleError({message: "Order creation not succesful"}, 21);
                                        }
                                    })
                                    .catch(err => {
                                        this.handleError(err, 2);
                                    })
                            } catch (err) {

                            }


                        } else {
                            this.setState({
                                orderErrorMsg: "Error during Record preparation. Please try again.",
                                creatingOrder: false,
                            });
                        }
                    })
                    .catch(err => {
                        this.handleError(err, 1);
                        this.setState({
                            orderErrorMsg: "Error sending order. Please try again.",
                            creatingOrder: false,
                        });
                    })
            });
        } else if (!this.props.recordFormIsValid) {
            this.props.validateRecordForm();
        }
    }

    createCustomOrder() {
        if (!this.state.creatingOrder && this.props.recordFormIsValid && this.validateCheckout()) {
            // Create pendingRR first
            this.setState({
                creatingOrder: true,
                orderErrorMsg: null,
                failedValidationCheck: false
            }, async () => {

                let pendingRP = await this.getPendingRouteParam();

                // Upload images
                let auth = "Basic " + Buffer.from(appConfig.IPFS_ProjectID + ":" + appConfig.IPFS_Key).toString("base64");
                let client = create({url: appConfig.IPFS_Endpoint, headers: {authorization: auth}});

                let imageUploadPaths = [];
                imageUploadPaths = await Promise.all(
                    this.props.imageFiles.map(async file => {
                        const result = await client.add(file);
                        return result.path;
                    })
                );

                let rrData = this.props.rrData;
                rrData.imageCIDs = imageUploadPaths;
                rrData.isPremium = this.state.addPremiumPackage;

                // First create pending RR
                axios
                    .post(appConfig.currentConfig.backendApp.url + "/pendingRR", {
                        originator: this.props.walletReducer.connectedAddress,
                        rootRecordData: rrData,
                        pendingRouteParam: pendingRP
                    }, {
                        headers: {
                            Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                            'Content-Type': 'application/json',
                        },
                    })
                    .then(res => {
                        if (res.data.success) {
                            let pendingRRid = res.data.id;

                            // Then create order
                            try {
                                axios
                                    .post(appConfig.currentConfig.backendApp.url + "/order", {
                                        walletAddress: this.props.walletReducer.connectedAddress,
                                        personalData: this.getPersonalData(),
                                        shoppingBasket: this.getShoppingBasket(),
                                        pendingRootRecordID: pendingRRid
                                    }, {
                                        headers: {
                                            Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.standard}`,
                                            'Content-Type': 'application/json',
                                        },
                                    })
                                    .then(res => {
                                        if (res.data.success) {
                                            this.onOrderCreated(pendingRP, res.data.checkoutURL);
                                        } else {
                                            this.handleError({message: "Order creation not succesful"}, 21);
                                        }
                                    })
                                    .catch(err => {
                                        this.handleError(err, 2);
                                    })
                            } catch (err) {

                            }


                        } else {
                            this.setState({
                                orderErrorMsg: "Error during Record preparation. Please try again.",
                                creatingOrder: false,
                            });
                        }
                    })
                    .catch(err => {
                        this.handleError(err, 1);
                        this.setState({
                            orderErrorMsg: "Error sending order. Please try again.",
                            creatingOrder: false,
                        });
                    })
            });
        } else if (!this.props.recordFormIsValid) {
            this.setState({
                failedValidationCheck: true
            }, () => {
                this.props.validateRecordForm();
            });
        }
    }

    validateCheckout() {
        let canSave = true;
        DataReporter.trackMixpanel(this.props, "Validating Checkout", {category: "Interaction"});
        this.setState({
            firstNameValid: true,
            lastNameValid: true,
            emailValid: true
        });

        let s = this.state;

        if (s.firstName === null || s.firstName.length < s.minInputLength) {
            DataReporter.trackMixpanel(this.props, "Validation Error: First name",
                {category: "Feedback"});

            this.setState({
                firstNameValid: false
            });

            canSave = false;
        }

        if (s.lastName === null || s.lastName.length < s.minInputLength) {
            DataReporter.trackMixpanel(this.props, "Validation Error: Last name", {category: "Feedback"});
            this.setState({
                lastNameValid: false
            });

            canSave = false;
        }

        if (s.emailAddress === null || s.emailAddress.length < s.minInputLength
            || !s.emailAddress.includes("@")) {

            DataReporter.trackMixpanel(this.props, "Validation Error: Email invalid",
                {category: "Feedback"});

            this.setState({
                emailValid: false
            });

            canSave = false;
        }

        return canSave;
    }


    // Events
    onOrderCreated(pendingRP, checkoutURL) {
        this.setState({
            orderCheckoutURL: checkoutURL,
            creatingOrder: false,
        }, () => {
            DataReporter.trackMixpanel(this.props, "Checkout: Order created", {
                category: "Order"
            });

            setTimeout(() => {
                window.open(checkoutURL, "_self");
            }, 1500);
        });
    }

    onOrderSuccess(pendingRP, checkoutURL) {
        this.setState({
            orderSuccessful: true,
            creatingOrder: false,
        }, () => {
            if (this.props.onCheckoutSuccess) {
                this.props.onCheckoutSuccess(pendingRP);
            }
        });
    }

    onAddPremiumPackage(isChecked) {
        this.setState({
            addPremiumPackage: !this.state.addPremiumPackage
        }, () => {
            DataReporter.trackMixpanel(this.props,
                isChecked ? "Checkout: Add premium package" : "Checkout: Remove premium package", {
                category: "Interaction"
            });
        })
    }

    firstNameOnChange(e) {
        if (e.inputValue.length >= this.state.minInputLength) {
            this.setState({firstName: e.inputValue});
        }
    }

    firstNameOnPaste(e) {
        this.firstNameOnChange({inputValue: e.clipboardData.getData("text")});
    }

    lastNameOnChange(e) {
        if (e.inputValue.length >= this.state.minInputLength) {
            this.setState({lastName: e.inputValue});
        }
    }

    lastNameOnPaste(e) {
        this.lastNameOnChange({inputValue: e.clipboardData.getData("text")});
    }

    emailOnChange(e) {
        if (e.inputValue.length >= this.state.minInputLength) {
            this.setState({emailAddress: e.inputValue});
        }
    }

    emailOnPaste(e) {
        this.emailOnChange({inputValue: e.clipboardData.getData("text")});
    }

    onGetUpdatesCB(isChecked) {
        this.setState({
            updatesAccepted: !this.state.updatesAccepted
        }, () => {
            DataReporter.trackMixpanel(this.props,
                isChecked ? "Checkout: Updates accepted" : "Checkout: Updates not accepted", {
                category: "Interaction"
            });
        })
    }

    onSelectPaymentMethod(selection) {
        this.setState({
            selectedPaymentMethod: selection
        }, () => {
            DataReporter.trackMixpanel(this.props, `Checkout: Payment selected: ${selection}`,
                { category: "Interaction" });
        });
    }

    onGettingStartedLink() {
        DataReporter.trackMixpanel(this.props, "Checkout: Click getting started", {
            category: "Interaction"
        });

        setTimeout(() => {
            window.open("/gettingstarted", "_blank")},
            1500);
    }

    onPickCurrency(c) {
        this.setState({
            selectedCurrency: c
        }, () => {
            DataReporter.trackMixpanel(this.props, `Changed currency to ${c}`, {
                category: "Error"
            });
        });
    }

    onExpandCheckout() {
        this.setState({
            checkoutExpanded: true
        }, () => {
            DataReporter.trackMixpanel(this.props, "Checkout: Payment Options", {
                category: "Interaction"
            });

            this.props.saveOrderData({
                premiumPackageSelected: this.state.addPremiumPackage,
                firstname: this.state.firstName,
                lastname: this.state.lastName,
                email: this.state.emailAddress,
                selectedMethod: this.state.selectedPaymentMethod,
                sendNewsletter: this.state.updatesAccepted
            });
        });
    }

    onExpandWhyWallet() {
        let curr = this.state.expandWhyWallet;
        this.setState({
            expandWhyWallet: !curr
        }, () => {
            DataReporter.trackMixpanel(this.props, "Checkout: Why Wallet", {
                category: "Interaction"
            });
        });
    }

    onExpandDirectWallet() {
        let curr = this.state.expandDirectWallet;
        this.setState({
            expandDirectWallet: !curr
        }, () => {
            DataReporter.trackMixpanel(this.props, "Checkout: Wallet recommendations", {
                category: "Interaction"
            });
        });
    }

    // Renderers
    renderCurrencyPicker() {
        let c = this.state.selectedCurrency;
        return (
            <div id={"currency-picker"}>
                <p
                    id={"usd"}
                    className={c === "USD" ? "picked" : null}
                    onClick={() => this.onPickCurrency("USD")}
                >
                    USD
                </p>

                <p
                    id={"eur"}
                    className={c === "EUR" ? "picked" : null}
                    onClick={() => this.onPickCurrency("EUR")}
                >
                    EUR
                </p>
            </div>
        );
    }

    renderIncludedItem(icon, title, descr, customComponent = null) {
        let descrComp;
        if (descr) {
            descrComp = (<p id={"description"}>{descr}</p>);
        }

        return (
            <div className={"included-item"}>
                <div id={"h"}>
                    {icon}
                    <p id={"title"}>{title}</p>
                </div>
                {descrComp}
                {customComponent ? customComponent : null}
            </div>
        );
    }

    renderWhatsIncluded() {
        return (
            <div id={"whats-included-container"}>
                <p id={"whats-included-h"}>
                    What's included
                </p>

                {this.renderIncludedItem(
                    <RiShieldCheckFill className={"icon"} id={"guarantee-icon"}/>,
                    "Instant money-back guarantee",
                    "You can get your money back easy and fast within 30 days of ordering."
                )}

                {this.renderIncludedItem(
                    <BiMessageAltAdd id={"auto-updates-icon"}/>,
                    `Automatic ${this.getProductWord()} updates`,
                    null,
                    (
                        <p id={"description"}>
                            Your Record can receive past and future details about the history and condition
                            of your {this.getProductWord()}.
                            <sup className={"note"}>*</sup>
                        </p>
                    )
                )}

                {this.renderIncludedItem(
                    <HiOutlineGlobeAlt className={"icon"} id={"recovery-system-icon"}/>,
                    "Global recovery system",
                    null,
                    (
                        <p id={"description"}>
                            When you lose your {this.getProductWord()}, your Record can inform you
                            when your {this.getProductWord()} get's detected with new activity.<sup className={"note"}>**</sup>
                        </p>
                    )
                )}

            </div>
        );
    }

    renderPremiumFeatures() {
        let price = null;
        if (this.state.selectedCurrency === "EUR") {
            price = `${this.props.appReducer.VRprices.premium}€`;
        } else {
            price = `$${this.props.appReducer.VRprices.premium}`;
        }

        return (
            <div id={"premium-features-container"}>
                <div id={"header-container"}>
                    <p id={"premium-features-h"}>
                        Premium package
                    </p>
                    <p id={"price-tag"}>
                        {price}
                    </p>
                </div>

                {this.renderIncludedItem(
                    <GoEyeClosed id={"privacy-icon"}/>,
                    "Private mode",
                    `Receive details about your ${this.getProductWord()} just for yourself, without publicly sharing your Record.`
                )}

                {this.renderIncludedItem(
                    <AiFillLock id={"claim-protection-icon"}/>,
                    "Claim protection",
                    null,
                    (
                        <p id={"description"}>
                            No one can replicate your Record and assert ownership of the represented {this.getProductWord()}.
                        </p>
                    )
                )}

                <CheckboxInput
                    id={"add-premium-cb"}
                    label={"Add the premium package to my Ventrace Record."}
                    onChange={(e) => this.onAddPremiumPackage(e)}
                    checked={this.state.addPremiumPackage}
                />
            </div>
        );
    }

    renderSummary() {
        let currency = this.state.selectedCurrency;
        let totalAmount = this.props.appReducer.VRprices.standard;

        let premItem = null;
        if (this.state.addPremiumPackage) {
            premItem = (
                <div className={"order-item"} id={"prem-item"}>
                    <div className={"inline"}>
                        <p id={"count"}>1x</p>
                        <p id={"item-name"}>Additional premium package</p>
                        <p id={"item-amount"}>
                            {currency === "USD" ? "$" : ""}{this.props.appReducer.VRprices.premium}{currency === "EUR" ? "€" : ""}
                        </p>
                    </div>
                </div>
            );
            totalAmount += this.props.appReducer.VRprices.premium;
        }

        return (
            <div id={"summary-container"}>
                <p id={"summary-h"}>
                    <AiOutlineShoppingCart id={"shopping-cart-icon"}/>
                    Your order summary
                    {this.renderCurrencyPicker()}
                </p>
                <p id={"summary-descr"}>
                    Your order contains the items shown below.
                </p>

                <div id={"order-items-container"}>
                    <div className={"order-item"}>
                        <div className={"inline"}>
                            <p id={"count"}>1x</p>
                            <p id={"item-name"}>Ventrace Record</p>
                            <p id={"item-amount"}>
                                {currency === "USD" ? "$" : ""}
                                {this.props.appReducer.VRprices.standard.toFixed(2)}
                                {currency === "EUR" ? "€" : ""}
                            </p>
                        </div>
                    </div>
                    {premItem}

                    <div id={"total-amount-container"}>
                        <p id={"label"}>Total amount:</p>
                        <p id={"amount"}>
                            {currency === "USD" ? "$" : ""}
                            {totalAmount.toFixed(2)}
                            {currency === "EUR" ? "€" : ""}
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    renderErrorMessage() {
        if (this.state.orderErrorMsg || this.props.errorMessage) {
            let msg = this.state.orderErrorMsg ? this.state.orderErrorMsg : this.props.errorMessage;
            return (
                <div id={"order-error-container"}>
                    <p id={"msg"}>
                        {msg}
                    </p>
                </div>
            )
        }
    }

    renderPaymentMethods() {
        let selectCC = "creditcard";
        let selectPayPal = "paypal"

        let unsetIcon = <CgRadioCheck className={"radio-unset"}/>;
        let setIcon = <CgRadioChecked className={"radio-set"}/>;
        let s = this.state;

        return (
            <div id={"payment-methods"}>
                <p id={"label"}>
                    Your payment method
                </p>

                <div id={"cc-method"} className={"single-method"} onClick={() => this.onSelectPaymentMethod(selectCC)}>
                    {s.selectedPaymentMethod === selectCC ? setIcon : unsetIcon}
                    <img id={"cc-logo"}
                         src={process.env.PUBLIC_URL + "/payment/ccLogos.png"} alt={"credit card logos"}
                    />
                    <p id={"cc-name"} className={"method-name"}>
                        Credit card
                    </p>
                </div>

                <div id={"paypal-method"} className={"single-method"}
                     onClick={() => this.onSelectPaymentMethod(selectPayPal)}>
                    {s.selectedPaymentMethod === selectPayPal ? setIcon : unsetIcon}
                    <img id={"paypal-logo"}
                         src={process.env.PUBLIC_URL + "/payment/paypalLogo.png"} alt={"paypal logo"}
                    />
                    <p id={"paypal-name"} className={"method-name"}>
                        PayPal
                    </p>
                </div>

            </div>
        );
    }

    renderSubmitForm() {
        let spinner;
        if (this.state.creatingOrder || this.props.redirectChecking) {
            spinner = (
                <div id={"spinner-container"}>
                    <img id={"spinner"} src={process.env.PUBLIC_URL + '/spinner.gif'} alt={"spinner"}/>
                    <p id={"psp-info"}>
                        <AiOutlineInfoCircle id={"icon"} />
                        Loading the payment page. You will be redirected here afterwards.
                    </p>
                </div>
            );
        }

        if (this.props.walletReducer.connectedAddress) {

            let validationError;
            if (this.state.failedValidationCheck) {
                validationError = (
                    <FeedbackMessage
                        success={false}
                        message={"Please check for errors in the form above."}
                    />
                );
            }

            return (
                <div id={"order-button-container"} ref={this.props.orderFormRef}>
                    <UserButton
                        forMobile={this.props.appReducer.mobileMode}
                        id={"order-button"}
                        value={"Order securely"}
                        icon={<AiOutlineShopping className={"text-icon"}/>}
                        disabled={this.state.creatingOrder || this.props.redirectChecking}
                        onClick={() => this.createOrder()}
                    />
                    {validationError}
                    <p id={"by-ordering-accept"}>
                        By ordering you accept the&nbsp;
                        <a href={"/terms"} target={"_blank"}>Ventrace Terms and Conditions</a>.
                    </p>
                    {spinner}
                    {this.renderErrorMessage()}
                </div>
            );
        } else {
            return (
                <div id={"connect-wallet-container"}>
                    <CheckoutConnectButton />
                    <p id={"why-text"}>
                        Ventrace will link your Record exclusively to you via your crypto wallet.
                        This is done by safely reading your public wallet address.
                    </p>

                    {this.renderWalletExplainers()}

                    <p id={"getting-started-link"} onClick={() => this.onGettingStartedLink()}>
                        Tutorial: Setting up a crypto wallet for Ventrace
                    </p>
                </div>
            );
        }
    }

    renderPaymentForm() {
        if (this.state.checkoutExpanded || this.props.redirectChecking || this.props.errorMessage) {
            return (
                <div id={"payment-form"}>
                    <p id={"payment-form-h"}>Payment details</p>
                    <p id={"payment-descr"}>Enter your contact information and choose how to pay.</p>

                    <div id={"name-container"}>
                        <TextInput
                            inputID={"first-name-input"}
                            label={"Your first name*"}
                            placeholder={"First name"}
                            value={this.state.firstName}
                            onChange={(e) => this.firstNameOnChange(e)}
                            onPaste={(e) => this.firstNameOnPaste(e)}
                            validationError={!this.state.firstNameValid}
                            validationMsg={"Too short"}
                            maxLen={50}
                        />
                        <TextInput
                            inputID={"last-name-input"}
                            label={"Your last name*"}
                            placeholder={"Last name"}
                            value={this.state.lastName}
                            onChange={(e) => this.lastNameOnChange(e)}
                            onPaste={(e) => this.lastNameOnPaste(e)}
                            validationError={!this.state.lastNameValid}
                            validationMsg={"Too short"}
                            maxLen={50}
                        />
                    </div>

                    <TextInput
                        id={"email-input-container"}
                        inputID={"email-input"}
                        label={"Your email address*"}
                        placeholder={"Your email address"}
                        value={this.state.email}
                        onChange={(e) => this.emailOnChange(e)}
                        onPaste={(e) => this.emailOnPaste(e)}
                        validationError={!this.state.emailValid}
                        validationMsg={"This email address is invalid"}
                        maxLen={50}
                    />

                    {this.renderPaymentMethods()}

                    <div id={"checkboxes"}>
                        <CheckboxInput
                            id={"get-updates-cb"}
                            label={"Receive news and updates about Ventrace and your Records."}
                            onChange={(e) => this.onGetUpdatesCB(e)}
                            checked={this.state.updatesAccepted}
                        />
                    </div>

                    {this.renderSubmitForm()}
                </div>
            );
        }
    }

    renderExpandCheckout() {
        if (!this.state.checkoutExpanded && !this.props.redirectChecking && !this.props.errorMessage) {
            return (
                <div id={"expand-container"}>
                    <UserButton
                        forMobile={this.props.appReducer.mobileMode}
                        id={"expand-button"}
                        value={"Continue to payment options"}
                        icon={<IoIosArrowDown className={"text-icon"}/>}
                        onClick={() => this.onExpandCheckout()}
                    />
                </div>
            );
        }
    }

    renderWalletExplainers() {
        let whyText;
        if (this.state.expandWhyWallet) {
            whyText = (
                <p id={"why-wallet-text"} className={"expanded-text"}>
                    Your digital certificate will be created and stored as a highly secured object, which can
                    be managed and changed only by you. The most appropiate technology which can support these
                    requirements are offered by crypto wallets.<br /><br/>

                    You can easily install crypto wallets as an app or browser extension.
                    You don't need to enter any personal data when you create one.
                </p>
            );
        }

        let directLinks;
        if (this.state.expandDirectWallet) {
            directLinks = (
                <p id={"direct-wallets-text"} className={"expanded-text"}>
                    A very popular, free and widely used crypto wallet is&nbsp;
                    <a href={"https://metamask.io/"} target={"_blank"}>MetaMask</a>. You can also check out&nbsp;
                    <a href={"https://trustwallet.com"} target={"_blank"}>TrustWallet</a>.
                    Once you've installed your wallet, please reload this page.
                </p>
            );
        }

        return (
            <div id={"wallet-explainers"}>
                <div className={"explainer"} onClick={() => this.onExpandWhyWallet()}>
                    <div className={"inline"}>
                        {this.state.expandWhyWallet ? <IoIosArrowDown className={"arrow"} />
                            : <IoIosArrowForward  className={"arrow"} />}
                        <p className={"title"}>What is a crypto wallet and why?</p>
                    </div>
                    {whyText}
                </div>

                <div className={"explainer"} onClick={() => this.onExpandDirectWallet()}>
                    <div className={"inline"}>
                        {this.state.expandWhyWallet ? <IoIosArrowDown className={"arrow"} />
                            : <IoIosArrowForward  className={"arrow"} />}
                        <p className={"title"}>Recommended free crypto wallets</p>
                    </div>
                    {directLinks}
                </div>
            </div>
        )
    }

    renderSupportHotline() {
        let phoneNr = this.props.appReducer.supportPhoneNrs.USA;
        if (this.props.appReducer.userRegionInEurope) {
            phoneNr = this.props.appReducer.supportPhoneNrs.Europe;
        }

        return (
            <div id={"support"}>
                <p id={"phone"}>
                    Any questions? Call free of charge&nbsp;<br/>
                    <span id={"phone-nr"}>
                            <AiFillPhone id={"phone-icon"}/>
                            {phoneNr}
                    </span>
                </p>
            </div>
        );
    }

    render() {
        let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

        return (
            <div id={"rr-checkout-container" + mobileSuffix}>
                <p id={"checkout-h"}>Checkout</p>

                {this.renderWhatsIncluded()}
                {this.renderPremiumFeatures()}
                {this.renderSummary()}
                {this.renderPaymentForm()}
                {this.renderExpandCheckout()}
                {this.renderSupportHotline()}

                <div id={"footnotes"}>
                    <p><sup>*</sup>Available only when information providers deliver to Ventrace.</p>
                    <p><sup>**</sup>Ventrace can only detect activity within its network.</p>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        walletReducer: state.wallet,
        blockchainReducer: state.blockchain,
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchBlockchainLoading: () => {
            dispatch(requestSetBlockchainLoading())
        },
        dispatchBlockchainLoadingStop: () => {
            dispatch(requestSetBlockchainLoadingStop())
        },
        dispatchSetClaimingRecord: (claimingRecord) => {
            dispatch(requestSetClaimingRecord(claimingRecord));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordCheckout);