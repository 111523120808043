import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import {useWeb3Modal} from "@web3modal/react";
import Web3 from "web3";
import appConfig from "../appConfig";
import localForage from "localforage";
import Logger from "js-logger";
import * as blockchainTypes from "../store/actionTypes/blockchain";
import * as recordManagerBuild from "../ABIs/recordManager";
import * as recordStorageBuild from "../ABIs/recordStorage";
import * as vtrTokenBuild from "../ABIs/vtrToken";
import * as vtrBonusBuild from "../ABIs/vtrBonus";
import * as vtrManagerBuild from "../ABIs/vtrManager";
import * as disputeBuild from "../ABIs/disputes";
import * as recordValuatorBuild from "../ABIs/recordValuator";
import * as verifiedOwnersBuild from "../ABIs/verifiedOwners";
import customWagmiChains from "../customWagmiChains";
import {requestDisconnectWallet} from "../store/actions/wallet";
import {
    requestSetContract,
    requestSetContractstReady,
    requestSetWalletReady,
    requestSetWeb3
} from "../store/actions/blockchain";
import {requestSetOnRightNetwork} from "../store/actions/app";
import "../styles/components/ui_config_loader.scss";
import {DataReporter} from "../DataReporter";

const UIConfigLoader = () => {
    // States
    const [settingWeb3, setSettingWeb3] = useState(false);

    // Redux
    const blockchainReducer = useSelector(state => state.blockchain);
    const walletReducer = useSelector(state => state.wallet);
    const appReducer = useSelector(state => state.app);

    const dispatch = useDispatch()
    const dispatchSetWeb3 = (web3) => {
        dispatch(requestSetWeb3(web3));
    }
    const dispatchSetContract = (type, contractObj) => {
        dispatch(requestSetContract(type, contractObj));
    }
    const dispatchDisconnectWallet = () => {
        dispatch(requestDisconnectWallet());
    }
    const dispatchSetContractsReady = (ready) => {
        dispatch(requestSetContractstReady(ready));
    }
    const dispatchSetWalletReady = (ready) => {
        dispatch(requestSetWalletReady(ready));
    }
    const dispatchSetOnRightNetwork = (b) => {
        dispatch(requestSetOnRightNetwork(b));
    }

    // Effects
    const {close} = useWeb3Modal();

    useEffect(() => {
        try {
            window.ethereum.on('accountsChanged', (account) => {
                accountChanged(account);
            });

            window.ethereum.on('chainChanged', (_chainId) => {
                chainChanged(_chainId);
            });
        } catch(err) {

        }

        if (!settingWeb3 && blockchainReducer.web3 === null) {
            setSettingWeb3(true);
            configureContracts();
            activateMetamask();
        }

        return () => {
            try {
                window.ethereum.removeListener("accountsChanged", accountChanged);
                window.ethereum.removeListener("chainChanged", chainChanged);
            }
            catch (err) {

            }
        };
    });

    const chainChanged = (chainID) => {
        if (!appConfig.onDev) {
            if (appConfig.useMainnet) {
                dispatchSetOnRightNetwork(chainID === customWagmiChains.getPolygonMainnet().id);
            }
            else {
                dispatchSetOnRightNetwork(chainID === customWagmiChains.getAmoy().id);
            }
        } else {
            dispatchSetOnRightNetwork(chainID === 5777);
        }
    }

    const accountChanged = (address) => {
        if (walletReducer.connectedAddress && walletReducer.connectedAddress !== address) {

            DataReporter.trackMixpanel(this.props, "Wallet address change",
                { category: "Interaction" });

            disconnectWallet();
            setTimeout(() => {
                window.open("/", "_self");
            }, 1000);
        }
    }

    const disconnectWallet = () => {
        localForage.dropInstance({
            name: "ventrace"
        }).then(function () {
            dispatchDisconnectWallet();
        }).catch(function (err) {
            DataReporter.trackSentry(err, {
                extra: {additionalData: "UIConfigLoader: localForage drop Ventrace instance."}
            });
        });
    }

    const activateMetamask = async () => {
        localForage.getItem("ventrace").then(() => {
            if (!blockchainReducer.walletReady && walletReducer.connectedAddress !== null && window.ethereum) {
                window.ethereum.request({method: 'eth_requestAccounts'})
                    .then(async res => {
                        await close();
                        dispatchSetWalletReady(true);

                        let web3 = new Web3(window.ethereum);
                        web3.eth.net.getId().then((networkId) => {
                            chainChanged(networkId);
                        }).catch((error) => {
                            DataReporter.trackSentry(error, {
                                extra: {additionalData: "UIConfigLoader: Failed to get network ID."}
                            });
                        })

                    })
                    .catch(err => {
                        if (err.message && err.message !== "User rejected the request.") {
                            DataReporter.trackSentry(err, {
                                extra: {additionalData: "UIConfigLoader: activate Metamask req accounts."}
                            });
                        }

                        dispatchSetWalletReady(false);
                        disconnectWallet();
                    })

            }
        }).catch((error) => {
            DataReporter.trackSentry(error, {
                extra: {additionalData: "UIConfigLoader: localForage get 'ventrace'."}
            });
        })
    }

    // Functions
    const configureContracts = () => {
        let hasMetaMask = false;
        try {
            if (window.ethereum.isMetaMask) hasMetaMask = true;
        }
        catch (err) {
            //DataReporter.trackSentry(err);
        }

        let web3;
        if ((appReducer.mobileMode && !appConfig.onDev) || (!appConfig.onDev && !hasMetaMask)) {
            let rpc = appConfig.useMainnet ? appConfig.mainnetPublicRPC : appConfig.testnetPublicRPC;
            web3 = new Web3(rpc);

            Logger.info("Using mobile blockchain provider.")
        }
        else {
            web3 = new Web3(window.ethereum ? window.ethereum : appConfig.ganache_nodeAddress);
        }

        if (appReducer.mobileMode && window.ethereum) {
            web3.eth.setProvider(Web3.givenProvider);
            Logger.info("Activating given provider");
        }

        dispatchSetWeb3(web3);

        dispatchSetContract(blockchainTypes.SET_RECORD_MANAGER_CONTRACT,
            new web3.eth.Contract(recordManagerBuild.abi,
                appConfig.currentConfig.contracts.address_RecordManager));

        dispatchSetContract(blockchainTypes.SET_RECORD_STORAGE_CONTRACT,
            new web3.eth.Contract(recordStorageBuild.abi,
                appConfig.currentConfig.contracts.address_RecordStorage));

        dispatchSetContract(blockchainTypes.SET_VTR_TOKEN_CONTRACT,
            new web3.eth.Contract(vtrTokenBuild.abi,
                appConfig.currentConfig.contracts.address_VTR_Token));

        dispatchSetContract(blockchainTypes.SET_VTR_BONUS_CONTRACT,
            new web3.eth.Contract(vtrBonusBuild.abi,
                appConfig.currentConfig.contracts.address_VTR_Bonus));

        dispatchSetContract(blockchainTypes.SET_VTR_MANAGER_CONTRACT,
            new web3.eth.Contract(vtrManagerBuild.abi,
                appConfig.currentConfig.contracts.address_VTR_Manager));

        dispatchSetContract(blockchainTypes.SET_DISPUTE_CONTRACT,
            new web3.eth.Contract(disputeBuild.abi,
                appConfig.currentConfig.contracts.address_Disputes));

        dispatchSetContract(blockchainTypes.SET_VALUATOR_CONTRACT,
            new web3.eth.Contract(recordValuatorBuild.abi,
                appConfig.currentConfig.contracts.address_RecordValuator));

        dispatchSetContract(blockchainTypes.SET_VERIFIED_OWNERS_CONTRACT,
            new web3.eth.Contract(verifiedOwnersBuild.abi,
                appConfig.currentConfig.contracts.address_VerifiedOwners));

        Logger.info("UIConfigLoader: Smart contracts loaded");
        setSettingWeb3(false);
        dispatchSetContractsReady(true);
    }


    return (
        <div className={"ui-config-loader-container"}>
            <img className={"logo"}
                 src={process.env.PUBLIC_URL + '/VentraceLogo.png'}
                 alt={"Ventrace logo"}/>

            <p className={"info"}>Loading </p>

            <img className="spinner"
                 src={process.env.PUBLIC_URL + '/spinner.gif'}
                 alt={"Spinner"}/>
        </div>
    );
}


export default UIConfigLoader;