import React from "react";
import {connect} from "react-redux";
import axios from "axios";
import appConfig from "../../appConfig";
import {requestSetBlockchainLoading, requestSetBlockchainLoadingStop} from "../../store/actions/blockchain";
import {MdVerified} from "react-icons/md";
import {DataReporter} from "../../DataReporter";
import {AiOutlineMail} from "react-icons/ai";
import {TbWorld} from "react-icons/tb";
import "../../styles/components/details_creatorinfo.scss";

class CreatorInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checking: false,
            verifiedInfo: null
        };
    }

    getProviderData() {
        if (!this.state.checking) {
            this.setState({
                checking: true
            }, async () => {
                try {
                    axios
                        .get(appConfig.currentConfig.backendApp.url + "/provider/all",
                            {
                                headers: {
                                    Authorization: `Bearer ${appConfig.currentConfig.backendApp.tokens.provider}`,
                                    'Content-Type': 'application/json'
                                },
                                params: {
                                    id: this.props.providerID
                                }
                            })
                        .then(res => {
                            if (res.status === 200) {
                                this.setState({
                                    verifiedInfo: res.data[0],
                                    checking: false
                                });
                            } else {
                                DataReporter.trackMixpanel(this.props,
                                    "Error: Getting ProviderData", { category: "Error" });
                            }
                        })
                        .catch(err => {
                            DataReporter.trackSentry(err, { extra: {additionalData: "getProviderData"} });
                        })

                } catch (err) {
                    DataReporter.trackSentry(err, { extra: {additionalData: "getProviderData"} });
                }
            })
        }
    }

    checkVerification() {
        if (!this.state.checking) {
            this.setState({
                checking: true
            }, async () => {
                let voContract = this.props.blockchainReducer.verifiedOwnersContract;
                voContract.methods.getVerifiedOwner(this.props.creatorAddress)
                    .call({
                        from: this.props.walletReducer.connectedAddress,
                        gas: appConfig.currentConfig.blockchainGasLimit
                    })
                    .then(async (receipt) => {
                        if (receipt[0] === "0" || receipt[2] === "false") {
                            this.props.dispatchBlockchainLoadingStop();
                        } else {
                            let contextJSON = await axios.get(appConfig.IPFS_ProjectURL + receipt[3]);

                            this.setState({
                                verifiedInfo: contextJSON.data,
                                checking: false
                            }, () => {
                                this.props.dispatchBlockchainLoadingStop();
                            })
                        }
                    })
                    .catch((err) => {
                        DataReporter.trackSentry(err, {
                            extra: {additionalData: "CreatorInfo: checkVerification"}
                        });
                    })
            })
        }
    }

    // Events
    onCreatorAddressClick(val) {
        DataReporter.trackMixpanel(this.props, "Verified owner: Open address", {
            category: "Interaction",
        });

        let baseUrl = appConfig.currentConfig.explorerURLs.address;
        window.open(baseUrl + val, "_blank");
    }

    onClickSupportEmail() {
        DataReporter.trackMixpanel(this.props, "Verified owner: Click support mail", {
            category: "Interaction",
        });

        window.location.href = `mailto:${this.state.verifiedInfo.supportEmail}`;
    }

    onClickRelatedPage() {
        DataReporter.trackMixpanel(this.props, "Verified owner: Click page", {
            category: "Interaction",
        });

        if (this.props.fromProvider) {
            window.open(this.state.verifiedInfo.contactInfo.website, "_blank");
        } else {
            let url = this.state.verifiedInfo.relatedPageURL;
            window.open(url.startsWith("http") ? url : "https://" + url, "_blank");
        }
    }

    // Renderers
    renderReferences() {
        let supportEmail;
        if (this.state.verifiedInfo.supportEmail && this.state.verifiedInfo.supportEmail.length > 5) {
            supportEmail = (
                <p id={"support-email"} onClick={() => this.onClickSupportEmail()}>
                    <AiOutlineMail className={"ref-icon"} />
                    Support
                </p>
            )
        }

        return (
            <div id={"references-container"}>
                <p id={"related-page"} onClick={() => this.onClickRelatedPage()}>
                    <TbWorld className={"ref-icon"} />
                    {this.props.fromProvider ? "Website" : "About"}
                </p>
                {supportEmail}
            </div>
        );
    }

    render() {
        if (!this.state.verifiedInfo) {
            if (this.props.fromProvider) {
                this.getProviderData();
            }
            else {
                this.checkVerification();
            }
            return this.props.defaultRender;
        } else {
            let p = this.props;
            let mobileSuffix = this.props.appReducer.mobileMode ? "-mobile" : "";

            let address = p.creatorAddress.slice(0, appConfig.address_SliceLen)
                + "-" + p.creatorAddress.slice(-appConfig.address_SliceLen);

            let imageUrl = process.env.PUBLIC_URL + "/assets/verified-bg.jpg";

            let style = {
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: "210%",
                backgroundPosition: 'center',
            };

            let regName;
            if (!this.props.fromProvider) {
                regName = (
                    <div>
                        <p className={"label"}>Registered name</p>
                        <p id={"verified-name"}>
                            {p.fromProvider ? this.state.verifiedInfo.name : this.state.verifiedInfo.displayName}
                        </p>
                    </div>
                );
            }

            return (
                <div className={"verified-creator-container" + mobileSuffix} style={style}>
                    <p id={"verified-title"}>
                        <MdVerified id={"icon"} />
                        {p.fromProvider ? "Officially provided by" : this.props.title}
                    </p>

                    <a id={"address"}
                       onClick={() => this.onCreatorAddressClick(p.creatorAddress)}>
                        {p.fromProvider ? this.state.verifiedInfo.name : address}
                    </a>

                    {regName}

                    <p className={"label"}>References</p>
                    {this.renderReferences()}
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        walletReducer: state.wallet,
        blockchainReducer: state.blockchain,
        appReducer: state.app
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dispatchBlockchainLoading: () => {
            dispatch(requestSetBlockchainLoading())
        },
        dispatchBlockchainLoadingStop: () => {
            dispatch(requestSetBlockchainLoadingStop())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatorInfo);